import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { SET_ERROR_MODAL_CLOSE } from "./Store/errorModalReducer";

const style = {
  position: "absolute",
  top: "0%",
  left: "50%",
  transform: "translate(-50%, 2%)",
  width: "100%",
  maxWidth: "500px",
  outline: "none",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  lineBreak: "anywhere",
};

const ErrorModal = () => {
  const { isOpen, details, message } = useSelector(
    (state) => state.errorModalReducer
  );
  const [showDetailMessage, setShowDetailMessage] = useState(false);
  const [countDown, setCountDown] = useState(10);
  const [isTimerStopped, setIsTimerStopped] = useState(false);
  const closeBtnRef = useRef(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsTimerStopped(false);
    setCountDown(10);
    setShowDetailMessage(false);
    dispatch({
      type: SET_ERROR_MODAL_CLOSE,
    });
  };

  const handleShowDetailMessage = () => {
    setShowDetailMessage(!showDetailMessage);
  };

  const copyHandler = async () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      toast.success("Error message copied !");
      return navigator.clipboard.writeText(details);
    } else {
      return Promise.reject("The Clipboard API is not available.");
    }
  };

  const stopTimerHandler = () => setIsTimerStopped(true);

  useEffect(() => {
    if (closeBtnRef?.current !== null) {
      closeBtnRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (!isTimerStopped) {
        const timer =
          countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
        if (countDown === 0) {
          handleClose();
          // setIsTimerStopped(true);
          // setCountDown(5);
          // dispatch({
          //   type: SET_ERROR_MODAL_CLOSE
          // });
        }
        return () => clearInterval(timer);
      }
    }
  }, [isOpen, countDown, isTimerStopped]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ display: "flex", alignItems: "center", flex: "1" }}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Error
            </Typography>

            {message?.length && details?.length ? (
              <IconButton
                tabIndex={-1}
                sx={{ borderRadius: "50px", marginLeft: "8px" }}
                onClick={handleShowDetailMessage}
              >
                {showDetailMessage ? (
                  <ExpandLessIcon fontSize="10" />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            ) : null}

            <IconButton
              tabIndex={-1}
              sx={{ borderRadius: "50px" }}
              onClick={copyHandler}
            >
              <ContentCopyIcon fontSize="10" />
            </IconButton>
          </Box>
          <IconButton
            tabIndex={-1}
            sx={{ borderRadius: "50px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        {message?.length ? (
          <Box>
            <p>{message}</p>
            <Box
              sx={{
                display: showDetailMessage ? "inline-block" : "none",
                marginTop: "10px",
              }}
            >
              {details}
            </Box>
          </Box>
        ) : (
          <Box>{details}</Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ pr: 1 }}>00:0{countDown}</Typography>
            <Button
              variant="text"
              // id="foot-sub"
              tabIndex={-1}
              size="small"
              sx={{
                background: "#FFFFFF !important",
                color: "#222222  !important",
                borderRadius: "4px",
                padding: "4px 10px",
                cursor: "pointer",
                fontSize: "12px",
                marginLeft: "5px",
              }}
              onClick={stopTimerHandler}
            >
              Pause
            </Button>
          </Box>
          <Box>
            {/* {isShowMoreEnabled ? (
              <>
                <Button
                  variant="outlined"
                  color="success"
                  sx={{
                    marginRight: '15px',
                    borderRadius: '4px',
                    padding: '2px 10px',
                    fontSize: '12px',
                    cursor: 'pointer'
                  }}
                  onClick={handleShowDetailMessage}
                >
                  {showDetailMessage ? 'Show Less' : 'Show More'}
                </Button>
              </>
            ) : null} */}
            <Button
              ref={closeBtnRef}
              autoFocus
              size="small"
              variant="contained"
              color="success"
              // sx={{
              //   background: "#28713E",
              //   borderRadius: "4px",
              //   padding: "4px 10px",
              //   cursor: "pointer",
              //   fontSize: "12px",
              //   "&:hover": { background: "#1E552F" },
              // }}
              id="foot-sub"
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
