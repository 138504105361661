import "./App.css";
import RouterLists from "./Router/RoutesLists";
import { BrowserRouter } from "react-router-dom";
import ErrorModal from "./Component/ErrorModal";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";

// window.onunload = function () {
// 	localStorage.removeItem('userName')
// }

function App() {
  const { isOpen } = useSelector((store) => store.errorModalReducer);
  return (
    <div className="App">
      <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 3000,
        }}
      />
      <BrowserRouter forceRefresh={true}>
        <RouterLists />
      </BrowserRouter>
      {isOpen ? <ErrorModal /> : null}
    </div>
  );
}

export default App;
