import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SEACustomInput = styled(TextField)({
  fontFamily: "var(--main--primary-fontFamily-lato)",
  textAlign: "left",
  "& label.Mui-disabled": {
    color: "#808080",
  },
  "& label ": {
    "& .Mui-focused": {
      color: "#28713E",
      backgroundColor: "#FFFFFF !important",
      padding: "0px 6px 0px 4px",
      zIndex: 1200,
    },
  },
  "& label": {
    color: "#222222",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#28713E",
  },
  "& .MuiInputLabel-root": {
    "& .Mui-focused": {
      backgroundColor: "#FFFFFF",
    },
    backgroundColor: "tranparent",
  },
  "& .Mui-disabled": {
    "& > fieldset": {
      borderColor: "#A1A1A1 !important",
    },
    "& .MuiInputAdornment-root > .MuiIconButton-root": {
      color: "#A1A1A1 !important",
    },
  },
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "none",
    },
    "&:hover fieldset": {
      borderColor: "#28713E",
    },
    "& .Mui-focused fieldset": {
      borderColor: "#28713E",
    },
    "& .Mui-disabled": {
      backgroundColor: "inherit",
      "&:hover fieldset": {
        borderColor: "#D0D0D0",
      },
    },
    "& .Mui-focused": {
      backgroundColor: "#FFFFFF !important",
      boxShadow: `rgba(40, 113, 62, 0.2) 0 0 0 2px`,
      borderColor: "rgba(40, 113, 62, 0.2)",
      "input:invalid + fieldset": {
        borderColor: "#AA0000",
        boxShadow: `rgba(255, 221, 221, 1) 0 0 0 2px`,
        borderWidth: 2,
      },
    },
    "& .MuiInputAdornment-root": {
      "& .MuiIconButton-root": {
        color: "#2E2E2E",
      },
    },
  },
  "& input:invalid + .MuiInputAdornment-root + fieldset": {
    borderColor: "#AA0000",
    boxShadow: `rgba(255, 221, 221, 1) 0 0 0 2px`,
    borderWidth: 2,
  },
  "& input:invalid + .MuiSelect-nativeInput + fieldset": {
    borderColor: "#AA0000",
    boxShadow: `rgba(255, 221, 221, 1) 0 0 0 2px`,
    borderWidth: 2,
  },
  "& input:invalid + fieldset": {
    borderColor: "#AA0000",
    boxShadow: `rgba(255, 221, 221, 1) 0 0 0 2px`,
    borderWidth: 2,
  },
  "& input:placeholder": {
    color: "#808080",
  },
  "& > .MuiFormHelperText-root": {
    fontSize: "12px",
    fontStyle: "italic",
    color: "#D07D00",
    fontWeight: "600",
  },
  ".MuiInputLabel-root.Mui-error": {
    color: "#2E2E2E !important",
  },
});
