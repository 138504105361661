import React, { useEffect } from "react";
import Billing from "./Billing";
import CustomerOrderDetail from "./CustomerOrderDetail";
import RazorpayProceed from "./RazorpayProceed";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_IS_REGISTER, SET_OPEN_LOGIN } from "./Store/Reducer";
import { HttpPost } from "../helper/Api";
import { useState } from "react";
import BillingDetails from "./BillingDetails";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";

const Proceedcheckout = () => {
  const location = useLocation();
  const allStateData = useSelector((store) => store.mainReducer);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [progress, setProgress] = useState(false);

  const loginClickHandler = () => {
    dispatch({ type: SET_OPEN_LOGIN, payload: true });
  };
  const registerClickHandler = () => {
    dispatch({ type: SET_IS_REGISTER, payload: true });
    dispatch({ type: SET_OPEN_LOGIN, payload: true });
  };

  const fetchData = async () => {
    setProgress(true);
    let obj = {
      SessionId: 0,
      CallMode: "LOV",
      QuerySettingId: 1000286,
      QuerySettingValues: `::5001:::::::::${allStateData?.partyId}`,
      fetchType: "DATA",
      requiredSocket: 0,
      pageNo: 1,
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      iGuid: "5388b279-91f2-4cc9-a352-ed3f738e598b",
    };
    const res = await HttpPost("searchLov", obj);
    if (res.success) {
      setData({
        ...data,
        name: res?.data?.Response[0]?.PARTY_NAME,
        firstName: res?.data?.Response[0]?.FIRST_NAME,
        lastName: res?.data?.Response[0]?.LAST_NAME,
        email: res?.data?.Response[0]?.EMAIL_ID,
        number: res?.data?.Response[0]?.MOBILE_NO,
        birth_date: res?.data?.Response[0]?.BIRTH_DATE
          ? res?.data?.Response[0]?.BIRTH_DATE
          : "",
        anniversary_date: res?.data?.Response[0]?.ANNIVERSARY_DATE
          ? res?.data?.Response[0]?.ANNIVERSARY_DATE
          : "",
        Address1: res?.data?.Response[0]?.BILLING_ADD_1
          ? res?.data?.Response[0]?.BILLING_ADD_1
          : "",
        addresss2: res?.data?.Response[0]?.BILLING_ADD_2
          ? res?.data?.Response[0]?.BILLING_ADD_2
          : "",
        Pincode: res?.data?.Response[0]?.BILLING_PINCODE
          ? res?.data?.Response[0]?.BILLING_PINCODE
          : "",
        billingAddId: res?.data?.Response[0]?.BILLING_ADDRESS_ID
          ? res?.data?.Response[0]?.BILLING_ADDRESS_ID
          : "",
        gstNo: res?.data?.Response[0]?.GST_NO
          ? res?.data?.Response[0]?.GST_NO
          : "",
        panNo: res?.data?.Response[0]?.PAN_NO
          ? res?.data?.Response[0]?.PAN_NO
          : "",
        taluka: res?.data?.Response[0]?.BILLING_TALUKA
          ? res?.data?.Response[0]?.BILLING_TALUKA
          : "",
        state: res?.data?.Response[0]?.BILLING_STATE
          ? res?.data?.Response[0]?.BILLING_STATE
          : "",
        stateId: res?.data?.Response[0]?.BILLING_STATE_ID
          ? res?.data?.Response[0]?.BILLING_STATE_ID
          : "",
        cityId: res?.data?.Response[0]?.BILLING_CITY_ID
          ? res?.data?.Response[0]?.BILLING_CITY_ID
          : "",
        city: res?.data?.Response[0]?.BILLING_CITY
          ? res?.data?.Response[0]?.BILLING_CITY
          : "",

        ShipAddress1: res?.data?.Response[0]?.SHIPPING_ADD_1
          ? res?.data?.Response[0]?.SHIPPING_ADD_1
          : "",
        Shipaddresss2: res?.data?.Response[0]?.SHIPPING_ADD_2
          ? res?.data?.Response[0]?.SHIPPING_ADD_2
          : "",
        ShipPincode: res?.data?.Response[0]?.SHIPPING_PINCODE
          ? res?.data?.Response[0]?.SHIPPING_PINCODE
          : "",
        ShippingAddId: res?.data?.Response[0]?.SHIPPING_ADDRESS_ID
          ? res?.data?.Response[0]?.SHIPPING_ADDRESS_ID
          : "",
        ShipgstNo: res?.data?.Response[0]?.SHIP_GST_NO
          ? res?.data?.Response[0]?.SHIP_GST_NO
          : "",
        ShippanNo: res?.data?.Response[0]?.SHIP_PAN_NO
          ? res?.data?.Response[0]?.SHIP_PAN_NO
          : "",
        Shiptaluka: res?.data?.Response[0]?.SHIPPING_TALUKA
          ? res?.data?.Response[0]?.SHIPPING_TALUKA
          : "",
        Shipstate: res?.data?.Response[0]?.SHIPPING_STATE
          ? res?.data?.Response[0]?.SHIPPING_STATE
          : "",
        ShipstateId: res?.data?.Response[0]?.SHIPPING_STATE_ID
          ? res?.data?.Response[0]?.SHIPPING_STATE_ID
          : "",
        ShipcityId: res?.data?.Response[0]?.SHIPPING_CITY_ID
          ? res?.data?.Response[0]?.SHIPPING_CITY_ID
          : "",
        Shipcity: res?.data?.Response[0]?.SHIPPING_CITY
          ? res?.data?.Response[0]?.SHIPPING_CITY
          : "",
        companyName: res?.data?.Response[0]?.COMPANY_NAME
          ? res?.data?.Response[0]?.COMPANY_NAME
          : "",
      });
      setProgress(false);
      return true;
    }
    setProgress(false);
    return false;
  };

  useEffect(() => {
    fetchData();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [allStateData?.partyId]);
  const handleChange = (event) => {
    setData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const dateConvert = (d) => {
    return new Date(d)?.toLocaleDateString("en-GB")?.replace(/\//g, "-");
  };
  const handleEditButtonClick = async (e) => {
    e.preventDefault();
    setProgress(true);
    const name = data?.firstName + " " + data?.lastName;
    if (name.length === 0 || data?.email?.length === 0)
      return toast.error("Cannot save with empty email or name");
    let obj = {
      SessionId: 0,
      CallMode: "LOV",
      QuerySettingId: 2487,
      QuerySettingValues: `PROFILE:${allStateData?.partyId}:${name}:0:0:${
        data?.email
      }:${data?.number}:${data?.["Address1"] ? data?.["Address1"] : ""}:${
        data?.["addresss2"] ? data?.["addresss2"] : ""
      }::${data?.["cityId"] ? data?.["cityId"] : ""}:${
        data?.["Pincode"] ? data?.["Pincode"] : ""
      }:${data?.birth_date ? dateConvert(data?.birth_date) : ""}:${
        data?.anniversary_date ? dateConvert(data?.anniversary_date) : ""
      }:${data?.["copyBillingAdd"] ? data?.["copyBillingAdd"] : ""}:${
        data?.["ShipAddress1"] ? data?.["ShipAddress1"] : ""
      }:${data?.["Shipaddresss2"] ? data?.["Shipaddresss2"] : ""}:${
        data?.["ShipPincode"] ? data?.["ShipPincode"] : ""
      }:${data?.["ShipcityId"] ? data?.["ShipcityId"] : ""}:${
        data?.["Shiptaluka"] ? data?.["Shiptaluka"] : ""
      }:${data?.["panNo"] ? data?.["panNo"] : ""}:${
        data?.["gstNo"] ? data?.["gstNo"] : ""
      }`,
      fetchType: "DATA",
      requiredSocket: 0,
      pageNo: 1,
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      iGuid: "5388b279-91f2-4cc9-a352-ed3f738e598b",
    };
    const res = await HttpPost("searchLov", obj);
    if (res.success) {
      setData({});
      toast.success("Data updated successfully saved.");
      setProgress(false);
      return true;
    }
    setProgress(false);
    return false;
  };
  return (
    <div>
      <div className="add-to-cart-bg-img">
        <h1
          style={{
            textAlign: "left",
            paddingTop: "120px",
            paddingLeft: "120px",
            fontSize: "55px",
            fontFamily: "'Tenor Sans', sans-serif",
          }}
        >
          CHECKOUT
        </h1>
      </div>

      {allStateData?.userInfo?.length === 0 ? (
        <div className="container login-form-accordion mt-5">
          <button
            className="checkout-login"
            onClick={() => loginClickHandler()}
          >
            LOGIN
          </button>
          <button
            className="checkout-login"
            onClick={() => registerClickHandler()}
          >
            Register
          </button>
        </div>
      ) : (
        <>
          {progress && allStateData?.userInfo?.length === 0 ? (
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <BillingDetails data={data} setData={setData} />
          )}
          {/* <Billing data={data} handleChange={handleChange} /> */}
        </>
      )}
      <CustomerOrderDetail data={location.cartData} />
      <RazorpayProceed
        handleEditButtonClick={handleEditButtonClick}
        progress={progress}
      />
    </div>
  );
};

export default Proceedcheckout;
