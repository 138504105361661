import React, { Component } from "react";
import ReactImageMagnify from "react-image-magnify";
import "../../assets/css/imagemagnify.css";
export class ResponsiveChildItemDetail extends Component {
  render() {
    return (
      <div className="fluid">
        <div className="single_product_left">
          <div
            className={`fluid__image-container  ${
              this.props.GlobalitemData?.customCatalogue?.[0]
                ?.SHOW_BOM_ATTRIBUTE_IND === "0"
                ? "fluid__image-container__withoutAttrib"
                : "fluid__image-container__withAttrib"
            }`}
          >
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: this.props.itemData?.IMAGE_NAME?.slice(0, 20),
                  src: this.props.itemData?.IMAGE_NAME,
                  isFluidWidth: true,
                  // width: window.innerWidth <= 540 ? 380 : 500,
                  // height: window.innerWidth <= 540 ? 380 : 400,
                  // onLoad: (e) => console.log(e),
                },
                largeImage: {
                  alt: this.props.itemData?.IMAGE_NAME?.slice(0, 20),
                  src: this.props.itemData?.IMAGE_NAME,
                  width: 1000,
                  height: 800,
                },
                isHintEnabled: true,
                enlargedImageContainerDimensions: {
                  width: 500,
                  height: "100%",
                },
              }}
              style={{
                zIndex: 14,
              }}
            />
            {/* {this.props.itemData?.ORG_CATALOG_ITEM_ID == undefined &&
            this.props.GlobalitemData?.items?.length &&
            !this.props.isNavigate ? (
              <>
                <img
                  className="fluid__arrowRight"
                  src="https://img.icons8.com/plumpy/2x/circled-right-2.png"
                  alt="Forward Arrow"
                  onClick={() => this.props.navigateToNextClick()}
                />
                <img
                  className="fluid__arrowLeft"
                  src="https://img.icons8.com/plumpy/2x/circled-left-2.png"
                  alt="Back Arrow"
                  onClick={() => this.props.navigateToPreviousClick()}
                />
              </>
            ) : null} */}
          </div>
          {/* {this.props.GlobalitemData?.customCatalogue?.[0]
            ?.SHOW_BOM_ATTRIBUTE_IND === "0" ? (
            <></>
          ) : !this.props.itemDetailLoading ? (
            this.props.itemDetails?.length ? (
              <div className="table-responsive-sm">
                <table className="my-2 table table-striped table-hover shadow-lg bg-body rounded-0">
                  <thead className="thead-dark">
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Pcs / Wt </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.itemDetails?.map((detail, index) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>
                          {detail?.RM_TYPE} / {detail?.DESCRIPTION}
                        </td>
                        <td>
                          {detail?.PIECES} / {detail?.WT} {detail?.UOM}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>No Details</div>
            )
          ) : null} */}
        </div>
        {/* <div className="fixed__instructions">
          <div className="single_product_right">
            <div className="itemModalContainer__imageList itemDetail__ImageList">
              <img
                onError={(event) => this.props.onError(event)}
                className={
                  this.props.itemData?.IMAGE_NAME === this.props.itemData?.IMAGE_NAME
                    ? "selectedImg"
                    : "not_selectedImg"
                }
                onLoad={(event) => this.props.onImgLoad(event)}
                onClick={() =>
                  this.props.setImageSelect(this.props.itemData?.IMAGE_NAME)
                }
                key="defaultImage"
                src={this.props.itemData?.IMAGE_NAME}
                alt={this.props.itemData?.IMAGE_NAME?.slice(0, 12)}
              />
              {this.props.itemData?.IMAGE_LIST
                ? this.props.itemData?.IMAGE_LIST?.split("|")?.map(
                    (data, index) => (
                      <img
                        onError={(event) => this.props.onError(event)}
                        className={
                          this.props.itemData?.IMAGE_NAME === data
                            ? "selectedImg"
                            : "not_selectedImg"
                        }
                        onLoad={(event) => this.props.onImgLoad(event)}
                        onClick={() => this.props.setImageSelect(data)}
                        key={index}
                        src={data}
                        alt={data?.slice(0, 12)}
                      />
                    )
                  )
                : null}
            </div>
            <div className="catalogue_name">
              <h5>
                {this.props.itemData?.["SET NO"]
                  ? this.props.itemData?.["SET NO"]?.toString() +
                    " / " +
                    this.props.itemData.VARIANT_NAME
                  : this.props.itemData?.VARIANT_NAME}
              </h5>
              <h1>{this.props.itemData?.STOCK_CODE}</h1>
            </div>
            <div className="itemModalContainer__description__weight">
              <div className="jewellry_detalis">
                <div className="jewellry-detalis jewellry">
                  <h5>Gross Weight</h5>
                  <h4>{this.props.itemData?.GR_WT}</h4>
                </div>
                <div className="jewellry-detalis jewellry">
                  <h5>Net Weight / Pure Weight</h5>
                  <h4>
                    {this.props.itemData?.NET_WT}/{this.props.itemData?.PURE_WT}
                  </h4>
                </div>
                <div className="jewellry-detalis jewellry">
                  <h5>Diamond</h5>
                  <h4>
                    {this.props.itemData?.DIA_PCS}/{this.props.itemData?.DIA_WT}
                  </h4>
                </div>
                <div className="jewellry-detalis jewellry">
                  <h5>Stone</h5>
                  <h4>
                    {this.props.itemData?.CLS_PCS}/{this.props.itemData?.CLS_WT}
                  </h4>
                </div>
              </div>
            </div>
            <div className="single_product_add">
              {this.props.isSelected === "0" ? (
                <button
                  onClick={() => this.props.handleAddToCart(this.props.itemData)}
                  className="btn btn-primary"
                >
                  {this.props.GlobalitemData?.LoginInfo?.LOGIN_NAME === "ADMIN"
                    ? "Add to Selection"
                    : "Add to Cart"}
                </button>
              ) : (
                <button
                  onClick={() =>
                    this.props.handleRemoveToCart(this.props.itemData)
                  }
                  className="btn btn-danger"
                >
                  {this.props.GlobalitemData?.LoginInfo?.LOGIN_NAME === "ADMIN"
                    ? "Remove From Selection"
                    : "Remove From Cart"}
                </button>
              )}
            </div>
            {this.props.GlobalitemData?.customCatalogue?.[0]
              ?.SHOW_BOM_ATTRIBUTE_IND === "0" ? (
              <></>
            ) : (
              <div className="table-responsive-sm">
                <table className="my-2 table table-striped table-hover shadow-lg bg-body rounded-0">
                  <thead className="thead-dark">
                    <tr>
                      <th>#</th>
                      <th>ATTRIB TYPE</th>
                      <th>ATTRIB DESC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.GlobalitemData?.LoginInfo?.LOGIN_NAME !==
                    "ADMIN" ? (
                      <>
                        {this.props.itemData.ATTRIBUTE?.filter(
                          (attribFilter) =>
                            attribFilter?.ATTRIB_DESC !== null &&
                            attribFilter?.ATTRIB_MODE != "2"
                        )
                          .sort((a, b) => a?.ATTRIB_MODE - b?.ATTRIB_MODE)
                          ?.sort((a, b) => {
                            if (a.ATTRIB_TYPE < b.ATTRIB_TYPE) {
                              return -1;
                            }
                            if (a.ATTRIB_TYPE > b.ATTRIB_TYPE) {
                              return 1;
                            }
                            return 0;
                          })
                          ?.map((attribDetail, index) => {
                            return this.props.GlobalitemData?.allGroupOptionLists?.map(
                              (allGroupVal) => {
                                if (
                                  allGroupVal?.ATTRIB_TYPE ===
                                    attribDetail?.ATTRIB_TYPE &&
                                  allGroupVal?.DISPLAY_SETTING === "1"
                                ) {
                                  return (
                                    <tr key={index + 1}>
                                      <td>{index + 1}</td>
                                      <td>{attribDetail?.ATTRIB_TYPE}</td>
                                      <td>{attribDetail?.ATTRIB_DESC}</td>
                                    </tr>
                                  );
                                }
                              }
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {this.props.itemData.ATTRIBUTE?.filter(
                          (attribFilter) =>
                            attribFilter?.ATTRIB_DESC !== null &&
                            attribFilter?.ATTRIB_MODE != "2"
                        )
                          .sort((a, b) => a?.ATTRIB_MODE - b?.ATTRIB_MODE)
                          ?.sort((a, b) => {
                            if (a.ATTRIB_TYPE < b.ATTRIB_TYPE) {
                              return -1;
                            }
                            if (a.ATTRIB_TYPE > b.ATTRIB_TYPE) {
                              return 1;
                            }
                            return 0;
                          })
                          ?.map((attribDetail, index) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{attribDetail?.ATTRIB_TYPE}</td>
                              <td>{attribDetail?.ATTRIB_DESC}</td>
                            </tr>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div> */}
      </div>
    );
  }
}

export default ResponsiveChildItemDetail;
