// import { SET_TOTAL_CART_COUNT } from "../Component/Store/ActionTypes";
import { HttpPost } from "../helper/Api";
import { extractUserDefineError } from "../helper/utils";

export const selectedFilter = (filterArray=[]) => {
    const checkedFilters = filterArray?.filter((data) => data.IS_CHECKED === '1' || data.FILTER_TYPE === 'RANGE')
    return checkedFilters;
}

export const getCartItem = async (itemData, cartType = 'CART') => {
    let cartObj = {
      "iConfigId": "5053",
      "iTransCategoryLinkId": "5053",
      "iMenuID": "1000148",
      "iWcId": 0,
      "iWcGroupId": 0,
      "iTransType": "EST",
      "TransItemId": 0,
      "vMode": `${cartType}`
    }
    let res = await HttpPost("GetCartData", cartObj);
    if (res.success) {
        return res.ITEM
    }
    else {
      const errorMessage = extractUserDefineError(res.error);
          alert(errorMessage);
    }
  }

export const getTotalCartAmount = (cartData) =>{
    return cartData.reduce((prev, curr) =>{
        prev += curr.Rate
        return prev
    }, 0)
}
