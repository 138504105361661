import React from 'react';
import ezeebuy from '../image/ezee.jpg'
import plan11 from '../image/ezee_buy_plan_11.jpg'
import plan18 from '../image/ezee_buy_plan_18.jpg'

const Ezee = () => {
  return (
    <div>
        <div className='ezee_buy_page'>
          <img src={ezeebuy} alt=""/>
        </div>
        <div className='ezee-plan-div'>
            <img src={plan11} alt="" />
            <img src={plan18} alt="" />
        </div>

    </div>
  )
}

export default Ezee