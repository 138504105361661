import React from 'react'
import './GemsStoneGuide.css'
import gemstoneguide1 from '../../image/gemstoneguide1.jpg'
import gemstoneguide2 from '../../image/gemstoneguide2.jpg'
import gemstoneguide3 from '../../image/gemstoneguide3.jpg'
import gemstoneguide4 from '../../image/gemstoneguide4.jpg'
import gemstoneguide5 from '../../image/gemstoneguide5.jpg'
import gemstoneguide6 from '../../image/gemstoneguide6.jpg'
import gemstoneguide7 from '../../image/gemstoneguide7.jpg'
import gemstoneguide8 from '../../image/gemstoneguide8.jpg'
import gemstoneguide9 from '../../image/gemstoneguide9.jpg'
import gemstoneguide10 from '../../image/gemstoneguide10.jpg'
import gemstoneguide11 from '../../image/gemstoneguide11.jpg'
import gemstoneguide12 from '../../image/gemstoneguide12.jpg'

const GemsStoneGuide = () => {
  return (
    <div className='gemsStoneGuide'>
        <div className='gemsStoneGuide__header'>
            <h2>Gemstone Guide</h2>
            <p>About two hundred known gemstones are there in the market. Some gemstones are salts which are cut from crystals that occur naturally. The specimen should be rough and durable to survive to scrape and have stunning transparency, color, or 'fire' to qualify as a gem.The cut, hue, brightness, and scale of the gemstone that accounts for their purchase.</p>
        </div>
        <div className='gemsStoneGuide__content'>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide1} alt=""/>
                <h2>GARNET</h2>
                <h3>January</h3>
                <p>This gem is available in a bright rainbow, from the deep red Bohemian Garnet to the vivid Russian Demantoid and African Tsavorite Greens. A garnet's different colors align with different forces and are known to assist with multiple problems: green for mediation and focus; orange for dedication; purple for emotional health and red for zeal, self-confidence, imagination, and meditation.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide2} alt=""/>
                <h2>AMETHYST</h2>
                <h3>February</h3>
                <p>The Amethyst is a translucent quartz type gemstone occurring in shades of purple or violet. In truth, the color purple for all gemstones is defined by the February birthstone. A much-coveted stone, for over millennia, Amethysts have been worn by the people of authority. Royalty and class are denoted by the dark purple hue. The Amethyst is credited with several powers.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide3} alt=""/>
                <h2>AQUAMARINE</h2>
                <h3>March</h3>
                <p>Aquamarine is an extremely translucent, semi-precious gemstone in the Beryl mineral family, light blue. For March, it is the common birthstone. Aquamarines can be found in a variety of blues, varying from pale pastel to a rich greenish-blue hue. With passing time, the darker shades of blue are becoming rarer and are growing in value. Aquamarine is also a gemstone made from pastel.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide4} alt=""/>
                <h2>DIAMOND</h2>
                <h3>April</h3>
                <p>Diamond is the key gemstone shimmering, polished birthstone of April that adorns thousands of engagement rings every year. Wearing diamonds is also said to offer benefits such as harmony, transparency, and abundance. Diamonds come in several colors, from the most common colorless to the fanciest pink, blue, yellow, orange, green, and red. Fancy diamonds are less costly and rarer.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide5} alt=""/>
                <h2>EMERALD</h2>
                <h3>May</h3>
                <p>Emeralds come in a green, translucent beryl variety. Like the purple definition of Amethysts, Emeralds stand beautifully and unimaginably green for all things. Emeralds, among the rarest diamonds, are nearly often found with birthmarks, often known as inclusions. One of the oldest stones to bemined, any community from Indian to Inca, mixes the emerald with luck, healing, and holiness.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide6} alt=""/>
                <h2>PEARL</h2>
                <h3>June</h3>
                <p>Pearls have long been linked to purity, modesty, and innocence. So it can be said that the June birthstone means "sweet simplicity." As such, pearls have historically been offered as a wedding gift. Although no pearl is flawless, the lower the surface imperfections, the higher the value.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide7} alt=""/>
                <h2>RUBY</h2>
                <h3>July</h3>
                <p>Ruby is the birthstone for the month of July. Many people born in the month of July are blessed to have ruby as an astrological gemstone for them, and after wearing it, any person can encounter greater benefits from the gemstone. Ruby gets its color from trace quantities of the element chromium, a variation of the mineral corundum. The larger the amount of chromium, the stronger the red color of the gemstone.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide8} alt=""/>
                <h2>PERIDOT</h2>
                <h3>August</h3>
                <p>Peridot is a distinctive birthstone since it inherits a green hue with no other color at all. The color intensity depends on the amount of iron; the more iron it contains, the deeper it will be green. It is both a stone of the day and a stone of the night, maintaining its vivid color even under artificial illumination. It's sometimes called "Evening Emerald" for this reason.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide9} alt=""/>
                <h2>SAPPHIRE</h2>
                <h3>September</h3>
                <p>For many centuries, sapphire has adorned the royalty and the clergy's robes. The elite of ancient Greece and Rome believed that blue sapphires protected their owners from harm and envy. Sapphires are also present in other natural colors and shades – colorless, gray, yellow, pale pink, orange , green, purple and brown – called fancy sapphires.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide10} alt=""/>
                <h2>TOURMALINE</h2>
                <h3>October</h3>
                <p>Tourmaline is an alternative birthstone for October, along with an opal. According to legend, the Tourmaline was passing through a rainbow on its way up from the center of the earth, absorbing its colors along the way. Among the gemstones, tourmaline is available in the widest range of colors. The gemstone is known to prevent negative energy protecting the person wearing it from any bad vibes.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide11} alt=""/>
                <h2>CITRINE</h2>
                <h3>November</h3>
                <p>Citrine is the birthstone of those born in the month of November. The people wearing the Citrine stones are considered to have the understanding of morality and law. Citrines range from the palest yellow to the dark amber known as Madeira, which almost touches red.</p>
            </div>
            <div className='gemsStoneGuide__content__item'>
                <img src={gemstoneguide12} alt=""/>
                <h2>TOPAZ</h2>
                <h3>December</h3>
                <p>Blue Topaz is the birthstone for the people born in the month of December.This gemstone is believed to have a long life, elegance and wisdom, and isalso available in other shades. Most people remember this precious stone because of its stunning aqua-turquoise hue. The three main shades of the range are called: London Blue, Sky, and Swiss.</p>
            </div>
        </div>
    </div>
  )
}

export default GemsStoneGuide