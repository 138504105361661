import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import shopAainaBird from "../image/shop-aaina-bird.png";
import shopAainaKada from "../image/aaina-hand-kada.jpg";
import shopAainKada2 from "../image/aaina-hand-kada2.jpg";
import shopAainaKada3 from "../image/aaina-hand-kada3.jpg";
import shopAainaKada4 from "../image/aaina-hand-kada4.jpg";
import shopAainaBrace1 from "../image/aaina-brace1.jpg";
import shopAainaBrace2 from "../image/aaina-brace2.jpg";
import shopAainaBrace3 from "../image/aaina-brace3.jpg";
import shopAainaBrace4 from "../image/aaina-brace4.jpg";
import aainaCarousel1 from "../image/aaina_carousel1.jpg";
import aainaCarousel2 from "../image/aaina_carousel2.jpg";
import aainaCarousel3 from "../image/aaina_carousel3.jpg";
import aainaCarousel4 from "../image/aaina_carousel4.jpg";
import aainaCarousel5 from "../image/aaina_carousel5.jpg";
import aainaCarousel6 from "../image/aaina_carousel6.jpg";
import { useLocation, useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ShopAaina() {
  const navigate = useNavigate();
  const location = useLocation();
  const openShopPage = () => {
    navigate(`/shop/`);
  };
  return (
    <div className="continer-fluid mb-5">
      <div
        className="shop-aaina-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="row justify-content-center">
          <div className="text-center bird-div">
            <img src={shopAainaBird} className="img-fluid" alt="..." />
            <h2 className="bird-h2">AN ODE TO INDIAN CRAFTSMANSHIP</h2>
            <p className="bird-para">
              Depeting beauty, love and honour for exquisite craftsmanship, at
              AAINA we curate a selection of gold plated and antique silver
              jewels that retains its aesthetic charm through generations.
              Precious in metal and make, it possesses an elaborate sense of
              appeal that makes ``Love at first sight`` come to life.
            </p>
            <a href="#">
              <button className="bird-btn" onClick={() => openShopPage()}>
                SHOP ALL
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row align-items-start text-center">
          <div className="col-sm col-md col-lg">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainaKada} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">SILVER NAVRATAN BANGLE</p>
                <p>₹10,900</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm col-md col-lg">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainKada2} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">SILVER JAALI KARA WITH GREEN STONE</p>
                <p>₹4,500</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm col-md col-lg">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainaKada3} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">SILVER DUAL TONE PACHELI STYLE KARA</p>
                <p>₹8,900</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm col-md col-lg">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainaKada4} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">SILVER OXIDISED TRIBAL KARA</p>
                <p>₹7,900</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row align-items-start text-center">
          <div className="col-sm">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainaBrace1} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  SILVER TRIBAL BANGLE WITH PINK & GREEN STONES
                </p>
                <p>₹10,900</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainaBrace2} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  SILVER PAIR OF BANGLE WITH PINK STONES
                </p>
                <p>₹16,500</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainaBrace3} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">SILVER OXIDISED ELEPHANT BANGLE</p>
                <p>₹2,900</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm">
            <div
              className="card"
              id="multi"
              style={{ width: "15rem", border: "none" }}
            >
              <img src={shopAainaBrace4} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">SILVER MULTI STONE TRIBAL BANGLE</p>
                <p>₹3,600</p>
                <div className="cart-btn">
                  <button
                    className="add-btn btn-1"
                    onClick={() => console.log("Add to cart")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button className="add-btn btn-2">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <a href="#">
            <button className="aaina-shop-btn">SHOP ALL</button>
          </a>
        </div>
      </div>

      {/* <div className='container' style={{ marginTop: "80px" }}>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className='row'>
                <img src={aainaCarousel1} className="d-block w-25" alt="..." />
                <img src={aainaCarousel2} className="d-block w-25" alt="..." />
                <img src={aainaCarousel3} className="d-block w-25" alt="..." />
                <img src={aainaCarousel4} className="d-block w-25" alt="..." />
              </div>
            </div>
            <div className="carousel-item">
              <div className='row'>
                <img src={aainaCarousel5} className="d-block w-25" alt="..." />
                <img src={aainaCarousel6} className="d-block w-25" alt="..." />
                <img src={aainaCarousel1} className="d-block w-25" alt="..." />
                <img src={aainaCarousel2} className="d-block w-25" alt="..." />
              </div>
            </div>

          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div> */}
      <div className="carouselConatiner" style={{ marginBottom: "80px" }}>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
        >
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={aainaCarousel1} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={aainaCarousel2} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={aainaCarousel3} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={aainaCarousel4} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={aainaCarousel5} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={aainaCarousel6} width="100%" alt="..." />
          </div>
        </Carousel>
      </div>

      <div className="container-fluid" style={{ marginBottom: "-49px" }}>
        <div className="row pt-5">
          <div className="col-lg aaina"></div>
          <div className="col-lg aaina-bg">
            <div className="aaina-auth-decs">
              <h4 className="aaina-auth-heading">
                EXPERIENCE AAINA IN VARANASI
              </h4>
              <p className="aaina-auth-para">
                We are now showcasing our collection in Varanasi at Guleria
                Kothi, a Magnificently restored 18th century river front house.
              </p>
              <a href="http://guleriakothi.com/contact-us/" target="_blank">
                <button className="aaina-book-btn">
                  BOOK YOUR APPOINTMENT
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopAaina;
