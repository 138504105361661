import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import whatappIcon from "../image/whatsapp.svg";
import Ellipsefoot from "../image/icons/Ellipsefoot.png";
import { ReactComponent as GetTouch } from "../image/icons/getTouch.svg";
import { HttpPost } from "../helper/Api";
import { toast } from "react-hot-toast";
function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [emailId, setEmailId] = useState("");
  const handleSubscribe = async () => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regex.test(emailId)) {
      const body = {
        EMAIL_ID: emailId,
      };
      const res = await HttpPost("SubscribeNewLetter", body);
      if (res.success) {
        toast.success("Subscribed successfully.");
        setEmailId("");
      }
    } else {
      toast.error("Please enter valid email.");
    }
  };
  return (
    <div
      className="footer-main"
      style={{
        backgroundImage: `url(${Ellipsefoot})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div className="container">
        <div className="row align-items-start">
          <div className="col-md" style={{ textAlign: "left" }}>
            <div className="footer-add1">
              <div
                style={{
                  paddingBottom: "10px",
                  fontSize: "13px",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  direction: "row",
                  gap: "8px",
                }}
                onClick={() => navigate(`/contact-us/`)}
              >
                <GetTouch />
                <p>Get In Touch</p>
              </div>
              <p>+91 9984500051/52</p>
              <p>
                <a href="mailto:info@baijnathjewellers.com">
                  info@baijnathjewellers.com
                </a>
              </p>
            </div>
            <div className="footer-add2">
              <p>27-28 A, PPN Market, Kanpur.</p>
              <p>11am-8pm, Mon-Sat</p>
            </div>
          </div>
          <div className="col-md" style={{ textAlign: "left" }}>
            <h5
              style={{
                paddingBottom: "20px",
                fontSize: "13px",
                fontFamily: "Poppins",
                fontWeight: 500,
              }}
            >
              JEWELLERY GUIDES
            </h5>
            <div className="jwel-guides">
              <p>
                <a
                  onClick={() => navigate(`/diamond-guide/`)}
                  href="#"
                  rel="noreferrer"
                >
                  Diamond Guide
                </a>
              </p>
              <p>
                <a
                  onClick={() => navigate(`/gemstone-guide/`)}
                  href="#"
                  rel="noreferrer"
                >
                  Gemstone Guide
                </a>
              </p>
              <p>
                <a
                  onClick={() =>
                    navigate(`/jewellery-guide/`)
                  }
                  href="#"
                  rel="noreferrer"
                >
                  Jewellery Guide
                </a>
              </p>
              <p>
                <a
                  href="https://files.synergicserp.com/BAIJNATH/FILES/Ring-size-Guide.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ring Size
                </a>
              </p>
              <p>
                <a
                  href="https://files.synergicserp.com/BAIJNATH/FILES/Bangle-size-Guide.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bangle Size
                </a>
              </p>
            </div>
          </div>
          <div className="col-md" style={{ textAlign: "left" }}>
            <h5
              style={{
                paddingBottom: "20px",
                fontSize: "13px",
                fontFamily: "Poppins",
                fontWeight: 500,
              }}
            >
              POLICIES
            </h5>
            <div className="jwel-policis">
              <p>
                <a
                  href="#"
                  onClick={() => navigate(`/faq-page/`)}
                  rel="noreferrer"
                >
                  FAQ
                </a>
              </p>
              <p>
                <a
                  href="#"
                  onClick={() => navigate(`/privacy-policy/`)}
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
              <p>
                <a
                  href="#"
                  onClick={() =>
                    navigate(`/terms-conditions/`)
                  }
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
              </p>
              <p>
                <a
                  href="#"
                  onClick={() =>
                    navigate(`/shipping-refund-policy/`)
                  }
                  rel="noreferrer"
                >
                  Shipping & Refund Policy
                </a>
              </p>
            </div>
          </div>
          <div className="col-md" style={{ textAlign: "left" }}>
            <div style={{ paddingBottom: "20px" }}>
              <input
                type="email"
                value={emailId}
                placeholder="Enter email for newsletter"
                id="input-footer"
                style={{
                  borderRadius: "0px",
                  height: "40px",
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: "12px",
                }}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </div>
            <div style={{ paddingBottom: "20px" }}>
              {" "}
              <button
                id="foot-sub"
                style={{ width: "100%" }}
                onClick={() => handleSubscribe()}
              >
                SUBSCRIBE
              </button>
            </div>
            <div style={{ paddingBottom: "20px" }}>
              <div>FOLLOW US ON</div>
              <p>
                <a
                  className="social-media"
                  href="https://www.facebook.com"
                  target="_blank"
                >
                  <i
                    className="fab fa-facebook-square foot-icon"
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  className="social-media"
                  href="https://www.instagram.com/baijnathjewellers/"
                  target="_blank"
                >
                  <i
                    className="fab fa-instagram foot-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <a href="https://whatsapp.com" target="_blank">
        <img className="whatsApp" src={whatappIcon} alt="" />
      </a>
    </div>
  );
}

export default Footer;
