import { Tabs } from "@material-ui/core";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { HttpPost } from "../helper/Api";
import { toast } from "react-hot-toast";
const ForgetPassword = ({ closeForgetPassword }) => {
  const allStateData = useSelector((store) => store.mainReducer);
  const [value, setValue] = React.useState("1");
  const [data, setData] = useState({});
  const handlChangeData = (e) => {
    if (e.target.name === "mobileNo" && e.target.value?.length > 10) {
      return;
    }
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleChange = (event, newValue) => {
    setData({});
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const handleGeneratePass = async (e) => {
    e.preventDefault();
    const body = {
      MODE: "GENERATE",
      MOBILE_NO: data?.mobileNo,
      EMAIL_ID: data?.email,
    };
    const res = await HttpPost("GeneratePasswordForWeb", body);
    if (res?.success) {
      toast.success(
        "Generated password has been sent on your entered email address."
      );
      closeForgetPassword();
    }
  };
  const handleForgotPass = async (e) => {
    e.preventDefault();
    const body = {
      MODE: "FORGOT",
      MOBILE_NO: data?.mobileNo,
      EMAIL_ID: data?.email,
    };
    const res = await HttpPost("GeneratePasswordForWeb", body);
    if (res?.success) {
      toast.success(
        "Generated new password has been sent on your entered email address."
      );
      closeForgetPassword();
    }
  };

  return (
    <div className="register">
      <h2>Password Manager</h2>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="lab API tabs example"
          indicatorColor="secondary"
          textColor="inherit"
        >
          <Tab
            label="Generate Password"
            disabled={allStateData?.userInfo !== ""}
            value="1"
            {...a11yProps("1")}
          />
          <Tab
            label="Forgot Password"
            disabled={allStateData?.userInfo !== ""}
            value="2"
            {...a11yProps("2")}
          />
        </Tabs>
        <TabPanel value={value} index="1">
          <form onSubmit={handleGeneratePass}>
            <div className="register__inputs">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: 26,
                    left: "5px",
                    padding: "0px 5px",
                  }}
                >
                  +91
                </span>
                <input
                  autoFocus={true}
                  type="number"
                  inputMode="numeric"
                  maxLength={"10"}
                  minLength={"10"}
                  name="mobileNo"
                  value={data?.mobileNo}
                  style={{ paddingLeft: "35px", marginTop: 15 }}
                  required
                  onChange={handlChangeData}
                />
              </div>
              <input
                type="email"
                placeholder="Enter email*"
                name="email"
                required
                onChange={handlChangeData}
                style={{ marginTop: 15 }}
              />
              <button
                className="register__btn"
                style={{
                  backgroundColor: "#ffe7e3",
                  border: 0,
                  lineHeight: "2em",
                  margin: "15px 0 0",
                }}
                onClick={handleGeneratePass}
              >
                GENERATE PASSWORD
              </button>
            </div>
          </form>
        </TabPanel>
        <TabPanel value={value} index="2">
          <form onSubmit={handleForgotPass}>
            <div className="register__inputs">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: 26,
                    left: "5px",
                    padding: "0px 5px",
                  }}
                >
                  +91
                </span>
                <input
                  autoFocus={true}
                  type="number"
                  inputMode="numeric"
                  maxLength={"10"}
                  minLength={"10"}
                  name="mobileNo"
                  value={data?.mobileNo}
                  style={{ paddingLeft: "35px", marginTop: 15 }}
                  required
                  onChange={handlChangeData}
                />
              </div>
              <input
                type="email"
                placeholder="Enter email*"
                name="email"
                value={data?.email}
                required
                onChange={handlChangeData}
                style={{ marginTop: 15 }}
              />
              {/* <input type="email" placeholder='Email*' name='email' required></input> */}
              {/* <input type="password" placeholder='Password*' name='password' required></input> */}
              {/* <input type="password" placeholder='Repeat Password*' name='repeatpassword' required></input> */}
              <button
                className="register__btn"
                style={{
                  backgroundColor: "#ffe7e3",
                  border: 0,
                  lineHeight: "2em",
                  margin: "15px 0 0",
                }}
                onClick={handleForgotPass}
              >
                FORGOT PASSWORD
              </button>
            </div>
          </form>
        </TabPanel>
      </Box>

      <p
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          closeForgetPassword();
        }}
      >
        Back To Log In
      </p>
    </div>
  );
};

export default ForgetPassword;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
