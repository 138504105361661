import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import errorModalReducer from "./errorModalReducer";
import NavBarReducer from "./NavBarReducer";
import Reducer from "./Reducer";
import ShopProductDetailReducer from "./ShopProductDetailReducer";

const rootReducer = combineReducers({
  mainReducer: persistReducer(
    {
      key: "baij-main",
      storage,
    },
    Reducer
  ),
  ShopProductDetailReducer: ShopProductDetailReducer,
  NavBarReducer: NavBarReducer,
  errorModalReducer: errorModalReducer,
});

// const Store = createStore(rootReducer, undefined, applyMiddleware(thunk));

export default rootReducer;
