import React, { useEffect, useState } from "react";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Additionalinfo from "./Additionalinfo";
import { HttpPost } from "../helper/Api";
import { useDispatch, useSelector } from "react-redux";
import { SET_CART_DATA, SET_TOTAL_CART_COUNT } from "./Store/ActionTypes";
import { getCartItem } from "../utils/utils";
import { decrypt, encrypt } from "../helper/utils";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@material-ui/core";
import ResponsiveChildItemDetail from "./imageMgnify/ResponsiveChildItemDetail";
import { SET_OPEN_LOGIN, SET_WISH_LIST } from "./Store/Reducer";
import { Box, Grid, Typography } from "@mui/material";
import SimilarItems from "./SimilarItemCards";
import { useLocation, useNavigate } from "react-router-dom";

const ShopProduct = () => {
  const allStateData = useSelector((store) => store.mainReducer);
  const locationNav = useLocation()
  const [itemData, setItemData] = useState({});
  const itemUrlInfo = locationNav.state?.externalProduct
    ? JSON.stringify(locationNav.state?.externalProduct)
    : allStateData.externalProduct
      ? JSON.stringify(allStateData.externalProduct)
      : `{}`;
  // const itemUrlInfo = decrypt()?.externalProduct
  //   ? JSON.stringify(decrypt()?.externalProduct)
  //   : allStateData.externalProduct
  //     ? JSON.stringify(allStateData.externalProduct)
  //     : `{}`;
  const item = JSON.parse(itemUrlInfo) ? JSON.parse(itemUrlInfo) : {};
  // const [attribDesc, setAttribDescription] = useState("");
  // const [selectedAttribObj, setSelectedAttribObj] = useState({});
  // const [responseData, setResposeData] = useState([]);
  const dispatch = useDispatch();
  const [bomDetails, setBomDetails] = useState([]);
  const [similarItemsList, setSimilarItemsList] = useState([]);
  const navigate = useNavigate();

  // attribDesc STATE

  // const handleChange = (event) => {
  //   setAttribDescription(event.target.value);
  // };

  // const fetchQuery = async () => {
  //   const queryBody = {
  //     QuerySettingId: 1000514,
  //     QuerySettingValues: item?.CATEGORY,
  //   };
  //   const res = await HttpPost("searchLov", queryBody);
  //   if (res?.success) {
  //     setResposeData(res.data?.Response);
  //   }
  // };

  // Add to cart function
  const addToCartData = async (itemData, cartType = "C") => {
    // if( responseData.length !== 0 && !attribDesc ){
    //   return alert("Please select ATTRIB_DESC")
    // }
    let addItemObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iMode: "STOCK-LIST-ESTIMATION-B2C",
      iScanText: `${itemData.STOCK_CODE}^INV^5044^5045^153^IDLE-STOCK^^${cartType}`,
    };
    // let updateItemAttribDescObj = {
    //   iConfigId: "5033",
    //   iTransCategoryLinkId: "5033",
    //   iMenuID: "1000070",
    //   iWcId: 0,
    //   iWcGroupId: 0,
    //   iTransItemId: item?.TRANS_ITEM_ID,
    //   iTransType: "SOR",
    //   vMode: "TRANSATTRIBUTE",
    //   vColumn: "INSERT",
    //   vEditText: attribDesc,
    //   dblEditText: selectedAttribObj?.ATTRIB_ID,
    // };
    // console.log(updateItemAttribDescObj);
    const result = await HttpPost("AddtoCart", addItemObj);
    if (result.success) {
      const res = await getCartItem();
      if (res) {
        dispatch({
          type: SET_CART_DATA,
          payload: res,
        });
        // Update selected ATTRIB
        //  const newUpdateQueryRes= await HttpPost('pkg_cell_edit_ending',updateItemAttribDescObj);
        //  if(newUpdateQueryRes.success){
        //   console.log("success");
        //  }else{
        //   console.log(newUpdateQueryRes.error)
        //  }
      }

      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: result.rec_count,
      });
      let currentItem = similarItemsList?.filter(
        (item) => item?.STOCK_CODE === itemData?.STOCK_CODE
      )?.[0];
      if (currentItem) {
        setSimilarItemsList((prev) =>
          prev.map((similarItemInfo) => ({
            ...similarItemInfo,
            cartStatus:
              currentItem?.STOCK_CODE == similarItemInfo?.STOCK_CODE
                ? true
                : similarItemInfo?.cartStatus,
          }))
        );
      }
      toast.success("Added to cart");
    }
  };

  const fetDetailsOfProduct = async () => {
    if (!item?.STOCK_CODE) return;
    let body = {
      STOCK_CODE: item?.STOCK_CODE,
      CATALOGUE_ID: 966924,
    };
    const res = await HttpPost("SearchTrouveStockCode", body);
    if (res.success) {
      setItemData(
        res?.ItemData?.[0]
          ? {
            ...res?.ItemData?.[0],
            cartStatus: isCheckInCart(res?.ItemData?.[0]),
          }
          : {}
      );
      body = {
        STOCK_CODE: item?.STOCK_CODE,
      };
      const result = await HttpPost("getBomForWeb", body);
      if (result.success) {
        setBomDetails(result?.data);
      }
    }
  };

  useEffect(() => {
    if (
      allStateData?.apiKey?.length &&
      sessionStorage.getItem("token__key") !== null
    ) {
      fetDetailsOfProduct();
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [allStateData?.externalProduct, item?.STOCK_CODE]);

  // Update ATTRIBSetail
  // const handleAttribDetail = (selectDetails) => {
  //   setSelectedAttribObj(selectDetails);
  // };
  // onClick={() => handleAttribDetail(val)}

  const isCheckInCart = (cart) => {
    let isCheck = false;
    for (let index = 0; index < allStateData?.cartData?.length; index++) {
      const element = allStateData?.cartData[index];
      if (element?.StockCode === cart?.STOCK_CODE) {
        isCheck = true;
        break;
      }
    }
    return isCheck;
  };

  const deleteFromCart = async (elem) => {
    const clickedItem = allStateData?.cartData?.filter(
      (item) => item?.StockCode === elem?.STOCK_CODE
    )?.[0];
    if (!clickedItem) return;
    let delCartObj = {
      iGuid: localStorage.getItem("token__key")?.length
        ? localStorage.getItem("token__key")
        : sessionStorage.getItem("token__key"),
      vMode: "DELETE",
      iMode: "ITEM",
      iScanText: "",
      TransItemId: clickedItem?.TransItemId,
      iTransItemBomId: 0,
      iTransItemOperationId: 0,
    };

    let res = await HttpPost("pkg_cell_edit_ending", delCartObj);
    if (res.success) {
      //   const cartIndex = CartItem.findIndex((cart) => cart.TransItemId === transItemId);
      //   setCartItem((prevLists) => ([
      //     ...prevLists?.slice(0, cartIndex),
      //     ...prevLists?.slice(cartIndex + 1)
      //   ]))

      const tempCartData = JSON.parse(JSON.stringify(allStateData.cartData));
      const cartIndex = tempCartData.findIndex(
        (cart) => cart.TransItemId === clickedItem?.TransItemId
      );
      tempCartData.splice(cartIndex, 1);
      dispatch({
        type: SET_CART_DATA,
        payload: tempCartData,
      });
      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: allStateData?.cartCount - 1,
      });
      let currentItem = similarItemsList?.filter(
        (item) => item?.STOCK_CODE === elem?.STOCK_CODE
      )?.[0];
      if (currentItem) {
        setSimilarItemsList((prev) =>
          prev.map((similarItemInfo) => ({
            ...similarItemInfo,
            cartStatus:
              currentItem?.STOCK_CODE == similarItemInfo?.STOCK_CODE
                ? false
                : similarItemInfo?.cartStatus,
          }))
        );
      }
      toast.success("Removed from cart");
    }
  };

  useEffect(() => {
    if (JSON.stringify(itemData) !== "{}") {
      setItemData((prev) => ({
        ...prev,
        cartStatus: isCheckInCart(itemData),
        wishListStatus: isCheckInWishList(itemData),
      }));
    }
  }, [allStateData?.cartData, allStateData?.wishListData, item?.STOCK_CODE]);

  const handleWishList = async () => {
    if (allStateData.userInfo === "") {
      dispatch({ type: SET_OPEN_LOGIN, payload: true });
      return;
    }
    let body = {
      MODE: "A",
      STOCK_CODE: itemData?.STOCK_CODE,
      VARIANT_ID: itemData?.VARIANT_ID,
      PARTY_ID: allStateData?.partyId,
    };
    let res = await HttpPost("ManageWishlist", body);
    if (res.success) {
      dispatch({
        type: SET_WISH_LIST,
        payload: [...res?.data],
      });

      toast.success("Added into wishlist.");
    }
  };

  const isCheckInWishList = (cart) => {
    let isCheck = false;
    if (allStateData?.userInfo === "") return false;
    for (let index = 0; index < allStateData?.wishListData?.length; index++) {
      const element = allStateData?.wishListData[index];
      if (element?.STOCK_CODE === cart?.STOCK_CODE) {
        isCheck = true;
        break;
      }
    }
    return isCheck;
  };

  const getSearchTrouveStockCode = async (searchStockCode) => {
    let response = await HttpPost("SearchTrouveStockCode", {
      CATALOGUE_ID: process.env.REACT_APP_CATALOGUE_ID,
      STOCK_CODE: searchStockCode,
    });

    if (response?.success) {
      let similarItemDataWithCartStatus = response?.ItemData?.length
        ? response?.ItemData?.map((itemInfo) => ({
          ...itemInfo,
          cartStatus: false,
        }))
        : [];
      setSimilarItemsList(similarItemDataWithCartStatus);
    }
  };
  // Get Similar Item Data
  const getSimilarItemData = async () => {
    let result = await HttpPost("getSimilarItemForWeb", {
      STOCK_CODE: item?.STOCK_CODE,
    });
    if (result?.success) {
      if (result?.data?.[0]?.STOCK_CODE) {
        getSearchTrouveStockCode(result?.data?.[0]?.STOCK_CODE);
      }
    }
  };

  useEffect(() => {
    let isItemDetailPageActive = true;
    if (isItemDetailPageActive && item?.STOCK_CODE) {
      getSimilarItemData();
    }

    return () => {
      isItemDetailPageActive = false;
    };
  }, [item?.STOCK_CODE]);

  const handleNavigateToItemDetailPage = (itemInfo) => {
    navigate(
      `/shop/product`,
      {
        state: {
          externalProduct: { STOCK_CODE: itemInfo?.STOCK_CODE }
        }
      }
    );
  };

  if (!item?.STOCK_CODE) {
    return (
      <div style={{ height: "500px", paddingTop: "25px" }}>No Data found</div>
    );
  }

  return (
    <div className="productContainer">
      {itemData?.STOCK_CODE ? (
        <>
          <div className="productContainer__product">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <ResponsiveChildItemDetail key={"image"} itemData={itemData} />
              {/* <img src={itemData?.IMAGE_NAME} alt="..." /> */}
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  flex: 1,
                  // maxWidth: "200px",
                  overflow: "auto",
                }}
              >
                {itemData?.IMAGE_LIST?.split("|")?.map((image, i) => (
                  <img
                    key={image + i}
                    src={image}
                    alt="..."
                    style={{
                      height: "80px",
                      width: "80px",
                      border:
                        itemData?.IMAGE_NAME === image
                          ? "1px solid #d7d7d7"
                          : "none",
                      cursor: "pointer",
                      flex: 0,
                      ":hover": {
                        border: "1px solid #d7d7d7",
                      },
                    }}
                    onClick={() =>
                      setItemData((prev) => ({ ...prev, IMAGE_NAME: image }))
                    }
                  />
                ))}
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                gap: "18px",
              }}
            >
              <div className="productContainer__product__details">
                <h1>{itemData?.DESCRIPTION?.split("|")?.[0]}</h1>
                <h4 style={{ fontSize: "17px" }}>
                  {itemData?.DESCRIPTION?.split("|")?.[1]}
                </h4>
                <h5>₹{itemData.TRANS_AMOUNT}</h5>
                {itemData?.cartStatus ? (
                  <button onClick={() => deleteFromCart(itemData)}>
                    REMOVE FROM CART
                  </button>
                ) : (
                  <button onClick={() => addToCartData(itemData)}>
                    ADD TO CART
                  </button>
                )}
                <p
                  className="productContainer__product__details__fav"
                  onClick={() => {
                    if (!itemData?.wishListStatus) {
                      handleWishList();
                    }
                  }}
                >
                  <FavoriteBorderIcon />{" "}
                  {itemData?.wishListStatus ? "SAVED" : "SAVE"} FOR LATER
                </p>
                <p className="productContainer__product__details__info">
                  SKU: {itemData?.VARIANT_NAME}
                </p>
                <p className="productContainer__product__details__info">
                  CATEGORIES: {itemData?.CATEGORY}
                </p>
                <p className="productContainer__product__details__info">
                  TAG: {itemData?.STOCK_CODE}
                </p>
                {itemData?.ATTRIBUTE?.filter(
                  (attribInfo) => attribInfo?.ATTRIB_TYPE == "LINE OF BUSINESS"
                )?.[0]?.ATTRIB_DESC == "GOLD" ? (
                  <p className="productContainer__product__details__info" style={{ lineHeight: '20px', fontWeight: '600' }}>
                    The BOM rate includes the gold rate and making charges of the product
                  </p>
                ) : null}
              </div>
              {/* Similar Item Card */}
              {similarItemsList?.length ? (
                <SimilarItems
                  similarItemList={similarItemsList}
                  RemoveItemFromCart={deleteFromCart}
                  AddItemToCartData={addToCartData}
                  handleNavigateToItemDetailPage={
                    handleNavigateToItemDetailPage
                  }
                />
              ) : null}
            </Box>
          </div>
          <Grid
            sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}
          >
            <Additionalinfo data={itemData?.STOCK_CODE} itemData={itemData} />
            <Grid
              sx={{ p: "0px", pr: "30px", width: "60%" }}
              className="tableContainer"
            >
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "20px",
                  fontWeight: 400,
                  padding: "12px 59px 16px",
                  fontFamily: "Cormorant",
                  fontStyle: "italic",
                  border: "1px solid rgb(0, 0, 0)",
                  width: "250px",
                  m: "0 5px 30px",
                  color: "#5a5a5a",
                }}
              >
                Bom Information
              </Typography>

              <table className="table" style={{ borderSpacing: "12px" }}>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        textAlign: "left",
                        borderLeft: "1px solid inherit",
                        color: "#670f3b",
                      }}
                    >
                      ITEM GROUP
                    </th>
                    <th
                      scope="col"
                      style={{
                        textAlign: "left",
                        borderLeft: "1px solid inherit",
                        color: "#670f3b",
                      }}
                    >
                      VARIANT NAME
                    </th>
                    <th
                      scope="col"
                      style={{
                        textAlign: "right",
                        borderLeft: "1px solid inherit",
                        color: "#670f3b",
                      }}
                    >
                      PIECES
                    </th>
                    <th
                      scope="col"
                      style={{
                        textAlign: "right",
                        borderLeft: "1px solid inherit",
                        color: "#670f3b",
                      }}
                    >
                      WEIGHT
                    </th>
                    <th
                      scope="col"
                      style={{
                        textAlign: "right",
                        borderLeft: "1px solid inherit",
                        color: "#670f3b",
                      }}
                    >
                      BOM RATE
                    </th>
                    <th
                      scope="col"
                      style={{
                        textAlign: "right",
                        borderLeft: "1px solid inherit",
                        color: "#670f3b",
                      }}
                    >
                      BOM AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bomDetails?.map((row, i) => (
                    <tr key={row?.ITEM_GROUP + i} className="tableRow">
                      <td style={{ padding: "0.5rem 0.5rem !important" }}>
                        {row?.ITEM_GROUP}
                      </td>
                      <td style={{ padding: "0.5rem 0.5rem !important" }}>
                        {row?.VARIANT_NAME}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "0.5rem 0.5rem !important",
                        }}
                      >
                        {Number(row?.PIECES)?.toFixed(0)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "0.5rem 0.5rem !important",
                        }}
                      >
                        {Number(row?.WEIGHT)?.toFixed(3)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "0.5rem 0.5rem !important",
                        }}
                      >
                        {Number(row?.TRANS_BOM_RATE)?.toFixed(2)}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "0.5rem 0.5rem !important",
                        }}
                      >
                        {Number(row?.TRANS_BOM_AMOUNT)?.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
          </Grid>
          {/* <RelatedProducts add={itemData} /> */}
        </>
      ) : (
        <div
          style={{
            height: "500px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default ShopProduct;
