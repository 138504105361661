import React, { useState } from "react";
import "./ContactUs.css";
import contactUs1 from "../../image/contact-us1.jpg";
import { Button, TextField } from "@mui/material";
import { HttpPost } from "../../helper/Api";
import toast from "react-hot-toast";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const setContactHandler = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setContact(value);
    }
  };
  const sendMailHandler = async () => {
    if (email.split("@").length - 1 !== 1) {
      toast.error("Invalid Email");
      return;
    }
    const body = {
      name,
      contact,
      query,
      email,
    };
    let res = await HttpPost("contactMail", body);
    if (res.success) {
      setName("");
      setContact("");
      setEmail("");
      setQuery("");
      toast.success("Send Query SuccessFully");
    }
  };
  const EmailHandler = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  return (
    <div className="contact-us">
      <div className="contact-us__img">
        <img src={contactUs1} alt="" />
      </div>
      <div className="contact-us__details">
        <div className="contact-us-details__whatsapp">
          <h5>Whatsapp</h5>
          <p>+ 91 9984500051</p>
          <p>+ 91 9984500052</p>
        </div>
        <div className="contact-us-details__call">
          <h5>Call Us At</h5>
          <p>+ 91 9984500051</p>
          <p>+ 91 9984500052</p>
        </div>
        <div className="contact-us-details__email">
          <h5>Email Us At</h5>
          <p>info@baijnathjewelers.com</p>
        </div>
        <div className="contact-us-details__opening">
          <h5>Opening Hours</h5>
          <p>Monday-Saturday</p>
          <p>11 AM – 8PM</p>
        </div>
      </div>
      <div className="contact-us__footer">
        <div className="contact-us__footer__form">
          <h2>Ask Us Anything</h2>
          <div className="contact-us__footer__form__inputs">
            <TextField
              id="standard-basic"
              label="Name"
              variant="standard"
              required={true}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              id="standard-basic"
              label="Phone no."
              type="number"
              variant="standard"
              required={true}
              value={contact}
              onChange={setContactHandler}
            />
            <TextField
              id="standard-basic"
              label="Email"
              variant="standard"
              required={true}
              value={email}
              onChange={EmailHandler}
            />
            <TextField
              id="standard-basic"
              label="Query"
              variant="standard"
              required={true}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
          </div>
          <Button
            size="small"
            disabled={
              name == "" || query == "" || contact.length != 10 || email == ""
            }
            onClick={sendMailHandler}
            sx={{
              borderRadius: "0px",
              border: "1px solid rgb(175, 174, 174)",
              mt: "10px",
              mr: "10px",
              minWidth: "0px !important",
              background: "#FFFFFF",
              color: "black",
              ":hover": {
                background: "#111111",
                color: "white",
              },
            }}
          >
            SHARE
          </Button>
        </div>
        <div className="contact-us__footer__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.4137887773945!2d80.3407831143557!3d26.474618685186783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c389f51746d0b%3A0x245f51b8e07af19c!2sBaijnath%20Jewellers!5e0!3m2!1sen!2sin!4v1660732367455!5m2!1sen!2sin"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
