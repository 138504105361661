import React, { useEffect, useState } from "react";
import caro1 from "../image/caro1.jpg";
import caro2 from "../image/caro2.jpg";
import earingGrid1 from "../image/earing-grid1.jpg";
import earingGrid2 from "../image/earing-grid2.jpg";
import earingGrid3 from "../image/earing-grid3.jpg";
import earing2 from "../image/earing-row2.jpg";
import card1 from "../image/card-1.jpg";
import card2 from "../image/card-2.jpg";
import card3 from "../image/card-3.jpg";
import card4 from "../image/card-4.jpg";
import shopbangle from "../image/shop-bangle.jpg";
import shopearing from "../image/shop-earings.jpg";
import shopnecklace from "../image/shop-necklace.jpg";
import shoprings from "../image/shop-rings.jpg";
import icon1 from "../image/icon1.png";
import lifeexchange from "../image/lifetime-exchange.png";
import purity from "../image/purity.png";
import latest from "../image/latest.png";
import RemoveIcon from "@mui/icons-material/Remove";
import { SET_IS_SEARCH_DATA } from "./Store/ShopProductDetailReducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpPost } from "../helper/Api";
import {
  SET_CART_DATA,
  SET_FILTER_DATA,
  SET_TOTAL_CART_COUNT,
} from "./Store/ActionTypes";
import { encrypt, extractUserDefineError } from "../helper/utils";
import { getCartItem, selectedFilter } from "../utils/utils";
import {
  SET_OPEN_LOGIN,
  SET_SELECTED_PRODUCT_SAVED,
  SET_WISH_LIST,
} from "./Store/Reducer";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
function MainPage() {
  const dispatch = useDispatch();
  const allStateData = useSelector((store) => store.mainReducer);
  const { filterData } = useSelector((store) => store.NavBarReducer);
  const { searchData, isSearch, sortData } = useSelector(
    (store) => store.ShopProductDetailReducer
  );
  const navigate = useNavigate();
  const [cateAndLobBuckets, setCateAndLobBuckets] = useState([]);
  const [earringdata, setEarringData] = useState([]);
  const location = useLocation();

  const bucketsFilterList = [
    "All Jewellery",
    "Rings",
    "Earrings",
    "Necklace",
    "Bracelet",
    // "Other Jewellery",
    "Coins",
    "Silver",
    "Digi Gold",
    "Collections",
    // "Shop Aaina",
    "EZEE Buy",
    // "About us",
  ];
  const navigateToAnotherPage = (navName) => {
    navigate(navName + "/" + '');
  };
  const getDynamicEarrings = async () => {
    try {
      const checkedFilterLists = selectedFilter(filterData);
      let body = {
        CART_ITEM_COUNT: 25,
        SESSION_ID: "352",
        PARTY_ID: "12",
        CATALOGUE_ID: "966924",
        PARTY_NAME: "JIGNESH",
        COMPANY_ID: "0",
        LOCATION_ID: "0",
        IMAGE_FOLDER_URL: null,
        LOGIN_NAME: "ADMIN",
        EMPLOYEE_TYPE: "ADMIN",
        SHOW_ONLY_GW_WT: "0",
        MASTER_URL: null,
        SEARCH_MODE: "ITEM",
        SEARCH_FILTER: {
          PAGE_NO: 1,
          FILTER_DATA: checkedFilterLists,
          SORT: sortData?.length ? sortData : "",
          TEXT: isSearch ? (searchData ? searchData : "") : "",
        },
        DISPLAY_MODE: "SCREEN",
      };
      const res = await HttpPost("getItemData", body);
      if (res.success) {
        setEarringData(res?.ItemData);
      }
    } catch (error) {
      extractUserDefineError(error);
    }
  };
  useEffect(() => {
    getAllCatlogues();
  }, []);
  useEffect(() => {
    getDynamicEarrings();
  }, [filterData]);
  useEffect(() => {
    if (earringdata?.length) {
      setEarringData((prev) =>
        prev?.map((item) => ({
          ...item,
          cartStatus: isCheckInCart(item),
          wishListStatus: isCheckInWishList(item),
        }))
      );
    }
  }, [
    allStateData?.wishListData?.length,
    allStateData?.cartData?.length,
    earringdata?.length,
  ]);
  const isCheckInCart = (cart) => {
    let isCheck = false;
    for (let index = 0; index < allStateData?.cartData?.length; index++) {
      const element = allStateData?.cartData[index];
      if (element?.StockCode === cart?.STOCK_CODE) {
        isCheck = true;
        break;
      }
    }
    return isCheck;
  };
  const isCheckInWishList = (cart) => {
    let isCheck = false;
    if (allStateData?.userInfo === "") return false;
    for (let index = 0; index < allStateData?.wishListData?.length; index++) {
      const element = allStateData?.wishListData[index];
      if (element?.STOCK_CODE === cart?.STOCK_CODE) {
        isCheck = true;
        break;
      }
    }
    return isCheck;
  };
  const getAllCatlogues = async () => {
    // setCateAndCollBuckets([]);
    // setCateAndLobBuckets([]);
    let body = {
      CATALOGUE_ID: "966924",
    };
    let res = await HttpPost("MainPageListing", body);
    if (res.success) {
      setCateAndLobBuckets([
        {
          dynamicId: 0,
          key: "All Jewellery",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        ...res.data?.CATEGORY_AND_LOB?.buckets?.map((bucket) =>
          bucketsFilterList.includes(bucket.key)
            ? {
                ...bucket,
                dynamicId: bucketsFilterList.indexOf(bucket.key),
              }
            : { ...bucket, dynamicId: bucketsFilterList.length + 1 }
        ),
        // {
        //   key: "Other Jewellery",
        //   doc_count: 0,
        //   LOB: {
        //     buckets: [],
        //   },
        // },
        {
          id: 7,
          key: "Digi Gold",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          id: 8,
          key: "Collections",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          dynamicId: bucketsFilterList.length + 1,
          key: "Shop Aaina",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          dynamicId: 10,
          key: "EZEE Buy",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          dynamicId: 10,
          key: "About us",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
      ]);
    }
  };
  const handleClickOnSub = (
    seleCtedValues = [],
    filterName,
    isAllJewelllery = false
  ) => {
    let selectedValueFilter = [];
    if (filterName) {
      selectedValueFilter = [
        {
          filterType: "PARENT CATEGORY",
          key: filterName,
        },
        ...cateAndLobBuckets
          ?.filter(
            (categoryFilterKey) => categoryFilterKey?.key == filterName
          )?.[0]
          ?.LOB?.buckets?.filter((lob) => lob?.key !== "AAINA")
          ?.map((lob) => ({
            filterType: "LINE OF BUSINESS",
            key: lob?.key,
          })),
        ...(filterName === "Silver"
          ? [
              {
                filterType: "LINE OF BUSINESS",
                key: "AAINA",
              },
            ]
          : []),
      ];
    } else {
      selectedValueFilter = seleCtedValues;
    }
    dispatch({ type: SET_IS_SEARCH_DATA, payload: false });
    let data = filterData.map((nFilter) => {
      if (nFilter?.FILTER_TYPE === "RANGE") {
        const filteringData = selectedValueFilter?.filter(
          (obj2) =>
            nFilter.FILTER_HEADING === obj2.filterType &&
            nFilter?.FILTER_TYPE === obj2?.key
        );

        if (filteringData?.length) {
          return {
            ...nFilter,
            INPUT_MIN_VALUE: `${filteringData[0]?.value[0]}`,
            INPUT_MAX_VALUE: `${filteringData[0]?.value[1]}`,
          };
        } else {
          return { ...nFilter, INPUT_MIN_VALUE: `0`, INPUT_MAX_VALUE: `0` };
        }
      } else {
        const filteringData = selectedValueFilter?.filter(
          (obj2) =>
            nFilter.FILTER_HEADING === obj2.filterType &&
            nFilter?.FILTER_ID === obj2?.key
        );
        if (filteringData?.length) {
          return { ...nFilter, IS_CHECKED: "1" };
        }
      }
      return { ...nFilter, IS_CHECKED: "0" };
    });
    if (isAllJewelllery) {
      data = data?.filter(
        (itemFilterInfo) => itemFilterInfo?.FILTER_ID != "SILVER"
      );
    }
    dispatch({
      type: SET_FILTER_DATA,
      payload: data,
    });
    navigateToAnotherPage("shop");
  };
  const handleImageClick = (itemData) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_SAVED,
      payload: itemData,
    });

    // navigate(
    //   `/shop/product/?q=${encrypt({
    //     apiKey: allStateData?.apiKey,
    //     externalProduct: { STOCK_CODE: itemData?.STOCK_CODE },
    //   })}`
    // );
    navigate(
      `/shop/product/`,
      {
        state: {
          externalProduct: { STOCK_CODE: itemData?.STOCK_CODE }
        }
      }
    );
  };

  const deleteCartItem = async (elem) => {
    const obj = allStateData?.cartData?.filter(
      (item) => item?.VariantId === elem?.VARIANT_ID
    )?.[0];
    if (obj) {
      let delCartObj = {
        iGuid: localStorage.getItem("token__key")?.length
          ? localStorage.getItem("token__key")
          : sessionStorage.getItem("token__key"),
        vMode: "DELETE",
        iMode: "ITEM",
        iScanText: "",
        TransItemId: obj?.TransItemId,
        iTransItemBomId: 0,
        iTransItemOperationId: 0,
      };

      let res = await HttpPost("pkg_cell_edit_ending", delCartObj);
      if (res.success) {
        //   const cartIndex = CartItem.findIndex((cart) => cart.TransItemId === transItemId);
        //   setCartItem((prevLists) => ([
        //     ...prevLists?.slice(0, cartIndex),
        //     ...prevLists?.slice(cartIndex + 1)
        //   ]))

        const tempCartData = JSON.parse(JSON.stringify(allStateData.cartData));
        const cartIndex = tempCartData.findIndex(
          (cart) => cart.TransItemId === obj?.TransItemId
        );
        tempCartData.splice(cartIndex, 1);
        dispatch({
          type: SET_CART_DATA,
          payload: tempCartData,
        });
        dispatch({
          type: SET_TOTAL_CART_COUNT,
          payload: allStateData?.cartCount - 1,
        });
        toast.success("Removed from cart.");
      }
    }
  };
  const handleAddToCartData = async (itemData, cartType = "C") => {
    let addItemObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iMode: "STOCK-LIST-ESTIMATION-B2C",
      iScanText: `${itemData.STOCK_CODE}^INV^5044^5045^153^IDLE-STOCK^^${cartType}`,
    };
    const result = await HttpPost("AddtoCart", addItemObj);
    if (result.success) {
      const res = await getCartItem();
      if (res) {
        dispatch({
          type: SET_CART_DATA,
          payload: res,
        });
      }
      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: result.rec_count,
      });
      toast.success("Added to the cart.");
      // navigate(`/cart/`, { state: itemData })
    }
  };
  const handleAddToWishList = async (elem) => {
    if (allStateData.userInfo === "") {
      dispatch({ type: SET_OPEN_LOGIN, payload: true });
      return;
    }
    let body = {
      MODE: "A",
      STOCK_CODE: elem?.STOCK_CODE,
      VARIANT_ID: elem?.VARIANT_ID,
      PARTY_ID: allStateData?.partyId,
    };
    let res = await HttpPost("ManageWishlist", body);
    if (res.success) {
      dispatch({
        type: SET_WISH_LIST,
        payload: [...res?.data],
      });
      toast.success("Added into wishlist.");
    }
  };
  const deleteWishListItem = async (elem) => {
    const obj = allStateData?.wishListData?.filter(
      (item) => item?.STOCK_CODE === elem?.STOCK_CODE
    )?.[0];
    if (obj) {
      let body = {
        MODE: "D",
        PK_ID: obj?.PK_ID,
        PARTY_ID: allStateData?.partyId,
      };
      let res = await HttpPost("ManageWishlist", body);
      if (res.success) {
        dispatch({
          type: SET_WISH_LIST,
          payload: res.data ? [...res.data] : [],
        });
        toast.success("Removed from wishlist.");
      }
    }
  };
  return (
    <div>
      <div
        id="carouselExampleControls"
        className="carousel slid"
        data-bs-ride="carousel"
        style={{ marginTop: "0px", maxHeight: `${window.screen.height}px` }}
      >
        <div className="carousel-inner">
          <div className="carousel-item active item" data-bs-interval="3000">
            <img
              src={caro1}
              className="d-block mySlides"
              alt="..."
              style={{ maxWidth: "100%", marginTop: "-80px" }}
            />
            <div className="carousel-caption col-4">
              <h5
                className="carousel_cap py-1 lineUp"
                style={{ marginLeft: "50px", fontSize: "42px" }}
              >
                SOLEIL
              </h5>
              <p
                className="carousel_para lineUp2"
                style={{ marginLeft: "50px", fontSize: "18px" }}
              >
                Drops of light in elegant gold, to make everyday more special.
              </p>
              <button
                className="carosal-btn lineUp3"
                style={{ marginLeft: "50px" }}
              >
                SHOP NOW
              </button>
            </div>
          </div>
          <div className="carousel-item item" data-bs-interval="3000">
            <img
              src={caro2}
              className="d-block w-100 mySlides"
              alt="..."
              style={{ maxWidth: "100%", marginTop: "-80px" }}
            />
            <div className="carousel-caption col-4">
              <h5
                className="carousel_cap py-1 lineUp"
                style={{ marginLeft: "50px", fontSize: "42px" }}
              >
                ROSETTA
              </h5>
              <p
                className="carousel_para lineUp2"
                style={{ marginLeft: "50px", fontSize: "18px" }}
              >
                Unique Italian jewellery, delicately detailed by master artisans
              </p>
              <button
                className="carosal-btn lineUp3"
                style={{ marginLeft: "50px" }}
              >
                SHOP NOW
              </button>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev carousel-control left"
          type="button"
          style={{
            fontFamily: ["Cormorant", "serif"],
            fontSize: "16px",
            fontStyle: "italic",
            color: "#FFFFFF",
          }}
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span>Prev</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          style={{
            fontFamily: ["Cormorant", "serif"],
            fontSize: "16px",
            fontStyle: "italic",
            color: "#FFFFFF",
          }}
          data-bs-slide="next"
        >
          <span>Next</span>
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <div className="speacial-occasion">
        <div className="special-occasion__content">
          <p>Special</p>
          <h3>OCCASIONWEAR</h3>
          <img src={earingGrid1} alt="" />
        </div>
        <div
          className="special-occasion__content"
          style={{ display: "flex", flexDirection: "column", gap: "30px" }}
        >
          <div className="special-occasion__content__right">
            <h3>FOR EVERYDAY</h3>
            <p>Special</p>
            <img src={earingGrid2} alt="" />
          </div>
          <div className="special-occasion__content__right">
            <h3>GIFT OF NATURE</h3>
            <p>Special</p>
            <img src={earingGrid3} alt="" />
          </div>
        </div>
      </div>
      <div className="diamond">
        <div className="diamond__content-left">
          <img src={earing2} alt="" />
        </div>
        <div className="diamond__content-right">
          {earringdata?.length
            ? earringdata.slice(0, 4).map((item, index) => (
                <div key={index + 1} className="diamond__content-right__card" style={{marginLeft:'3px'}}>
                  <img
                    src={item?.IMAGE_NAME}
                    className="card-img-top"
                    alt="..."
                  onClick={() => handleImageClick(item)}
                  style={{width:"380px",height:"270px"}}
                  />
                  <p className="name">{item?.CATEGORY}</p>
                  <p className="price">₹ {item?.TRANS_AMOUNT}</p>
                  <div
                    className="diamond__content-right__card__btn"
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    {item?.cartStatus ? (
                      <Button
                        className="add-btn btn-1"
                        onClick={() => deleteCartItem(item)}
                        size="small"
                        sx={{
                          borderRadius: "0px",
                          border: "1px solid rgb(175, 174, 174)",
                          m: "0px",
                          minWidth: "0px !important",
                          background: "#FFFFFF",
                          svg: {
                            height: "100%",
                            width: "100%",
                            path: {
                              fill: "black",
                            },
                          },
                          ":hover": {
                            background: "#111111",
                            svg: {
                              path: {
                                fill: "#FFFF",
                              },
                            },
                          },
                        }}
                      >
                        <RemoveIcon />
                      </Button>
                    ) : (
                      <button
                        className="add-btn btn-1"
                        onClick={() => handleAddToCartData(item)}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    )}
                    {item?.wishListStatus ? (
                      <Button
                        className="add-btn btn-1"
                        onClick={() => deleteWishListItem(item)}
                        size="small"
                        sx={{
                          borderRadius: "0px",
                          border: "1px solid rgb(175, 174, 174)",
                          m: "0px",
                          mr: "10px",
                          minWidth: "0px !important",
                          background: "#FFFFFF",
                          svg: {
                            height: "18px",
                            width: "18px",
                            path: {
                              fill: "black",
                            },
                          },
                          ":hover": {
                            background: "#111111",
                            svg: {
                              height: "18px",
                              width: "18px",
                              path: {
                                fill: "#FFFF",
                              },
                            },
                          },
                        }}
                      >
                        <HeartBrokenIcon size="small" />
                      </Button>
                    ) : (
                      <button
                        className="add-btn btn-2"
                        onClick={() => handleAddToWishList(item)}
                      >
                        <i className="fa fa-heart" aria-hidden="true"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-sm necklace-div">
            {/* <img src={neck1} className="img-fluid" alt="..."/> */}
          </div>
          <div className="col-sm main-text-img">
            <div className="innerdiv-text">
              <h2 className="inner-heading">
                Baijnath Gold - Making Weddings Unforgettable
              </h2>
              <p className="div-para">
                Traditions are made more beautiful with Baijnath’s gold wedding
                collection. Crafted in regal styles that make your day
                unforgettable. Discover a wealth of bridal gold jewellery in
                heritage and contemporary designs by Baijnath.
              </p>
              <a
                href="#"
                style={{
                  textDecoration: "none",
                  color: "rgb(167,99,113)",
                  fontSize: "12px",
                  letterSpacing: "2px",
                  ":hover": {
                    color: "#686868",
                  },
                }}
              >
                SHOP GOLD
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mainhome__necklace-div2">
          <div className="col-sm  main-text-img">
            <div className="innerdiv-text">
              <h2 className="inner-heading">
                Baijnath Diamonds - Exquisite, For Years To Come
              </h2>
              <p className="div-para">
                A stunning range of diamond jewellery by Baijnath for the bride
                who loves herself some glamour. From solitaire engagement bands
                to dazzling necklaces encrusted in diamonds and precious stones
                - it’s all in here. Find the diamonds of your dreams with
                Baijnath.
              </p>
              <a
                href="#"
                style={{
                  textDecoration: "none",
                  color: "rgb(167,99,113)",
                  fontSize: "12px",
                  letterSpacing: "2px",
                  ":hover": {
                    color: "#686868",
                  },
                }}
              >
                SHOP DIAMONDS
              </a>
            </div>
          </div>
          <div className="col-sm necklace-div2">
            {/* <img src={neck2} className="img-fluid" alt="..."/> */}
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-lg col-md col-sm">
            <div>
              <img
                src={shopbangle}
                className="img-fluid"
                alt="..."
                onClick={() => {
                  handleClickOnSub("PARENT CATEGORY", "Bangle/ Kara", true);
                }}
                style={{ cursor: "pointer" }}
              />
              <p className="text-center shop-head">SHOP BANGLES</p>
            </div>
          </div>
          <div className="col-lg">
            <div>
              <img
                src={shopearing}
                className="img-fluid"
                alt="..."
                onClick={() => {
                  handleClickOnSub(
                    [{ filterType: "PARENT CATEGORY", key: "Earrings" }],
                    "Earrings",
                    true
                  );
                }}
                style={{ cursor: "pointer" }}
              />
              <p className="text-center shop-head">SHOP EARRINGS</p>
            </div>
          </div>
          <div className="col-lg">
            <div>
              <img
                src={shopnecklace}
                className="img-fluid"
                alt="..."
                onClick={() => {
                  handleClickOnSub(
                    [{ filterType: "PARENT CATEGORY", key: "Necklace" }],
                    "Necklace",
                    true
                  );
                }}
                style={{ cursor: "pointer" }}
              />
              <p className="text-center shop-head">SHOP NECKLACES</p>
            </div>
          </div>
          <div className="col-lg">
            <div>
              <img
                src={shoprings}
                className="img-fluid"
                alt="..."
                onClick={() => {
                  handleClickOnSub(
                    [{ filterType: "PARENT CATEGORY", key: "Rings" }],
                    "Rings",
                    true
                  );
                }}
                style={{ cursor: "pointer" }}
              />
              <p className="text-center shop-head">SHOP RINGS</p>
            </div>
          </div>
        </div>
      </div>

      <div className="main-logo-div">
        <div className="container-fluid logo-group">
          <div className="row justify-content-center align-items-center logo-row">
            <div
              className="col-sm mx-auto logo-last"
              style={{ width: "18rem" }}
            >
              <div className="text-center logo-col">
                <img
                  src={icon1}
                  className="card-img-top logo-img"
                  alt="..."
                  style={{ height: "80px", width: "80px" }}
                />
                <div className="card-body card-body-text">
                  <h5 className="heading-text">CERTIFIED JEWELLERY</h5>
                  <p className="card-text card-para-text">
                    Each purchase comes with bonafide certification so you shine
                    in nothing but the best.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm mx-auto" style={{ width: "18rem" }}>
              <div className="text-center logo-col">
                <img
                  src={lifeexchange}
                  className="card-img-top logo-img"
                  alt="..."
                  style={{ height: "80px", width: "80px" }}
                />
                <div className="card-body card-body-text">
                  <h5 className="heading-text">LIFETIME EXCHANGE</h5>
                  <p className="card-text card-para-text">
                    We guarantee the exchange of Baijnath products or encashment
                    at current value with minor deductions.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm mx-auto" style={{ width: "18rem" }}>
              <div className="text-center logo-col">
                <img
                  src={purity}
                  className="card-img-top logo-img"
                  alt="..."
                  style={{ height: "80px", width: "80px" }}
                />
                <div className="card-body card-body-text">
                  <h5 className="heading-text">PURITY ASSURANCE</h5>
                  <p className="card-text card-para-text">
                    Our precious metals & gems undergo rigorous checks to bring
                    you the confidence of purity.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm mx-auto" style={{ width: "18rem" }}>
              <div className="text-center logo-col">
                <img
                  src={latest}
                  className="card-img-top logo-img"
                  alt="..."
                  style={{ height: "80px", width: "80px" }}
                />
                <div className="card-body card-body-text">
                  <h5 className="heading-text">LATEST DESIGNS</h5>
                  <p className="card-text card-para-text">
                    Our selection is carefully curated by our in-house jewellery
                    experts to meet the latest trends in craft & fashion.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
