import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HttpPost } from "../helper/Api";
//import { useLocation } from 'react-router-dom';

function Additionalinfo(props) {
  // const {state} = useLocation();
  const [activeTab, setActiveTab] = useState("Info");
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [additionInfo, setAdditionInfo] = useState([]);
  const getAdditionalInfo = async () => {
    const res = await HttpPost("searchLov", {
      QuerySettingId: 1000525,
      QuerySettingValues: typeof props?.data == 'object' ? props?.data?.STOCK_CODE : props?.data,
    });
    if (res.success) {
      setAdditionInfo(res?.data?.Response);
    }
  };
  useEffect(() => {
    getAdditionalInfo();
  }, [props.data]);
  return (
    <div className="productContainer__additionalInfo">
      <div className="productContainer__additionalInfo__tabs">
        <button
          style={{ border: activeTab === "Info" ? "1px solid #000" : "" }}
          onClick={() => setActiveTab("Info")}
        >
          Additional Information
        </button>
        {/* <button
          style={{ border: activeTab === "Review" ? "1px solid #000" : "" }}
          onClick={() => setActiveTab("Review")}
        >
          Reviews(0)
        </button> */}
      </div>
      <div className="productContainer__tabs__content">
        {activeTab === "Info" && (
          <div className="productContainer__tabs__info">
            {additionInfo.length &&
              additionInfo
                ?.filter(
                  (item) =>
                    item?.ATTRIB_TYPE !== "FINISH" &&
                    item?.ATTRIB_TYPE !== "SUB-CATEGORY"
                )
                ?.map((data, i) => (
                  <div
                    key={Math.random() + i}
                    className="productContainer__tabs__info__content"
                  >
                    <p className="content__head">{data?.ATTRIB_TYPE}</p>
                    <p>{data?.ATTRIB_VALUE ? data?.ATTRIB_VALUE : "NA"}</p>
                    {data?.ATTRIB_TYPE === "SIZE/LENGTH" &&
                    props?.itemData?.CATEGORY?.includes("Ring") ? (
                      <p>
                        <a
                          href="https://baijnathjewellers.com/wp-content/uploads/2020/10/Ring-size-Guide.pdf"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "#670f3b",
                            fontWeight: 600,
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          Size Guide
                        </a>
                      </p>
                    ) : null}
                    {data?.ATTRIB_TYPE === "SIZE/LENGTH" &&
                    (props?.itemData?.CATEGORY === "Kara" ||
                      props?.itemData?.CATEGORY === "Bangle") ? (
                      <p>
                        <a
                          href="https://baijnathjewellers.com/wp-content/uploads/2020/10/Bangle-size-Guide.pdf"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "#670f3b",
                            fontWeight: 600,
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          Size Guide
                        </a>
                      </p>
                    ) : null}
                  </div>
                ))}
          </div>
        )}
        {activeTab === "Review" && (
          <div
            className="productContainer__tabs__review"
            style={{ marginLeft: 5 }}
          >
            <h2
              style={{
                fontFamily: ["Tenor Sans"],
                fontSize: 18,
                lineHeight: "1.5rem",
                fontWeight: 100,
                margin: "8px 0 24px",
              }}
            >
              Reviews
            </h2>
            <p
              style={{
                fontFamily: ["Montserrat"],
                fontSize: 16,
                lineHeight: "1.5rem",
                fontWeight: 100,
                marginBottom: 4,
              }}
            >
              There are no reviews yet.
            </p>
            <p
              style={{
                fontFamily: ["Montserrat"],
                fontSize: 14,
                fontWeight: 500,
                color: "#838383",
              }}
            >
              Be the first to review “DIAMOND STUDDED ROSE GOLD FLORAL RING”
            </p>
            <p
              style={{
                fontFamily: ["Montserrat"],
                fontSize: 16,
                lineHeight: "1.5rem",
                fontWeight: 100,
                marginBottom: 4,
              }}
            >
              Your email address will not be published. Required fields are
              marked *
            </p>
            <p
              style={{
                fontFamily: ["Montserrat"],
                fontSize: 14,
                fontWeight: 500,
                color: "#838383",
              }}
            >
              Your rating
            </p>
            <div className="star-rating" style={{ display: "flex", gap: 5 }}>
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    key={index}
                    className={index <= (hover || rating) ? "on" : "off"}
                    onClick={() => setRating(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
            <div className="review-textarea">
              <textarea
                rows="7"
                className="review-textarea__review"
                placeholder="Your Review"
              />
              <input
                style={{ height: "auto" }}
                type="text"
                className="review-textarea__review"
                placeholder="Your Name"
              />
              <input
                style={{ height: "auto" }}
                type="text"
                className="review-textarea__review"
                placeholder="Your Email"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox size="small" sx={{ padding: "0 10px 0 0" }} />
              <p
                style={{
                  fontFamily: ["Montserrat"],
                  fontSize: 16,
                  fontWeight: 100,
                  margin: 0,
                  color: "#000",
                }}
              >
                Save my name, email, and website in this browser for the next
                time I comment.
              </p>
            </div>
            <button className="productContainer__tabs__review__submit">
              SUBMIT
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Additionalinfo;
