import React from "react";
import razorpaylogo from "../image/payment.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { SET_ERROR_MODAL_OPEN } from "./Store/errorModalReducer";

const RazorpayProceed = ({ handleEditButtonClick, progress }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [acceptTerms, setAcceptTerm] = useState(false);
  const allStateData = useSelector((store) => store.mainReducer);
  const handleRazorPay = async (e, itemDetails) => {
    if (allStateData?.userInfo?.length === 0 || !acceptTerms) {
      if (allStateData?.userInfo?.length === 0) {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
      dispatch({
        type: SET_ERROR_MODAL_OPEN,
        payload: {
          isOpen: true,
          message:
            allStateData?.userInfo?.length === 0
              ? "Sorry, you need to login Or register your account."
              : "Please accept above terms and conditions.",
          details: "",
        },
      });

      return;
    }
    let res = await handleEditButtonClick(e);
    if (res) {
      navigate(`/paynow/`, { state: itemDetails });
      localStorage.setItem("orderDone", "0");
    }
  };

  return (
    <div className="mb-5">
      <div className="container d-flex justify-content-start">
        <div className="payment position-relative">
          <img
            className="razorpay-logo position-absolute"
            src={razorpaylogo}
            alt="..."
          />
          <p className="payment-mode d-block">
            Credit Card/Debit Card/NetBanking{" "}
          </p>
        </div>
      </div>

      <div
        className="container d-flex justify-content-start mb-4"
        style={{ borderBottom: "1px solid gray" }}
      >
        <p className="mt-2 mb-4" style={{ fontSize: "18px" }}>
          Pay securely by Credit or Debit card or Internet Banking through
          Razorpay.
        </p>
      </div>
      <div className="container text-start">
        <p style={{ fontSize: "18px" }}>
          Your personal data will be used to process your order, support your
          experience throughout this website, and for other purposes described
          in our privacy policy.
        </p>
        <div className=" container form-check text-start">
          <input
            className="form-check-input"
            type="checkbox"
            value={acceptTerms}
            id="flexCheckDefault"
            onChange={(e) => setAcceptTerm(e.target.checked)}
          />
          <label className="form-check-label">
            I have read and agree to the website terms and conditions *
          </label>
        </div>
        <p style={{ fontSize: "18px", fontWeight: 900, marginTop: "8px" }}>
          Desclaimer : Item prices may change subject to change in Metal or
          Stone Rate.
        </p>
      </div>

      <div className="container  d-flex justify-content-start mt-3">
        <button
          className="place-order"
          onClick={(e) => handleRazorPay(e, location.state)}
          disabled={progress}
        >
          PLACE ORDER
        </button>
      </div>
    </div>
  );
};

export default RazorpayProceed;
