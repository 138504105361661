import { SET_SELECTED_PRODUCT } from "./ActionTypes";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
export const SET_SORT_DATA = "SET_SORT_DATA";
export const SET_IS_SEARCH_DATA = "SET_IS_SEARCH_DATA";
const initialState = {
  item: {},
  searchData: "",
  isSearch: false,
  sortData: "",
};
const ShopProductDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        item: action?.payload,
      };
    case SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action?.payload,
      };
    case SET_SORT_DATA:
      return {
        ...state,
        sortData: action?.payload,
      };
    case SET_IS_SEARCH_DATA:
      return {
        ...state,
        isSearch: action?.payload,
      };
    default:
      return state;
  }
};
export default ShopProductDetailReducer;
