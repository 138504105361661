import React from "react";

function Billing({ data, handleChange }) {
  // console.log(props);
  return (
    <div className="container text-start">
      <div className="row">
        <div className="col-6">
          <h3 className="billing_heading mb-4">BILLING DETAILS</h3>
          <div className="">
            <form>
              <div className="row">
                <div className="col">
                  <label className="label-styling">First name *</label>
                  <input
                    type="text"
                    className="form-control billing_input"
                    aria-label="First name"
                    name="firstName"
                    value={data?.firstName}
                    onChange={handleChange}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
                <div className="col">
                  <label className="label-styling">Last name *</label>
                  <input
                    type="text"
                    className="form-control billing_input"
                    aria-label="Last name"
                    name="lastName"
                    value={data?.lastName}
                    onChange={handleChange}
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="form-label label-styling">
                    Company name (optional)
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    value={data?.companyName}
                    onChange={handleChange}
                    className="form-control billing_input"
                    style={{ borderRadius: "0px" }}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12" style={{ lineHeight: "10px" }}>
                  <p className="label-styling">Country / Region*</p>
                  <p className="label-styling">India</p>
                  <div className="row">
                    <div className="col-12">
                      <label className="form-label label-styling">
                        Street Address *
                      </label>
                      <input
                        type="text"
                        className="form-control text-start billing_input"
                        name="Address1"
                        value={data?.Address1}
                        onChange={handleChange}
                        placeholder="House number and street name"
                        style={{ borderRadius: "0px" }}
                      />
                      <input
                        type="text"
                        className="form-control mt-3 text-start billing_input"
                        placeholder="Apartment, suit, unit, etc.(optional)"
                        style={{ borderRadius: "0px" }}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label label-styling">
                        Town / City *
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={data?.city}
                        onChange={handleChange}
                        className="form-control text-start billing_input"
                        style={{ borderRadius: "0px" }}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label label-styling">State</label>
                      <select
                        id="inputState"
                        name="state"
                        value={data?.state}
                        onChange={handleChange}
                        className="form-select billing_input"
                        style={{ borderRadius: "0px" }}
                      >
                        <option
                          style={{
                            fontSize: "17px",
                            fontFamily: "'Tenor Sans', sans-serif",
                            color: "#6b6868",
                          }}
                        >
                          Uttar Pradesh
                        </option>
                        <option
                          style={{
                            fontSize: "17px",
                            fontFamily: "'Tenor Sans', sans-serif",
                            color: "#6b6868",
                          }}
                          value={data?.state}
                        >
                          {data?.state}
                        </option>
                        <option>...</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label label-styling">
                        Pin Code *
                      </label>
                      <input
                        type="text"
                        name="Pincode"
                        value={data?.Pincode}
                        onChange={handleChange}
                        className="form-control text-start billing_input"
                        style={{ borderRadius: "0px" }}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label label-styling">
                        Phone *
                      </label>
                      <input
                        type="text"
                        name="number"
                        value={data?.number}
                        onChange={handleChange}
                        className="form-control text-start billing_input"
                        style={{ borderRadius: "0px" }}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label label-styling">
                        Email address *
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={data?.email}
                        onChange={handleChange}
                        className="form-control text-start billing_input"
                        style={{ borderRadius: "0px" }}
                      />
                    </div>
                  </div>

                  {/* <div className="col-12 mt-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gridCheck" style={{borderRadius:"0px"}} />
                                            <label className="form-check-label label-styling">
                                                Create an account?
                                            </label>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-6">
          <h3 className="billing_heading mb-4">ADDITIONAL INFORMATION</h3>
          <div className="mb-3">
            <label className="form-label label-styling">
              Order notes (optional)
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Notes about your order, e.g"
              style={{ borderRadius: "0px", height: "" }}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;
