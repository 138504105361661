import React from "react";

const OtherJwellery = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "300px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Comming soon...
    </div>
  );
};

export default OtherJwellery;
