import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import { HttpPost } from "../helper/Api";
import { useSelector } from "react-redux";
const ResetPassword = (props) => {
  const allStateData = useSelector((store) => store.mainReducer);
  const [data, setData] = useState({
    mobileNo:allStateData?.MobileNo,
    email:allStateData?.EmailId,
  });
  const handleResetPass = async (e) => {
    e.preventDefault();
    if (data?.newPass !== data?.reNewPass)
      return toast.error(
        "New password and confirmation password is not match."
      );
    const body = {
      MODE: "CHANGE",
      OLD_PASSWORD: data?.oldPass,
      NEW_PASSWORD: data?.newPass,
      MOBILE_NO: data?.mobileNo,
      EMAIL_ID: data?.email,
      PARTY_ID: allStateData?.partyId,
    };
    const res = await HttpPost("GeneratePasswordForWeb", body);
    if (res?.success) {
      setData({});
      props.handleResetClose();
      props?.handleLogout();
      toast.success("Password updated successfull.");
    }
  };
  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <Dialog
      open={props.resetClose}
      onClose={() => props.handleResetClose()}
      style={{ backgroundColor: "rgba(255,255,255,.75)", overflowY: "hidden" }}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ display: "flex", alignItems: "center", flex: "1" }}>
            <Typography variant="h5" component="h2">
              Reset Password
            </Typography>
          </Box>
          <Tooltip title="Esc to close">
            <IconButton
              tabIndex={-1}
              sx={{ borderRadius: "50px" }}
              onClick={() => props.handleResetClose()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <div className="register">
          <form onSubmit={handleResetPass}>
            <div className="register__inputs">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: 26,
                    left: "5px",
                    padding: "0px 5px",
                  }}
                >
                  +91
                </span>
                <input
                  type="number"
                  inputMode="numeric"
                  placeholder="Enter mobile no*"
                  name="mobileNo"
                  maxLength={"10"}
                  minLength={"10"}
                  required
                  style={{ marginTop: 15, paddingLeft: "35px" }}
                  value={data?.mobileNo}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>

              <input
                type="email"
                placeholder="Enter Email address*"
                name="email"
                required
                style={{ marginTop: 15 }}
                value={data?.email}
                onChange={handleChange}
                disabled={true}
              />
              <input
                type="password"
                placeholder="Enter old password*"
                name="oldPass"
                required
                autoFocus={true}
                style={{ marginTop: 15 }}
                value={data?.oldPass}
                onChange={handleChange}
              />
              <input
                type="password"
                placeholder="Enter new password*"
                name="newPass"
                required
                style={{ marginTop: 15 }}
                value={data?.newPass}
                onChange={handleChange}
              />
              <input
                type="password"
                placeholder="Re-enter new password*"
                name="reNewPass"
                required
                style={{ marginTop: 15 }}
                value={data?.reNewPass}
                onChange={handleChange}
              />

              <button
                className="register__btn"
                style={{
                  backgroundColor: "#ffe7e3",
                  border: 0,
                  lineHeight: "2em",
                  margin: "15px 0 0",
                }}
                onSubmit={handleResetPass}
              >
                RESET PASSWORD
              </button>
            </div>
          </form>
        </div>
      </DialogContent>
      {/* <DialogActions sx={{ p: 2 }}>
        <button
          className="register__btn"
          type="submit"
          onClick={(e) => handleResetPass(e)}
        >
          EDIT
        </button>
      </DialogActions> */}
    </Dialog>
  );
};

export default ResetPassword;
