// third-party
import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import { persistStore } from "redux-persist";
import rootReducer from "./Store";
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// project import

// const persistConfig = {
//   key: 'sea-react-trouve-admin',
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, reducers);

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_DEV === "production",
});

const persister = persistStore(store);

const { dispatch } = store;
const { getState } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, dispatch, persister, useSelector, useDispatch, getState };
