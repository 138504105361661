import React from 'react'
import {Link, useLocation, useNavigate} from "react-router-dom"


function Viewcart() {
  const navigate = useNavigate();
  const location = useLocation()

//   const handleAddCartItem = () => {
//     navigate("/Additem");
// }





const addItemsInCart =(itemDetails) => {
  //console.log(itemDetails);
  navigate(`/Add-To-Cart/`, {state: itemDetails})
}
  return (
    <div className='container'>
        <div className='row' style={{border: "2px solid gray" ,height:"120px", width:"1200px",justifyContent:"center",marginLeft:"-50px", marginTop:"100px"}}>
            <div className='col-8' style={{textAlign:"center", marginTop:"40px"}}>
                <p>You cannot add that amount to the cart — we have 1
                     in stock and you already have 1 in your cart.</p>
            </div>
            <div className='col-4'style={{textAlign:"center", marginTop:"40px"}}>
                <button onClick={()=>addItemsInCart(location.state)} style={
                  {
              fontSize:"18px",
              border:"2px solid black",
              backgroundColor:"black",
              height:"60px",
              width:"260px",  
              color:"white"
            }
            }>VIEW CART</button>
            </div>
        </div>
    </div>
  )
}

export default Viewcart