import React, { useEffect, useRef, useState } from "react";
import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Slider,
  Typography,
} from "@material-ui/core";
import RemoveIcon from "@mui/icons-material/Remove";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { getCartItem, selectedFilter } from "../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpPost } from "../helper/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CART_DATA,
  SET_FILTER_DATA,
  SET_FILTER_LIST,
  SET_SELECTED_PRODUCT,
  SET_TOTAL_CART_COUNT,
} from "./Store/ActionTypes";
import { encrypt, extractUserDefineError } from "../helper/utils";
import { color, fontSize } from "@material-ui/system";
import { toast } from "react-hot-toast";
import { Button, Grid } from "@mui/material";
import {
  SET_OPEN_LOGIN,
  SET_SELECTED_PRODUCT_SAVED,
  SET_WISH_LIST,
} from "./Store/Reducer";
import {
  SET_IS_SEARCH_DATA,
  SET_SORT_DATA,
} from "./Store/ShopProductDetailReducer";

function Filter() {
  const allStateData = useSelector((store) => store.mainReducer);
  const { filterData } = useSelector((store) => store.NavBarReducer);
  const { searchData, isSearch, sortData } = useSelector(
    (store) => store.ShopProductDetailReducer
  );

  const [showItem, setShowItem] = useState([]);
  const [sellingPrice, setSellingPrice] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [globalFilterList, setGlobalFilterList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [openArray, setOpenArray] = useState([]);
  // const [lastElement, setLastElement] = useState(null);
  const [selectFilterDesc, setSelectFilterDesc] = useState("");
  const [value, setValue] = React.useState([0, 22900]);
  const [pageNum, setPageNum] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollRef = useRef();
  const [drawer, setDrawer] = useState({
    left: false,
    right: false,
  });
  const [sorting, setSorting] = useState(Array(6).fill(false));

  const windowSize = window.screen.width;

  const toggleDrawer = (anchor, open) => (event) => {
    setDrawer({ ...drawer, [anchor]: open });
  };
  const handleSorting = (index) => {
    const tempArray = Array(6).fill(false);
    tempArray[index] = true;
    setSorting([...tempArray]);
    setDrawer({ ...drawer, right: false });
  };

  const getFilter = async () => {
    if (filterData?.length) {
      setFilterList(filterData);
      dispatch({
        type: SET_FILTER_LIST,
        payload: JSON.stringify(filterData),
      });
      setGlobalFilterList(filterData);
      let groupFilterDatas = filterData?.reduce((result, array) => {
        result[array.FILTER_HEADING] = result[array.FILTER_HEADING] || [];
        result[array.FILTER_HEADING].push(array);
        return result;
      }, Object.create(null));
      setOpenArray(Array(Object.entries(groupFilterDatas).length).fill(false));
      const sellingPriceFilter = filterData?.filter(
        (selling) => selling.FILTER_HEADING === "SELLING PRICE"
      );
      setSellingPrice(...sellingPriceFilter);
    }
  };

  useEffect(() => {
    if (allStateData?.apiKey?.length && filterData?.length) {
      getFilter();
    }
  }, [allStateData?.apiKey?.length, filterData?.length]);
  useEffect(() => {
    if (filterData?.length) {
      if (scrollRef.current.scrollTop === 0) {
        refVal.current = 1;
        setPageNum(refVal.current);
        fetchMoreItems();
      } else {
        refVal.current = 1;
        scrollRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [filterData]);
  const getAllItemsData = async (applyfilters = []) => {
    // setPageNum(1);
    const checkedFilterLists = applyfilters?.length
      ? selectedFilter(applyfilters)
      : selectedFilter(filterData);
    let body = {
      // "CART_ITEM_COUNT": pageNum,
      SESSION_ID: "352",
      PARTY_ID: "12",
      CATALOGUE_ID: "966924",
      PARTY_NAME: "JIGNESH",
      COMPANY_ID: "0",
      LOCATION_ID: "0",
      IMAGE_FOLDER_URL: null,
      LOGIN_NAME: "ADMIN",
      EMPLOYEE_TYPE: "ADMIN",
      SHOW_ONLY_GW_WT: "0",
      MASTER_URL: null,
      SEARCH_MODE: "ITEM",
      SEARCH_FILTER: {
        PAGE_NO: 1,
        FILTER_DATA: checkedFilterLists,
        SORT: sortData?.length ? sortData : "",
        TEXT: isSearch ? (searchData ? searchData : "") : "",
      },
      DISPLAY_MODE: "SCREEN",
    };

    let res = await HttpPost("getItemData", body);
    if (res.success) {
      setShowItem([...res.ItemData]);
      if (res.ItemData?.length) {
        refVal.current = 1;
      }
    }
  };

  const refVal = useRef(0);
  // const observer = useRef(
  //   new IntersectionObserver((entries) => {
  //     const first = entries[0];
  //     if (first.isIntersecting ) {

  //     }
  //   })
  // );

  async function getDatas(catalogueID, page, textSearch) {
    try {
      const checkedFilterLists = selectedFilter(filterData);
      let body = {
        CART_ITEM_COUNT: 25,
        SESSION_ID: "352",
        PARTY_ID: "12",
        CATALOGUE_ID: "966924",
        PARTY_NAME: "JIGNESH",
        COMPANY_ID: "0",
        LOCATION_ID: "0",
        IMAGE_FOLDER_URL: null,
        LOGIN_NAME: "ADMIN",
        EMPLOYEE_TYPE: "ADMIN",
        SHOW_ONLY_GW_WT: "0",
        MASTER_URL: null,
        SEARCH_MODE: "ITEM",
        SEARCH_FILTER: {
          PAGE_NO: refVal.current,
          FILTER_DATA: checkedFilterLists,
          SORT: sortData?.length ? sortData : "",
          TEXT: isSearch ? (searchData ? searchData : "") : "",
        },
        DISPLAY_MODE: "SCREEN",
      };
      const res = await HttpPost("getItemData", body);
      return res;
    } catch (error) {
      extractUserDefineError(error);
    }
  }

  const fetchMoreItems = () => {
    getDatas()
      .then((res) => {
        if (res?.success === 1) {
          if (refVal.current === 1) {
            setShowItem([...res?.ItemData]);
          } else {
            setShowItem([...showItem, ...res?.ItemData]);
          }
        } else {
          extractUserDefineError(res.error);
        }
      })
      .catch((error) => {
        extractUserDefineError(error.message);
      });
  };

  useEffect(() => {
    if (pageNum === 0) {
      refVal.current = 1;
      setPageNum(1);
    } else if (
      pageNum > 1 &&
      pageNum <= Number(showItem?.[0]?.TOTAL_PAGES) &&
      showItem?.length
    ) {
      fetchMoreItems();
    }
  }, [pageNum]);

  // useEffect(() => {
  //   const currentElement = lastElement;
  //   const currentObserver = observer.current;
  //   if (currentElement ) {
  //     currentObserver.observe(currentElement);
  //   }

  //   return () => {
  //     if (currentElement) {
  //       currentObserver.unobserve(currentElement);
  //     }
  //   };
  // }, [lastElement]);

  useEffect(() => {
    if (filterList.length) {
      let groupFilterDatas = filterList?.reduce((result, array) => {
        result[array.FILTER_HEADING] = result[array.FILTER_HEADING] || [];
        result[array.FILTER_HEADING].push(array);
        return result;
      }, Object.create(null));
      setGroupFilter(groupFilterDatas);
      // setOpenArray(Array(Object.entries(groupFilterDatas).length).fill(false))
    }
  }, [filterList]);

  const rangeSelector = (event, newValue) => {
    setValue(newValue);
    const indexSellingFilter = filterList.findIndex(
      (sellFilter) =>
        sellFilter.FILTER_HEADING === "SELLING PRICE" &&
        sellFilter.FILTER_TYPE === "RANGE"
    );
    const newArr = filterData?.map((data) => {
      if (
        data.FILTER_HEADING === "SELLING PRICE" &&
        data.FILTER_TYPE === "RANGE"
      ) {
        return {
          ...data,
          IS_CHECKED: "1",
          INPUT_MIN_VALUE: value[0],
          INPUT_MAX_VALUE: value[1],
        };
      }
      return { ...data, IS_CHECKED: 0 };
    });
    dispatch({ type: SET_IS_SEARCH_DATA, payload: false });
    dispatch({ type: SET_FILTER_DATA, payload: [...newArr] });
    const tempFilter = [...filterList];
    tempFilter[indexSellingFilter].INPUT_MIN_VALUE = value[0];
    tempFilter[indexSellingFilter].INPUT_MAX_VALUE = value[1];
    setFilterList(tempFilter);
    getAllItemsData(tempFilter);
  };

  const handleFilterDesc = (FilterDesc) => {
    const newArr = filterData?.map((data) => {
      if (
        data.FILTER_HEADING === FilterDesc.FILTER_HEADING &&
        data.FILTER_ID === FilterDesc.FILTER_ID
      ) {
        return { ...data, IS_CHECKED: "1" };
      }
      return { ...data, IS_CHECKED: 0 };
    });
    const filterIndex = filterList?.findIndex(
      (filter) =>
        filter.FILTER_DESC === FilterDesc.FILTER_DESC &&
        filter.FILTER_HEADING === FilterDesc.FILTER_HEADING
    );
    const tempFilter = JSON.parse(JSON.stringify(globalFilterList));
    tempFilter[filterIndex].IS_CHECKED =
      tempFilter[filterIndex].IS_CHECKED === "1" ? "0" : "1";
    setFilterList(tempFilter);
    // getAllItemsData(tempFilter);
    setDrawer({ ...drawer, left: false });
    if (windowSize <= 550) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    dispatch({ type: SET_IS_SEARCH_DATA, payload: false });
    dispatch({ type: SET_FILTER_DATA, payload: [...newArr] });
  };

  const handleImageClick = (itemData) => {
    // localStorage.setItem("itemObj", JSON.stringify(itemData));
    // sessionStorage.setItem("itemObj", JSON.stringify(itemData));

    dispatch({
      type: SET_SELECTED_PRODUCT_SAVED,
      payload: itemData,
    });

    // navigate(
    //   `/shop/product/?q=${encrypt({
    //     apiKey: allStateData?.apiKey,
    //     externalProduct: { STOCK_CODE: itemData?.STOCK_CODE },
    //   })}`
    // );
    navigate(
      `/shop/product/`,
      {
        state: {
          externalProduct: { STOCK_CODE: itemData?.STOCK_CODE }
        }
      }
    );
  };

  const handleAddToCartData = async (itemData, cartType = "C") => {
    let addItemObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iMode: "STOCK-LIST-ESTIMATION-B2C",
      iScanText: `${itemData.STOCK_CODE}^INV^5044^5045^153^IDLE-STOCK^^${cartType}`,
    };
    const result = await HttpPost("AddtoCart", addItemObj);
    if (result.success) {
      const res = await getCartItem();
      if (res) {
        dispatch({
          type: SET_CART_DATA,
          payload: res,
        });
      }
      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: result.rec_count,
      });
      toast.success("Added to the cart.");
      navigate(`/Additem/`);

      // let itemDataTemp = {...itemData};
      // itemDataTemp['cartStatus'] = true;
      // navigate(`/cart/`, { state: itemData })
    }
  };

  const handleAddToWishList = async (elem) => {
    if (allStateData.userInfo === "") {
      dispatch({ type: SET_OPEN_LOGIN, payload: true });
      return;
    }
    let body = {
      MODE: "A",
      STOCK_CODE: elem?.STOCK_CODE,
      VARIANT_ID: elem?.VARIANT_ID,
      PARTY_ID: allStateData?.partyId,
    };
    let res = await HttpPost("ManageWishlist", body);
    if (res.success) {
      dispatch({
        type: SET_WISH_LIST,
        payload: [...res?.data],
      });
      toast.success("Added into wishlist.");
    }
  };

  const handleItemClick = (idx) => {
    const tempArray = [...openArray];
    tempArray[idx] = !tempArray[idx];
    setOpenArray([...tempArray]);
  };

  const scrollToTop = () => {
    if (windowSize <= 550) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getFewCategory = (name) => {
    return (
      name === "CATEGORY" ||
      name === "LINE OF BUSINESS" ||
      name === "STYLE KARAT" ||
      name === "STYLE COLLECTION" ||
      name === "STYLE COLOR"
    );
  };
  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (scrollTop === 0 && refVal.current === 1 && showItem?.length !== 25) {
      // console.log(
      //   scrollTop,
      //   "scrollHeight - clientHeight - scrollTop",
      //   scrollHeight - clientHeight - scrollTop
      // );
      // console.log(pageNum);
      // refVal.current = 1;
      setPageNum(refVal.current);
      fetchMoreItems();
      return;
    }
    if (
      Math.abs(scrollHeight - clientHeight - scrollTop) < 1 &&
      showItem?.length
    ) {
      refVal.current = refVal.current + 1;
      setPageNum(refVal.current);
    }
  };
  const isCheckInCart = (cart) => {
    let isCheck = false;
    for (let index = 0; index < allStateData?.cartData?.length; index++) {
      const element = allStateData?.cartData[index];
      if (element?.StockCode === cart?.STOCK_CODE) {
        isCheck = true;
        break;
      }
    }
    return isCheck;
  };
  const isCheckInWishList = (cart) => {
    let isCheck = false;
    if (allStateData?.userInfo === "") return false;
    for (let index = 0; index < allStateData?.wishListData?.length; index++) {
      const element = allStateData?.wishListData[index];
      if (element?.STOCK_CODE === cart?.STOCK_CODE) {
        isCheck = true;
        break;
      }
    }
    return isCheck;
  };

  const deleteCartItem = async (elem) => {
    const obj = allStateData?.cartData?.filter(
      (item) => item?.VariantId === elem?.VARIANT_ID
    )?.[0];
    if (obj) {
      let delCartObj = {
        iGuid: localStorage.getItem("token__key")?.length
          ? localStorage.getItem("token__key")
          : sessionStorage.getItem("token__key"),
        vMode: "DELETE",
        iMode: "ITEM",
        iScanText: "",
        TransItemId: obj?.TransItemId,
        iTransItemBomId: 0,
        iTransItemOperationId: 0,
      };

      let res = await HttpPost("pkg_cell_edit_ending", delCartObj);
      if (res.success) {
        //   const cartIndex = CartItem.findIndex((cart) => cart.TransItemId === transItemId);
        //   setCartItem((prevLists) => ([
        //     ...prevLists?.slice(0, cartIndex),
        //     ...prevLists?.slice(cartIndex + 1)
        //   ]))

        const tempCartData = JSON.parse(JSON.stringify(allStateData.cartData));
        const cartIndex = tempCartData.findIndex(
          (cart) => cart.TransItemId === obj?.TransItemId
        );
        tempCartData.splice(cartIndex, 1);
        dispatch({
          type: SET_CART_DATA,
          payload: tempCartData,
        });
        dispatch({
          type: SET_TOTAL_CART_COUNT,
          payload: allStateData?.cartCount - 1,
        });
        toast.success("Removed from cart.");
      }
    }
  };

  const deleteWishListItem = async (elem) => {
    const obj = allStateData?.wishListData?.filter(
      (item) => item?.STOCK_CODE === elem?.STOCK_CODE
    )?.[0];
    if (obj) {
      let body = {
        MODE: "D",
        PK_ID: obj?.PK_ID,
        PARTY_ID: allStateData?.partyId,
      };
      let res = await HttpPost("ManageWishlist", body);
      if (res.success) {
        dispatch({
          type: SET_WISH_LIST,
          payload: res.data ? [...res.data] : [],
        });
        toast.success("Removed from wishlist.");
      }
    }
  };
  useEffect(() => {
    if (showItem?.length) {
      setShowItem((prev) =>
        prev?.map((item) => ({
          ...item,
          cartStatus: isCheckInCart(item),
          wishListStatus: isCheckInWishList(item),
        }))
      );
    }
  }, [
    allStateData?.wishListData?.length,
    allStateData?.cartData?.length,
    showItem?.length,
  ]);
  const handleChangeSorting = (e) => {
    dispatch({
      type: SET_SORT_DATA,
      payload: e.target.value?.length ? `TRANS_AMOUNT,${e.target.value}` : "",
    });
    const data = filterData.map((nFilter) => {
      return { ...nFilter };
    });
    dispatch({
      type: SET_FILTER_DATA,
      payload: data,
    });
  };
  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <div className="container">
        <div className="row align-items-start mt-3 filter__div">
          <div className="col-4 range">
            <h5
              className="text-start"
              style={{
                fontFamily: "'Tenor Sans', sans-serif",
                fontSize: "18px",
              }}
            >
              FILTER
            </h5>
            <Slider
              className="range-slider"
              size="small"
              value={value}
              onChange={rangeSelector}
              valueLabelDisplay="auto"
              min={sellingPrice?.MIN_VALUE}
              max={sellingPrice?.MAX_VALUE}
            />
            <p className="range-price text-start">
              Price: ₹ {value[0]} - ₹ {value[1]}
            </p>
          </div>
          <div className="col-5 align-self-center">
            {/* <p>SHOWING 1–100 OF 252 RESULTS</p> */}
          </div>
          <div className="col-3 align-self-center">
            <select
              defaultValue
              className="form-select border-0"
              aria-label="Default select example"
              onChange={handleChangeSorting}
            >
              <option value="" className="border-0">
                Default sorting
              </option>
              <option value="asc" className="border-0">
                Sort by price: low to high
              </option>
              <option value="desc" className="border-0">
                Sort by price: high to low
              </option>
            </select>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="container">
          <div className="row align-items-start">
            <div className="col-2 filter-list__div">
              {/* <ul className="list-group text-start">
                                {
                                    groupFilter && Object.entries(groupFilter)?.map(([name, filterValues]) => {
                                        return (
                                            <ul key={name} className="list-group text-start">
                                                <li className="list-group-item border-0 list-filter-head"><h5 style={{fontSize:"18px"}}><a href='#'>{name}</a></h5></li>
                                                {
                                                    filterValues.filter((filter) => filter.FILTER_TYPE === 'LOV').map((filter) => (
                                                        <li key={filter.FILTER_DESC} className="list-group-item border-0 filter-data-list" onClick={() => handleFilterDesc(filter)}>
                                                            <a href='#' style={{
                                                                color: filter?.IS_CHECKED === "1" ? "rgb(175,97,113)" : "gray",
                                                                textDecorationLine: filter?.IS_CHECKED === "1" ? "underline" : "none"
                                                            }}>{filter.FILTER_DESC}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        )
                                    })
                                }

                            </ul> */}
              <List sx={{ width: "100%", maxWidth: 360 }} component="nav">
                {groupFilter &&
                  Object.entries(groupFilter)
                    ?.filter(([name]) => getFewCategory(name))
                    ?.map(([name, filterValues], index) => (
                      <div key={name}>
                        <ListItemButton
                          onClick={() => handleItemClick(index)}
                          sx={{ pl: 0, pr: 0 }}
                        >
                          <ListItemText sx={{ fontFamily: "Tenor Sans" }}>
                            <Typography
                              sx={{
                                fontSize: "16px !important",
                                fontFamily: ["Tenor Sans"],
                              }}
                            >
                              {name}
                            </Typography>
                          </ListItemText>
                          {openArray[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse
                          in={openArray[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {filterValues
                              .filter((filter) => filter.FILTER_TYPE === "LOV")
                              .map((filter) => (
                                <ListItemButton
                                  key={filter.FILTER_DESC}
                                  sx={{ pl: 2 }}
                                  onClick={() => handleFilterDesc(filter)}
                                >
                                  <ListItemText>
                                    <Typography
                                      sx={{
                                        fontSize: "15px !important",
                                        fontFamily: ["Tenor Sans"],
                                        color:
                                          filter.IS_CHECKED === "1"
                                            ? "#aa4762"
                                            : "",
                                      }}
                                    >
                                      {filter.FILTER_DESC}
                                    </Typography>
                                  </ListItemText>
                                </ListItemButton>
                              ))}
                          </List>
                        </Collapse>
                      </div>
                    ))}
              </List>
            </div>

            {/* Filter For Mobile */}
            <div className="mobile__filter">
              <button onClick={toggleDrawer("left", true)}>FILTER</button>
              <Drawer
                anchor={"left"}
                open={drawer["left"]}
                onClose={toggleDrawer("left", false)}
              >
                <div className="mobile__filter__box">
                  <Slider
                    className="mobile__filter__box__range__slider"
                    size="small"
                    value={value}
                    onChange={rangeSelector}
                    valueLabelDisplay="auto"
                    min={sellingPrice?.MIN_VALUE}
                    max={sellingPrice?.MAX_VALUE}
                  />
                  <p className="mobile__filter__box__price">
                    Price: ₹ {value[0]} - ₹ {value[1]}
                  </p>
                  <List sx={{ width: "100%", maxWidth: 360 }} component="nav">
                    {groupFilter &&
                      Object.entries(groupFilter)?.map(
                        ([name, filterValues], index) => (
                          <div key={name}>
                            <ListItemButton
                              onClick={() => handleItemClick(index)}
                              sx={{ pl: 0, pr: 0 }}
                            >
                              <ListItemText sx={{ fontFamily: "Tenor Sans" }}>
                                <Typography
                                  sx={{
                                    fontSize: "16px !important",
                                    fontFamily: ["Tenor Sans"],
                                  }}
                                >
                                  {name}
                                </Typography>
                              </ListItemText>
                              {openArray[index] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItemButton>
                            <Collapse
                              in={openArray[index]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {filterValues
                                  .filter(
                                    (filter) => filter.FILTER_TYPE === "LOV"
                                  )
                                  .map((filter) => (
                                    <ListItemButton
                                      key={filter.FILTER_DESC}
                                      sx={{ pl: 2 }}
                                      onClick={() => handleFilterDesc(filter)}
                                    >
                                      <ListItemText>
                                        <Typography
                                          sx={{
                                            fontSize: "15px !important",
                                            fontFamily: ["Tenor Sans"],
                                            color:
                                              filter.IS_CHECKED === "1"
                                                ? "#aa4762"
                                                : "",
                                          }}
                                        >
                                          {filter.FILTER_DESC}
                                        </Typography>
                                      </ListItemText>
                                    </ListItemButton>
                                  ))}
                              </List>
                            </Collapse>
                          </div>
                        )
                      )}
                  </List>
                </div>
              </Drawer>
              <button onClick={toggleDrawer("right", true)}>SORT</button>
              <Drawer
                anchor={"right"}
                open={drawer["right"]}
                onClose={toggleDrawer("right", false)}
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  className="mobile__filter__box"
                  style={{ padding: "20px 0" }}
                >
                  <p style={{ margin: "0 0 8px 16px" }}>SORT</p>
                  <List sx={{ width: "100%", maxWidth: 360 }}>
                    <ListItemButton
                      style={{ backgroundColor: sorting[4] ? "#e3dcdc" : "" }}
                      onClick={() => handleSorting(4)}
                    >
                      <ListItemText primary="Sort by price: low to high" />
                    </ListItemButton>
                    <ListItemButton
                      style={{ backgroundColor: sorting[5] ? "#e3dcdc" : "" }}
                      onClick={() => handleSorting(5)}
                    >
                      <ListItemText primary="Sort by price: high to low" />
                    </ListItemButton>
                  </List>
                </div>
              </Drawer>
            </div>

            <div
              ref={scrollRef}
              className="col-10 mobile__filter__img__div"
              id="image-box"
              style={{ padding: 0 }}
              onScroll={handleScroll}
            >
              <div className="filter__card__div">
                {showItem?.length ? (
                  showItem.map((item, index) => {
                    return index === showItem.length - 1 ? (
                      <div key={index + 1} id="multi">
                        {item.CATEGORY === selectFilterDesc ||
                          selectFilterDesc === "" ? (
                          <>
                            <img
                              src={item?.IMAGE_NAME}
                              className="card-img-top"
                              alt="..."
                              onClick={() => handleImageClick(item)}
                            />
                            <p className="price_desc">{item?.CATEGORY}</p>
                            <p className="price_desc2">
                              ₹ {item?.TRANS_AMOUNT}
                            </p>
                            <div
                              className="cart-btn"
                              style={{ alignItems: "center", display: "flex" }}
                            >
                              {item?.cartStatus ? (
                                <Button
                                  className="add-btn btn-1"
                                  onClick={() => deleteCartItem(item)}
                                  size="small"
                                  sx={{
                                    borderRadius: "0px",
                                    border: "1px solid rgb(175, 174, 174)",
                                    m: "0px",
                                    mr: "10px",
                                    minWidth: "0px !important",
                                    background: "#FFFFFF",
                                    svg: {
                                      height: "100%",
                                      width: "100%",
                                      path: {
                                        fill: "black",
                                      },
                                    },
                                    ":hover": {
                                      background: "#111111",
                                      svg: {
                                        path: {
                                          fill: "#FFFF",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <RemoveIcon />
                                </Button>
                              ) : (
                                <button
                                  className="add-btn btn-1"
                                  onClick={() => handleAddToCartData(item)}
                                >
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                              {item?.wishListStatus ? (
                                <Button
                                  className="add-btn btn-1"
                                  onClick={() => deleteWishListItem(item)}
                                  size="small"
                                  sx={{
                                    borderRadius: "0px",
                                    border: "1px solid rgb(175, 174, 174)",
                                    m: "0px",
                                    mr: "10px",
                                    minWidth: "0px !important",
                                    background: "#FFFFFF",
                                    svg: {
                                      height: "18px",
                                      width: "18px",
                                      path: {
                                        fill: "black",
                                      },
                                    },
                                    ":hover": {
                                      background: "#111111",
                                      svg: {
                                        height: "18px",
                                        width: "18px",
                                        path: {
                                          fill: "#FFFF",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <HeartBrokenIcon size="small" />
                                </Button>
                              ) : (
                                <button
                                  className="add-btn btn-2"
                                  onClick={() => handleAddToWishList(item)}
                                >
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div id="multi" key={index + 1}>
                        {/* <img src={item?.IMAGE_NAME} className="card-img-top" alt="..." onClick={() => navigate(`/shop/product/`, {
                          state: {
                            item: item,
                            externalProduct: { STOCK_CODE: item?.STOCK_CODE }
                          }
                        })} /> */}
                        <img
                          src={item?.IMAGE_NAME}
                          className="card-img-top"
                          alt="..."
                          onClick={() => handleImageClick(item)}
                        />
                        <p className="price_desc">{item?.CATEGORY}</p>
                        <p className="price_desc2">₹ {item?.TRANS_AMOUNT}</p>
                        <div
                          className="cart-btn"
                          style={{ alignItems: "center", display: "flex" }}
                        >
                          {item?.cartStatus ? (
                            <Button
                              className="add-btn btn-1"
                              onClick={() => deleteCartItem(item)}
                              size="small"
                              sx={{
                                borderRadius: "0px",
                                border: "1px solid rgb(175, 174, 174)",
                                m: "0px",
                                mr: "10px",
                                minWidth: "0px !important",
                                background: "#FFFFFF",
                                svg: {
                                  height: "100%",
                                  width: "100%",
                                  path: {
                                    fill: "black",
                                  },
                                },
                                ":hover": {
                                  background: "#111111",
                                  svg: {
                                    path: {
                                      fill: "#FFFF",
                                    },
                                  },
                                },
                              }}
                            >
                              <RemoveIcon />
                            </Button>
                          ) : (
                            <button
                              className="add-btn btn-1"
                              onClick={() => handleAddToCartData(item)}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          )}
                          {item?.wishListStatus ? (
                            <Button
                              className="add-btn btn-1"
                              onClick={() => deleteWishListItem(item)}
                              size="small"
                              sx={{
                                borderRadius: "0px",
                                border: "1px solid rgb(175, 174, 174)",
                                m: "0px",
                                mr: "10px",
                                minWidth: "0px !important",
                                background: "#FFFFFF",
                                svg: {
                                  height: "18px",
                                  width: "18px",
                                  path: {
                                    fill: "black",
                                  },
                                },
                                ":hover": {
                                  background: "#111111",
                                  svg: {
                                    height: "18px",
                                    width: "18px",
                                    path: {
                                      fill: "#FFFF",
                                    },
                                  },
                                },
                              }}
                            >
                              <HeartBrokenIcon size="small" />
                            </Button>
                          ) : (
                            <button
                              className="add-btn btn-2"
                              onClick={() => handleAddToWishList(item)}
                            >
                              <i className="fa fa-heart" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>No data</>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className='row'>
                    <div className='col'>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end">
                                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item"><a className="page-link" href="#">Next</a></li>
                            </ul>
                        </nav>
                    </div>
                </div> */}
      </div>
      <IconButton onClick={scrollToTop}>
        <ArrowCircleUpIcon
          sx={{
            position: "fixed",
            right: "30px",
            bottom: "80px",
            color: "#fd86d8",
            fontSize: 50,
          }}
        />
      </IconButton>
    </div>
  );
}

export default Filter;
