import React from 'react'
import './DiamondGuide.css'
import diamondguide1 from '../../image/diamond-guide1.jpg'
import diamondguide2 from '../../image/diamond-guide2.jpg'
import diamondguide3 from '../../image/diamond-guide3.jpg'
import diamondguide4 from '../../image/diamond-guide4.jpg'
import diamondguide5 from '../../image/diamond-guide5.png'
import diamondguide6 from '../../image/diamond-guide6.png'
import diamondguide7 from '../../image/diamond-guide7.png'
import diamondguide8 from '../../image/diamond-guide8.png'
import diamondguide9 from '../../image/diamond-guide9.png'
import diamondguide10 from '../../image/diamond-guide10.png'
import diamondguide11 from '../../image/diamond-guide11.png'
import diamondguide12 from '../../image/diamond-guide12.png'
import diamondguide13 from '../../image/diamond-guide13.png'
import diamondguide14 from '../../image/diamond-guide14.jpg'

const DiamondGuide = () => {
  return (
    <div className='diamond-guide'>
        <div className='diamond-guide__header'>
            <h2>Diamond Guide</h2>
            <p>Here we’ll help you with the basics of a diamond, so that before you buy you are sure of the authenticity.</p>
        </div>
        <div className='diamond-guide__desc'>
            <div className='diamond-guide__desc__header'>
                <h2>4 C’s Of A Diamond</h2>
                <p>The value of a diamond is determined by the four parameters, which are popularly known as the “4Cs of a Diamond”. Though there are numerous other factors like fluorescence, which impacts its beauty and brilliance.</p>
            </div>
            <div className='diamond-guide__desc__hrline'></div>
            <div className='diamond-guide__desc__content'>
                <div className='diamond-guide__desc__content__item'>
                    <h2>CUT</h2>
                    <img src={diamondguide1} alt=""/>
                    <p>The cut is determined by how a diamond’s facets interact with light. The cut is the only one of the four Cs impacted by human hands. In particular, Diamond Cut refers to the consistency of the angles, dimensions, symmetric facets, brilliance, fire, scintillation, and finishing features of a diamond. These variables directly affect the capacity of a diamond to sparkle, along with its overall aesthetic appeal.</p>
                </div>
                <div className='diamond-guide__desc__content__item'>
                    <h2>Color</h2>
                    <img src={diamondguide2} alt=""/>
                    <p>The term refers to the absence of color in most diamonds. The less color the stone contains, the more it is desirable and valuable. Some of these variations are not apparent to the naked eye but have a direct impact on the overall quality and price of the stone. Most white diamonds in nature have a slight tint of yellow. The closer to being “colorless” a diamond is, the rarer it is. A diamond should look white to the naked eye or colorless. There are also fancy colored diamonds with a hue of pink, orange, red, blue etc.</p>
                </div>
                <div className='diamond-guide__desc__content__item'>
                    <h2>Clarity</h2>
                    <img src={diamondguide3} alt=""/>
                    <p>Diamond clarity is the term used to measure naturally occurring ‘inclusions’ or tiny blemishes seen inside a diamond at a 10 X magnification. All the diamonds are measured on a diamond visibility scale that ranges from ‘Flawless’ to ‘Included’. Remember, they’re all unique diamonds, not perfect. Through immense pressure and heat, the diamonds are rendered underground, natural inclusions and blemishes are inevitable in the diamond.</p>
                </div>
                <div className='diamond-guide__desc__content__item'>
                    <h2>Carat</h2>
                    <img src={diamondguide4} alt=""/>
                    <p>Carat is a term that is quite confusing for the people. It refers to the weight of a diamond, not its size. Various diamond shapes such as round brilliant, princess, pear, oval, cushion, marquise, emerald, radiant or heart result in variation in carat weight. Your perfect carat depends on how large you want the stone to be, and your budget.</p>
                </div>
            </div>
        </div>
        <div className='diamond-guide__shapes'>
            <h2>Diamond Shapes</h2>
            <div className='diamond-guide__shapes__items'> 
                <div>
                    <img src={diamondguide5} alt=""/>
                    <p>CUSHION</p>
                </div>
                <div>
                    <img src={diamondguide6} alt=""/>
                    <p>PEAR</p>
                </div>
                <div>
                    <img src={diamondguide7} alt=""/>
                    <p>PRINCESS</p>
                </div>
                <div>
                    <img src={diamondguide8} alt=""/>
                    <p>ROUND</p>
                </div>
                <div>
                    <img src={diamondguide9} alt=""/>
                    <p>EMRALD</p>
                </div>
                <div>
                    <img src={diamondguide10} alt=""/>
                    <p>HEART</p>
                </div>
                <div>
                    <img src={diamondguide11} alt=""/>
                    <p>OVAL</p>
                </div>
                <div>
                    <img src={diamondguide12} alt=""/>
                    <p>BAGUETTE</p>
                </div>
                <div>
                    <img src={diamondguide13} alt=""/>
                    <p>MARQUISE</p>
                </div>
            </div>
        </div>
        <div className='diamond-guide__certification'>
            <h2>Certification</h2>
            <p>Each purchase from Baijnath comes with a bonafide certification from us, which is an assurance of cut, color & clarity. The most trusted diamond grading laboratories are Gemological Institute of America (GIA), HRD, IGI.</p>
            <img src={diamondguide14} alt=""/>
        </div>
    </div>
  )
}

export default DiamondGuide