//Login Constants
export const SET_LOGIN = "SET_LOGIN";
export const SET_DATEFORMAT = "SET_DATEFORMAT";
// export const SET_FILTERDATA = 'SET_FILTERDATA';

// For Cart
export const SET_CART_DATA = "SET_CART_DATA";
export const SET_TOTAL_CART_COUNT = "SET_TOTAL_CART_COUNT";
export const SET_GET_CART_DATA = "SET_GET_CART_DATA";
export const SET_USER_INFO = "SET_USER_INFO";

// Shop PRODUCT Details page
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";

// Navbar List Info
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const SET_FILTER_LIST = "SET_FILTER_LIST";
export const CLEAR_ALL_FILTER = "CLEAR_ALL_FILTER";
