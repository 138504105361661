import React, { useEffect, useState } from "react";
import roseta1 from "../image/rosetta1.jpg";
import bangleRose from "../image/bangle-ros2.jpg";
import rosebangles from "../image/rosbangle.jpg";
import roseNecklace from "../image/rosetta-necklace.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import rosettaRing from "../image/rosetta-ring.jpg";
import roseEaring from "../image/ros-earing.jpg";
import raginiRing from "../image/ragini-ring.jpg";
import raginiBrace from "../image/ragini-brace.jpg";
import raginiBand from "../image/ragini-band.jpg";
import raginiEarring from "../image/ragini-earring.jpg";
import raginiNecklace from "../image/ragini-necklace.jpg";
import raginiMultiBangle from "../image/ragini-multi-bangle.jpg";
import anantRing from "../image/anant-ring2.jpg";
import anantEaring from "../image/anant-earring.jpg";
import anantEaring2 from "../image/anant-earrin2.jpg";
import anantBraclet from "../image/anant-braclet.jpg";
import anantRing1 from "../image/anant-ring.jpg";
import anantNeclace from "../image/anant-necklace.jpg";
import modenaEaring4 from "../image/modena-earring-4.jpg";
import modenaEaring1 from "../image/modena-earring-1.jpg";
import modenaEaring2 from "../image/modena-earring-2.jpg";
import modenaEaring3 from "../image/modena-earring-3.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_FILTER_DATA } from "./Store/ActionTypes";
import { HttpPost } from "../helper/Api";
import { selectedFilter } from "../utils/utils";
import { extractUserDefineError } from "../helper/utils";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Collections() {
  const { filterData } = useSelector((store) => store.NavBarReducer);
  const { searchData, isSearch, sortData } = useSelector(
    (store) => store.ShopProductDetailReducer
  );
  const [imgData1, setImgData1] = useState([]);
  const [imgData2, setImgData2] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const openShopPage = () => {
    const data = filterData?.map((filterdata) => ({
      ...filterdata,
      IS_CHECKED: "0",
    }));
    dispatch({ type: SET_FILTER_DATA, payload: [...data] });
    navigate(`/shop/`);
  };
  useEffect(() => {
    getImageData1();
    getImageData2();
  }, []);

  const getImageData1 = async () => {
    try {
      const checkedFilterLists = selectedFilter(filterData);
      let body = {
        CART_ITEM_COUNT: 25,
        SESSION_ID: "352",
        PARTY_ID: "12",
        CATALOGUE_ID: "966924",
        PARTY_NAME: "JIGNESH",
        COMPANY_ID: "0",
        LOCATION_ID: "0",
        IMAGE_FOLDER_URL: null,
        LOGIN_NAME: "ADMIN",
        EMPLOYEE_TYPE: "ADMIN",
        SHOW_ONLY_GW_WT: "0",
        MASTER_URL: null,
        SEARCH_MODE: "ITEM",
        SEARCH_FILTER: {
          PAGE_NO: 1,
          FILTER_DATA: checkedFilterLists,
          SORT: sortData?.length ? sortData : "",
          TEXT: isSearch ? (searchData ? searchData : "") : "",
        },
        DISPLAY_MODE: "SCREEN",
      };
      const res = await HttpPost("getItemData", body);
      if (res?.success === 1) {
        setImgData1([...res?.ItemData]);
      }
    } catch (error) {
      extractUserDefineError(error);
    }
  };
  const getImageData2 = async () => {
    try {
      const checkedFilterLists = selectedFilter(filterData);
      let body = {
        CART_ITEM_COUNT: 25,
        SESSION_ID: "352",
        PARTY_ID: "12",
        CATALOGUE_ID: "966924",
        PARTY_NAME: "JIGNESH",
        COMPANY_ID: "0",
        LOCATION_ID: "0",
        IMAGE_FOLDER_URL: null,
        LOGIN_NAME: "ADMIN",
        EMPLOYEE_TYPE: "ADMIN",
        SHOW_ONLY_GW_WT: "0",
        MASTER_URL: null,
        SEARCH_MODE: "ITEM",
        SEARCH_FILTER: {
          PAGE_NO: 2,
          FILTER_DATA: checkedFilterLists,
          SORT: sortData?.length ? sortData : "",
          TEXT: isSearch ? (searchData ? searchData : "") : "",
        },
        DISPLAY_MODE: "SCREEN",
      };
      const res = await HttpPost("getItemData", body);
      if (res?.success === 1) {
        setImgData2([...res?.ItemData]);
      }
    } catch (error) {
      extractUserDefineError(error);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg rosetta-col"></div>
        <div
          className="col-lg"
          style={{
            backgroundColor: "rgb(255,249,248)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="rosetta-text-box">
            <h2 className="rosetta-heading">ROSETTA</h2>
            <p className="rosetta-para">
              A unique collection of fine Italian jewellery, the warmth of rich
              gold with a touch of the delicate.
            </p>
            {/* <a href="#"><button className="shop-btn" onClick={() =>  openShopPage()}>SHOP ALL</button></a> */}
          </div>
        </div>
      </div>
      {/* <div className='container' style={{marginTop: '80px'}}>
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row'>
                                <img src={roseta1} className="d-block w-25" alt="..." />
                                <img src={bangleRose} className="d-block w-25" alt="..." />
                                <img src={rosebangles} className="d-block w-25" alt="..." />
                                <img src={roseNecklace} className="d-block w-25" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <img src={rosettaRing} className="d-block w-25" alt="..." />
                                <img src={roseEaring} className="d-block w-25" alt="..." />
                                <img src={roseta1} className="d-block w-25" alt="..." />
                                <img src={bangleRose} className="d-block w-25" alt="..." />
                            </div>
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <button className='ros-shp-btn'onClick={() =>  openShopPage()}>
                    SHOP ALL
                </button>
            </div> */}

      <div className="carouselConatiner">
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
        >
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={roseta1} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={bangleRose} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={rosebangles} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={roseNecklace} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={rosettaRing} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={roseEaring} width="100%" alt="..." />
          </div>
        </Carousel>
        <button className="ros-shp-btn" onClick={() => openShopPage()}>
          SHOP ALL
        </button>
      </div>

      <div className="row pt-5">
        <div className="col-lg ragini-col"></div>
        <div
          className="col-lg"
          style={{
            backgroundColor: "rgb(255,249,248)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="rosetta-text-box">
            <h2 className="rosetta-heading">RAGINI</h2>
            <p className="rosetta-para">
              Delicate, 22 KT gold jewellery in classic, timeless designs.
              Ragini - Verses in Gold.
            </p>
            <a href="#">
              <button className="shop-btn" onClick={() => openShopPage()}>
                SHOP ALL
              </button>
            </a>
          </div>
        </div>
      </div>
      {/* <div className='container' style={{marginTop: '80px'}}>
                <div id="carouselExampleControls__1" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row'>
                                <img src={raginiRing} className="d-block w-25" alt="..." />
                                <img src={raginiBrace} className="d-block w-25" alt="..." />
                                <img src={raginiBand} className="d-block w-25" alt="..." />
                                <img src={raginiEarring} className="d-block w-25" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <img src={raginiNecklace} className="d-block w-25" alt="..." />
                                <img src={raginiMultiBangle} className="d-block w-25" alt="..." />
                                <img src={raginiRing} className="d-block w-25" alt="..." />
                                <img src={raginiBrace} className="d-block w-25" alt="..." />
                            </div>
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls__1" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls__1" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <button className='ros-shp-btn' onClick={() =>  openShopPage()}>
                    SHOP ALL
                </button>
            </div> */}

      <div className="carouselConatiner">
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
        >
        <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={raginiRing} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={raginiBrace} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={raginiBand} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={raginiEarring} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={raginiNecklace} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={raginiMultiBangle} width="100%" alt="..." />
          </div>
        </Carousel>
        <button className="ros-shp-btn" onClick={() => openShopPage()}>
          SHOP ALL
        </button>
      </div>

      <div className="row pt-5">
        <div className="col-lg anant-col"></div>
        <div
          className="col-lg"
          style={{
            backgroundColor: "rgb(255,249,248)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="rosetta-text-box">
            <h2 className="rosetta-heading">ANANT</h2>
            <p className="rosetta-para">
              An endless selection of gold, diamond & gemstone jewellery <br />
              that will leave you spoilt for choice
            </p>
            <button className="shop-btn" onClick={() => openShopPage()}>
              SHOP ALL
            </button>
          </div>
        </div>
      </div>
      {/* <div className='container' style={{marginTop: '80px'}}>
                <div id="carouselExampleControls__2" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row'>
                                <img src={anantRing} className="d-block w-25" alt="..." />
                                <img src={anantEaring} className="d-block w-25" alt="..." />
                                <img src={anantEaring2} className="d-block w-25" alt="..." />
                                <img src={anantBraclet} className="d-block w-25" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row'>
                                <img src={anantRing1} className="d-block w-25" alt="..." />
                                <img src={anantNeclace} className="d-block w-25" alt="..." />
                                <img src={anantRing} className="d-block w-25" alt="..." />
                                <img src={anantEaring} className="d-block w-25" alt="..." />
                            </div>
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls__2" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls__2" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <button className='ros-shp-btn' onClick={() =>  openShopPage()}>
                    SHOP ALL
                </button>
            </div> */}

      <div className="carouselConatiner">
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
        >
       <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={anantRing} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={anantEaring} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={anantEaring2} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={anantBraclet} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={anantRing1} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={anantNeclace} width="100%" alt="..." />
          </div>
        </Carousel>
        <button className="ros-shp-btn" onClick={() => openShopPage()}>
          SHOP ALL
        </button>
      </div>

      <div className="row pt-5">
        <div className="col-lg modena-col"></div>
        <div
          className="col-lg"
          style={{
            backgroundColor: "rgb(255,249,248)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="rosetta-text-box">
            <h2 className="rosetta-heading">MODENA</h2>
            <p className="rosetta-para">
              A colourful array of sparkling gems and delicate design,
              <br />
              Modena is a vivid palette for the bold.
            </p>

            <a href="#">
              <button className="shop-btn" onClick={() => openShopPage()}>
                SHOP ALL
              </button>
            </a>
          </div>
        </div>
      </div>
      {/* <div className='container mb-5'>
                <div id="carouselExampleControls__3" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className='row' style={{ marginTop: "80px" }}>
                                <img src={modenaEaring4} className="d-block w-25" alt="..." />
                                <img src={modenaEaring1} className="d-block w-25" alt="..." />
                                <img src={modenaEaring2} className="d-block w-25" alt="..." />
                                <img src={modenaEaring3} className="d-block w-25" alt="..." />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className='row' style={{ marginTop: "80px" }}>
                                <img src={modenaEaring4} className="d-block w-25" alt="..." />
                                <img src={modenaEaring1} className="d-block w-25" alt="..." />
                                <img src={modenaEaring2} className="d-block w-25" alt="..." />
                                <img src={modenaEaring3} className="d-block w-25" alt="..." />
                            </div>
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls__3" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls__3" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <button className='ros-shp-btn' onClick={() =>  openShopPage()}>
                    SHOP ALL
                </button>
            </div> */}

      <div className="carouselConatiner" style={{ marginBottom: "80px" }}>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
        >
         <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={modenaEaring4} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={modenaEaring1} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={modenaEaring2} width="100%" alt="..." />
          </div>
          <div style={{ width: "100%", padding: "0 12px" }}>
            <img src={modenaEaring3} width="100%" alt="..." />
          </div>
        </Carousel>
        <button className="ros-shp-btn" onClick={() => openShopPage()}>
          SHOP ALL
        </button>
      </div>
    </div>
  );
}

export default Collections;
