import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

export function encrypt(text) {
  var b64 = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    process.env.REACT_APP_CRYPTO_KEY
  ).toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
}
// write a function using crypto js to decrypt object
export function decrypt() {
  try {
    let text = window.location.search?.split("=")[1];
    if (!text) return "";
    var reb64 = CryptoJS.enc.Hex.parse(text);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, process.env.REACT_APP_CRYPTO_KEY);
    var plain = JSON?.parse(decrypt?.toString(CryptoJS.enc.Utf8))
      ? JSON?.parse(decrypt?.toString(CryptoJS.enc.Utf8))
      : "";
    return plain;
  } catch (e) {
    if (
      e.message === "Illegal character" ||
      e.message === "Unexpected end of JSON input"
    ) {
      toast("Invalid token");
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
  }
}
export function getDateFormatString() {
  var locale = window.navigator.userLanguage || window.navigator.language;
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "YYYY";
        default:
          return obj.value;
      }
    })
    .join("");
}

export const extractUserDefineError = (errorMessage) => {
  if (errorMessage?.includes("#")) {
    return errorMessage.match(/\#{2}(.*)\#{2}/).pop();
  } else {
    return errorMessage;
  }
};

export const splicingArrayInOneRow = (arrayList = [], WhitchNos = 0) => {
  let count = 0;
  let objectData = {};
  while (arrayList.length) {
    if (arrayList.length < WhitchNos) {
      const splicedArr = arrayList.splice(0, arrayList.length);
      objectData = { ...objectData, [count]: splicedArr };
    } else {
      const splicedArr = arrayList.splice(0, WhitchNos);
      objectData = { ...objectData, [count]: splicedArr };
    }
    count = count + 1;
  }
  return objectData;
};
