import React from 'react'
import './ShippingRefundPolicy.css'

const ShippingRefundPolicy = () => {
  return (
    <div className='shippingRefundPolicy'>
        <h2>SHIPPING POLICY</h2>
        <h3>Shipping Policy</h3>
        <ol>
            <li>Baijnath Jewellers provides free delivery/shipping on all items within India.</li>
            <li>The delivery date mentioned on the website is an approximate date based on major cities and primary locations.</li>
            <li>We make sure that the parcels are properly boxed packed and sealed. If during delivery the parcel is damaged by the courier partner, we request you to not accept the damaged or tampered shipment.</li>
        </ol>
        <h3>Delivery Location</h3>
        <ol>
            <li>We currently ship only within India.</li>
            <li>If the recipient is unavailable to receive the order, the courier partner will try and make additionally 2 more attempts to deliver the product in the working hours before returning it back to the origin.</li>
        </ol>
        <h3>Tracking Of Shipment <br />
We will email you a tracking number once your product has been dispatched. You can check the status and track your order on our website.</h3>
        <h2>Lifetime Returns of Baijnath Jewellers Products:</h2>
        <h3>Stringent quality control practices are in place to ensure that the product reaches you safely. You may however return or exchange the product in any of the following scenarios:</h3>
        <ol>
            <li>In case of a defect in the product</li>
            <li>If you received a wrong order</li>
            <li>If the product does not fit your size</li>
        </ol>
        <h2><span style={{color: '#000', fontWeight: '700'}}>Please note, products for returns shall only be accepted if unworn, and in its original packaging with the boxes.</span> Return process will only be initiated if the Quality Control team at Baijnath Jewellers team deems it fit. Each return will be judged on the following parameters:</h2>
        <ol>
            <li>The product should not have been broken altered, mishandled, heated, treated chemically, or otherwise not found in good/ tradable condition.</li>
            <li>Gross and net weight of the product should match with original invoice.</li>
            <li>Check for wear and tear & damage to the product</li>
        </ol>
        <h2>Return Policy for Baijnath jewellers Diamond Jewellery:</h2>
        <ol>
            <li>100% of the sale/billed value (including GST) will be refunded on requests received within 14 days from the receipt of the product by the customer.</li>
            <li>For return of the products after 14 days from the receipt of the product by the customer, 30% would be deducted from the sale/billed value. No taxes such as GST etc levied on the Invoice shall be refunded back to the customer in such scenario.</li>
            <li>Refund shall be processed within 14 working days from the date of intimation by Baijnath Jewellers.</li>
            <li>Refund/Return shall only be granted after due examination of product by the quality control team and shall be issued by way of. “ account payee “ cheque/NEFT/ RTGS/ credited to the payment source ( credit card/ Debit card/ Net Banking ) or any other suitable mode of bank transfer. Refund shall be issued only in favour of person whose name was mentioned on the invoice at the time of sale.</li>
        </ol>
        <h2>Guarantee:</h2>
        <h2>*All Baijnath Jewellers products are engraved as a mark of security and authenticity</h2>
    </div>
  )
}

export default ShippingRefundPolicy