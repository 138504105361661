export const SET_ERROR_MODAL_OPEN = "SET_ERROR_MODAL_OPEN";
export const SET_ERROR_MODAL_CLOSE = "SET_ERROR_MODAL_CLOSE";

const initialState = {
  isOpen: false,
  message: "",
  details: "",
  isShowMoreEnabled: false,
};

const errorModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_MODAL_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        message: action.payload?.message?.length ? action.payload?.message : "",
        details: action.payload.details,
        isShowMoreEnabled:
          action.payload.message?.length && action.payload.details?.length
            ? true
            : false,
      };
    case SET_ERROR_MODAL_CLOSE:
      return initialState;

    default:
      return state;
  }
};

export default errorModalReducer;
