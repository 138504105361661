import React from 'react'
import './TermsConditions.css'

const TermsConditions = () => {
  return (
    <div className='termscondition'>
      <h1>TERMS & CONDITIONS</h1>
      <div className='termscondition__content'>
        <h2>GENERAL INFORMATION</h2>
        <ol>
          <li>Please see that any transaction done at our store is fully vouched and supported by proper invoice/receipt etc. </li>
          <li>Please understand thoroughly the transactions you have made and check the invoice before signing. Ensure that you have been fully explained by the attending staff and you have understood all the information regarding the transaction you have done. </li>
          <li>Please check the goods and verify the weight, purity and hallmarking before taking delivery. We shall not be responsible once the goods move out of the store.</li>
          <li>The amount before tax includes the wastage/ making charge/ value of stone etc. on the jewellery.</li>
          <li>Please ensure that the bill accompanies the jewellery for all future transactions. We can only entertain transactions with the customer on whose name the bill has been issued.</li>
          <li>The customer will provide the PAN card as and when needed.</li>
          <li>The purchase rate for gold will be less than sale rate of gold by around Rs 700/- per 10 gms  and the purchase rate of silver will be less than sale rate of silver by around Rs 1000/- per kg. This may vary depending upon the market conditions.</li>
          <li>Payments/refunds for purchases shall be made only by way of account payee cheque/ online transfer within 7-10 working days.</li>
          <li>In case of any dispute, courts in Kanpur only will have the jurisdiction</li>
        </ol>
      </div>
      <div className='termscondition__content'>
        <h2>GOLD JEWELLERY EXCHANGE/ RETURN POLICY</h2>
        <h2>We shall be happy to accept the jewellery sold from our store as per prevailing terms and conditions:</h2>
        <ol>
          <li>The original invoice copy should be produced at the time of exchange/ return.</li>
          <li>If the customer exchanges their gold jewellery for new jewellery, value for net gold weight will be given at the gold rate applicable on date of exchange. 750 hallmark jewellery will be valued at 18kt gold purchase rate and 916 hallmark jewellery will be valued at 22kt gold purchase rate.</li>
          <li>If customer sells and seeks refund for gold jewellery, we will make payment of 90% of exchange value determined as above in point no. 2.</li>
          <li>The net gold wt of the product is calculated after considering the following:</li>
          <li>a) The weight of gold will be net of all pearls, stones, lac, dirt, mina and any other extraneous material.</li>
          <li>b) Melting loss @ 1% shall be deducted over and above the net gold weight of the jewellery</li>
          <li>The gold weight will be valued on the basis of the company’s gold purchase rate on the date of exchange/ return.</li>
        </ol>
      </div>
      <div className='termscondition__content'>
        <h2>DIAMOND JEWELLERY EXCHANGE/ RETURN POLICY</h2>
        <ol>
          <li>The original invoice copy and the certificate of authenticity should be produced at the time of exchange/ return.</li>
          <li>The physical weight of the jewellery will be verified and matched with the original invoice and certificate issued.</li>
          <li>In case of exchange we offer full exchange value on gold and diamond at the prevailing rates on that date. The value of color stone is subject to deduction depending upon the wear and tear of the stone.</li>
          <li>In case customer seeks the refund than an additional 10% shall be deducted on the exchange value of the diamond and gold, calculated on the basis of prevailing  rates on that date.</li>
          <li>Making charges, taxes and any other charges will be deducted in case of both exchange and return.</li>
          <li>In case of platinum jewellery full value of net weight of platinum will be given on the basis of its purity at the prevailing purchase rates.</li>
        </ol>
      </div>
      <div className='termscondition__content'>
        <h2>FULL BUYBACK OF DIAMOND JEWELLERY</h2>
        <h2>We offer full buy back for diamond jewellery within 7 days from the date of sale provided the jewellery has not been used, tampered, altered at the time of sale and no cracks observed or broken alongwith the original invoice and certificate of authenticity.</h2>
        <ol>
          <li>a) The bank charges on account of credit card usage or any other charges/ tax applicable shall be   deducted from the net value of original sale invoice.</li>
          <li>b) In case of studded jewellery returned against order/ altered at the time of sale, in addition to above, the making charges will be deducted.</li>
        </ol>
      </div>
      <div className='termscondition__content'>
        <h2>SILVER JEWELLERY AND UTENSILS EXCHANGE / RETURN POLICY</h2>
        <ol>
          <li>The original invoice copy should be produced at the time of exchange/ return.</li>
          <li>In case of exchange with new jewellery a deduction of 20% will be made from the net weight of the jewellery.</li>
          <li>In case customer seeks refund a deduction of 30% will be made from the net weight of jewellery.</li>
          <li>In case of silver utensils/gift items 10-15% deduction will be made on exchange and 15-20% deduction will be made on refund. Certain products may not have exchange / resale value.</li>
          <li>The silver weight will be valued on the basis of the company’s silver purchase rate on the date of exchange/ return.</li>
        </ol>
      </div>
      <div className='termscondition__content'>
        <h2>GENERAL EXCHANGE PROGRAME</h2>
        <h2>The exchange of all other jewellery other than sold by Baijnath Jewellers shall wholly depend upon the value and appraisal done at our store at our discretion depending upon the purity assessed by us.</h2>
        <h2>Baijnath Jewellers reserves the right to withdraw or modify these terms and conditions without prior notice. Anything not mentioned herein will be at the sole discretion of the company.</h2>
      </div>
      <div className='termscondition__content'>
        <h2>SHIPPING POLICY</h2>
        <p>Shipping Policy</p>
        <ol>
          <li>Baijnath Jewellers provides free delivery/shipping on all items within India.</li>
          <li>The delivery date mentioned on the website is an approximate date based on major cities and primary locations.</li>
          <li>We make sure that the parcels are properly boxed packed and sealed. If during delivery the parcel is damaged by the courier partner, we request you to not accept the damaged or tampered shipment.</li>
        </ol>
        <p>Delivery Location</p>
        <ol>
          <li>We currently ship only within India.</li>
          <li>If the recipient is unavailable to receive the order, courier partner will try and make additionally 2 more attempts to deliver the product in the working hours before returning it back to the origin.</li>
        </ol>
        <p>Tracking Of Shipment</p>
        <h2>We will email you tracking number once your product has been dispatched. You can check the status and track your order on our website.</h2>
      </div>
    </div>
  )
}

export default TermsConditions