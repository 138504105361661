import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HttpPost } from "../helper/Api";
import { encrypt, extractUserDefineError } from "../helper/utils";
import { SET_LOGIN, SET_USER_INFO } from "./Store/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import Register from "./Register";
import LoginWithOtpDialog from "../Component/LoginWithOtpDialog";
import ForgetPassword from "./ForgetPassword";
import { v4 as UIDV4 } from "uuid";
import { SET_EMAIL_ID, SET_IS_REGISTER, SET_MO_NO, SET_PARTY_ID, SET_WISH_LIST } from "./Store/Reducer";
const Login = (props) => {
  const allStateData = useSelector((store) => store.mainReducer);
  const { isOpen } = useSelector((store) => store.errorModalReducer);
  const [cred, setCred] = useState({});
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [getOtpOpen, setGetOtpOpen] = useState(false);
  const [isSuccessMobile, setIsSuccessMobile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [isRegisterPage, setRegisterPage] = useState(false);
  const [isForgetPassword, setForgetPassword] = useState(false);
  const [input, setInput] = useState({
    mobileNumber: "",
    otp: "",
    username: "",
    email: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log(cred);

  const handleChange = (e) => {
    setCred({ ...cred, [e.target.name]: [e.target.value] });
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleGetOtpOpen = () => {
    setGetOtpOpen(true);
  };

  const handleGetOtpClose = () => {
    setGetOtpOpen(false);
  };
  const validateB2CSession = async () => {
    let dataObject = {
      iConfigId: process.env.iConfigId,
      iTransCategoryLinkId: process.env.iTransCategoryLinkId,
      iMenuID: process.env.iMenuID,
      iWcId: process.env.iWcId,
      iWcGroupId: process.env.iWcGroupId,
      iTransType: process.env.iTransType,
    };
    let response = await HttpPost("ValidateSessionB2C", dataObject);
    if (response.success) {
      //console.log(response.success);
      props?.setLoginData(response);
      // console.log(response);
      dispatch({
        type: SET_PARTY_ID,
        payload: response?.Id,
      });
      dispatch({
        type: SET_USER_INFO,
        userInfo: response.Info === "GUEST" ? "" : response.Info,
      });
      dispatch({
        type: SET_MO_NO,
        payload: response.MobileNo,
      });
      dispatch({
        type: SET_EMAIL_ID,
        payload: response.EmailId,
      });
      // dispatch({
      //   type: SET_WISH_LIST,
      //   payload: [],
      // });
      localStorage.setItem("userName", response.Info);
      sessionStorage.setItem("userName", response.Info);
    }
  };

  const handleLogin = async () => {
    dispatch({
      type: SET_LOGIN,
      apiKey: "",
    });
    dispatch({
      type: SET_USER_INFO,
      userInfo: "",
    });
    let dataObj = {
      SessionId: 0,
      CallMode: "LOV",
      QuerySettingId: 1576,
      QuerySettingValues:
        "VALIDATE-CREDENTIAL-FOR-CUSTOMER:GUEST:GUEST:::dd/mm/yyyy:",
      fetchType: "DATA",
      requiredSocket: 0,
      isLogin: 2,
      pageNo: 1,
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      iGuid: UIDV4(),
    };

    // console.log("click", dataObj);
    let result = await HttpPost("searchLov", dataObj);
    if (result.success) {
      dispatch({
        type: SET_LOGIN,
        apiKey: result.data,
      });
      if (
        !localStorage.getItem("token__key")?.length ||
        !sessionStorage.getItem("token__key")?.length
      ) {
        localStorage.setItem("token__key", result.data);
        sessionStorage.setItem("token__key", result.data);
      }
      // if (
      //   !localStorage.getItem("userName")?.length ||
      //   !sessionStorage.getItem("userName")?.length
      // ) {
      //   localStorage.setItem("userName", result.Info);
      //   sessionStorage.setItem("userName", result.Info);
      // }
      if (result.data !== null) {
        // setSearchParams({ q: encrypt({ apiKey: result.data }) });
        // validateB2CSession();
        navigate(
          `/`
        );
        // navigate(
        //   `/?q=${encrypt({
        //     apiKey: result.data,
        //   })}`
        // );
      }
    }
  };

  useEffect(() => {
    let isloginCheck = true;
    if (
      isloginCheck &&
      (sessionStorage.getItem("token__key") === null ||
        localStorage.getItem("token__key") === null)
    ) {
      handleLogin();
    } else if (
      allStateData.apiKey?.length !== 0 &&
      isloginCheck &&
      sessionStorage.getItem("token__key") !== null
    ) {
      validateB2CSession();
    }

    return () => {
      isloginCheck = false;
    };
  }, [allStateData.apiKey]);

  const submitAPiCall = async () => {
    searchParams.delete("q");
    setLoading(true);
    let dataObj = {
      SessionId: 0,
      CallMode: "LOV",
      QuerySettingId: 1576,
      QuerySettingValues: `VALIDATE-CREDENTIAL-FOR-CUSTOMER:${
        cred?.username[0] ? cred?.username[0] : ""
      }:${cred?.password[0] ? cred?.password[0] : ""}:::dd/mm/yyyy:`,
      fetchType: "DATA",
      requiredSocket: 0,
      isLogin: 3,
      pageNo: 1,
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      iGuid: UIDV4(),
    };
    let result = await HttpPost("searchLov", dataObj);
    if (result.success) {
      dispatch({
        type: SET_LOGIN,
        apiKey: result.data,
      });
      dispatch({
        type: SET_USER_INFO,
        userInfo: result.Info,
      });
      // if (result.data !== null) {
      //   setSearchParams({ q: encrypt({ apiKey: result.data }) });
      //   // validateB2CSession();
      // }
      localStorage.setItem("userName", result.Info);
      localStorage.setItem("token__key", result.data);
      localStorage.setItem("isLoggedIn", true);
      sessionStorage.setItem("token__key", result.data);
      // navigate(`/shop/?q=${encrypt({ apiKey: result.data })}`, { replace: true });
      props.handleClose();
    } else {
      setLoading(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submitAPiCall();
  };

  const handleGetOTP = async () => {
    if ("MOBILE_NO"?.length > 0) {
      setIsSuccessMobile(true);
      let getOtpBody = {
        MOBILE_NO: "8976268799",
      };
      let res = await HttpPost("ValidateB2CLogin", getOtpBody);
      if (res.success) {
        // console.log(res.success);
        // setIsSuccessMobile(false);
        console.log(res);
      } else {
        setIsSuccessMobile(false);
      }
    }
  };

  const handleValidateOtp = async () => {
    if ("MOBILE_NO" && "OTP") {
      setLoading(true);
      let validateOtpBody = {
        MOBILE_NO: "8976268799",
        OTP: "1234",
      };
      let res = await HttpPost("ValidateOTP", validateOtpBody);
      if (res.success) {
        setTimeout(() => {
          setLoading(false);
          navigate(`/shop`);
          // navigate(`/shop/?q=${encrypt({ apiKey: res.data })}`);
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (allStateData?.isRegister) {
      setRegisterPage(true);
    }
  }, [allStateData?.isRegister]);
  const handleCloseRegister = () => {
    dispatch({ type: SET_IS_REGISTER, payload: false });
    setRegisterPage(false);
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose()}
        disableEnforceFocus={isOpen}
        style={{
          backgroundColor: "rgba(255,255,255,.75)",
          overflowY: "hidden",
        }}
        // fullWidth
        maxWidth={isRegisterPage ? "xl" : "sm"}
      >
        <DialogContent>
          {isRegisterPage ? (
            <Register closeRegister={() => handleCloseRegister()} />
          ) : isForgetPassword ? (
            <ForgetPassword
              closeForgetPassword={() => setForgetPassword(false)}
            />
          ) : (
            <section className="login__page">
              {isLoading}
              <div className="container__fluid">
                <div className="mt-3">
                  <p
                    className="text-center"
                    style={{
                      fontFamily: "'Montserrat', sans-serif",
                      fontWeight: 100,
                      color: "gray",
                      fontSize: "16px",
                      marginBottom: 0,
                    }}
                  >
                    Already have an account?
                  </p>
                  <h3
                    className="text-center"
                    style={{
                      fontFamily: "'Tenor Sans', sans-serif",
                      fontSize: "32px",
                      marginBottom: 32,
                    }}
                  >
                    Log In
                  </h3>
                </div>
                <div className="row">
                  <div className="login__left__content">
                    <div className="login__content">
                      <div className="login__details">
                        <div className="login__form">
                          <form className="form-group">
                            <div className="user">
                              <input
                                type={"text"}
                                name="username"
                                placeholder="Email/Mobile"
                                className="form__control"
                                onChange={handleChange}
                                style={{
                                  borderRadius: "0px",
                                  padding: "15px 23px",
                                  fontFamily: ["Cormorant"],
                                  fontStyle: "italic",
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  marginBottom: 10,
                                  outline: "none",
                                }}
                              />
                            </div>
                            <div className="user">
                              <input
                                type={"password"}
                                placeholder="Password*"
                                name="password"
                                className="form__control"
                                onChange={handleChange}
                                style={{
                                  borderRadius: "0px",
                                  padding: "15px 23px",
                                  fontFamily: ["Cormorant"],
                                  fontStyle: "italic",
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  marginBottom: 10,
                                  outline: "none",
                                }}
                              />
                            </div>
                            <div className=" d-flex login_div login">
                              <button
                                className="loggin__button log_in-btn"
                                onClick={(event) => onSubmit(event)}
                                style={{ width: "150px", height: "50px" }}
                              >
                                LOG IN
                              </button>
                              <input
                                type="checkbox"
                                style={{ height: "15px" }}
                              />
                              <p
                                style={{ marginLeft: "-25px", marginBottom: 0 }}
                              >
                                Remember me
                              </p>
                            </div>
                            {/* <div className="forget">
                            <a className="forget__password" onClick={handleClickOpen}>
                              Forget Passord ?
                            </a>
                            <a
                              className="forget__password"
                              onClick={handleGetOtpOpen}
                            >
                              Login With OTP
                            </a>
                          </div> */}
                            <div className="d-block">
                              <p
                                className="forgot_passoword"
                                onClick={() => setForgetPassword(true)}
                              >
                                Lost your password??
                              </p>
                              <p className="title">Don’t have an account?</p>
                              <button
                                type="button"
                                className="create_acc_btn"
                                onClick={() => setRegisterPage(true)}
                              >
                                CREATE AN ACCOUNT
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoginWithOtpDialog
                messageText={messageText}
                isSuccessMobile={isSuccessMobile}
                handleInputChange={handleInputChange}
                handleValidateOtp={handleValidateOtp}
                open={getOtpOpen}
                handleClose={handleGetOtpClose}
                handleGetOTP={handleGetOTP}
              />
            </section>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Login;
