import axios from "axios";
import { decrypt } from "./utils";
import toast from "react-hot-toast";

import { SET_ERROR_MODAL_OPEN } from "../Component/Store/errorModalReducer";
import { store } from "../Component/Store";
const customAxios = axios.create({
  baseURL:
    process.env.REACT_APP_STAGE === "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL,
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  // let token = decrypt() ? decrypt() : "";
  request.headers.Authorization = localStorage.getItem("token__key")?.length
    ? localStorage.getItem("token__key")
    : sessionStorage.getItem("token__key");
  request.headers["Content-Type"] = "application/json;charset=utf-8";
  // request.headers["x-socket-id"] = socket.id;
  request.headers.identifier = process.env.REACT_APP_CLIENT_NAME;
  request.headers.clientcode = process.env.REACT_APP_CLIENT_CODE;
  return request;
};

const responseHandler = (response) => {
  if (response.data.success === 0 && response.data.error) {
    let errorToShow = response.data.error;

    let checkIfDoubleHashExists = response.data.error?.includes("##");
    if (checkIfDoubleHashExists) {
      let errorMessage = response.data.error.split("##")[1];
      if (!/CONFIRMHOLD/i.test(errorMessage)) {
        store.dispatch({
          type: SET_ERROR_MODAL_OPEN,
          payload: {
            isOpen: true,
            message: errorMessage,
            details: errorToShow,
          },
        });
      }
    } else {
      if (!/CONFIRMHOLD/i.test(errorToShow)) {
        store.dispatch({
          type: SET_ERROR_MODAL_OPEN,
          payload: {
            isOpen: true,
            details: errorToShow,
          },
        });
      }
    }
    // toast.error(errorToShow);
  }
  return response;
};

const errorHandler = (error) => {
  // if (error?.response?.status === 401) {
  // toast.error("Session Expired");
  // setTimeout(() => {
  // window.location.href = "/";
  // }, 3500);
  // }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
