import React, { useEffect, useRef, useState } from "react";
import Login from "./Login";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import brandName from "../image/cropped-baijnath-logo-021.png";
import { ReactComponent as SearchBar } from "../image/icons/searchbar.svg";
import Ellipsehead from "../image/icons/Ellipsehead.png";

//     "SessionId": 0,
//     "CallMode": "LOV",
//     "QuerySettingId": 1000286,
//     "QuerySettingValues": "::5001:::::::::53762",
//     "fetchType": "DATA",
//     "requiredSocket": 0,
//     "isLogin": 3,
//     "pageNo": 1,
//     "iConfigId": "5053",
//     "iTransCategoryLinkId": "5053",
//     "iMenuID": "1000148",
//     "iWcId": 0,
//     "iWcGroupId": 0,
//     "iTransType": "EST",
//     "iGuid": "5388b279-91f2-4cc9-a352-ed3f738e598b"
// }

// PROFILE:52392:DhammakirtySuryawanshi:0:0:dhammakirty@synergicssystems.com:8484913492:Add1:::::'01/01/2020':'01/01/2020'
import { Typography } from "@material-ui/core";
import { FavoriteBorderOutlined, EditOutlined } from "@material-ui/icons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { decrypt, encrypt } from "../helper/utils";
import { HttpPost } from "../helper/Api";
import PasswordIcon from "@mui/icons-material/Password";
import {
  SET_CART_DATA,
  SET_FILTER_DATA,
  SET_LOGIN,
  SET_TOTAL_CART_COUNT,
  SET_USER_INFO,
} from "./Store/ActionTypes";
import HeaderCartData from "./HeaderCartData";
import EditProfile from "./EditProfile";
import { toast } from "react-hot-toast";
import {
  SET_EMAIL_ID,
  SET_MO_NO,
  SET_OPEN_LOGIN,
  SET_WISH_LIST,
} from "./Store/Reducer";
import {
  SET_IS_SEARCH_DATA,
  SET_SEARCH_DATA,
} from "./Store/ShopProductDetailReducer";
import ResetPassword from "./ResetPassword";
import MetalRatePopup from "./HeaderMetalRatePopup";
import Account from "./TermsCondition/Account";
// import { Alert } from "react-bootstrap";

const Header = () => {
  const allStateData = useSelector((store) => store.mainReducer);
  const { filterData } = useSelector((store) => store.NavBarReducer);
  const [open, setOpen] = React.useState(false);
  const [userInfoBox, setUserInfoBox] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const myRef = useRef();
  const [loginData, setLoginData] = useState({});
  const [data, setData] = useState({
    name: "",
    email: "",
    number: "",
    birth_date: "",
    anniversary_date: "",
    address: "",
  });
  const [editClose, setEditClose] = useState(false);
  const [resetClose, setResetClose] = useState(false);
  const [metalRateLists, setMetalRateLists] = useState([]);
  const [accountClose, setAccountClose] = useState(false);
  const handleClickOutside = (e) => {
    if (!myRef.current?.contains(e.target)) {
      setUserInfoBox(false);
    }
  };
  const handleClickAccount = () => {
    setAccountClose(true);
  };
  const handleGetCurrentMetalRate = async () => {
    let result = await HttpPost("getGoldRateForWeb");
    if (result?.success) {
      setMetalRateLists(result?.data || []);
    } else {
      toast.error("Metal Rate" + result?.error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    let isPageActive = true;

    if (isPageActive && allStateData?.apiKey) {
      handleGetCurrentMetalRate();
    }

    return () => {
      isPageActive = false;
    };
  }, [allStateData?.apiKey]);

  const windowSize = window.screen.width;
  const divStyle = {
    width: windowSize < 550 ? "100%" : "",
    justifyContent: windowSize < 550 ? "space-between" : "",
  };

  const handleClickOpen = () => {
    allStateData.userInfo ? setUserInfoBox(!userInfoBox) : setOpen(true);
  };

  useEffect(() => {
    if (allStateData?.openLogin) {
      setOpen(allStateData?.openLogin);
    }
  }, [allStateData?.openLogin]);

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: SET_OPEN_LOGIN, payload: false });
  };

  const handleEditClose = () => {
    setEditClose(false);
  };
  const dateConvert = (d) => {
    return new Date(d)?.toLocaleDateString("en-GB")?.replace(/\//g, "-");
  };
  // const checkValidityFields =()=>{

  // }
  const handleEditButtonClick = async (e) => {
    e.preventDefault();
    const name = data?.firstName + " " + data?.lastName;
    if (name.length === 0 || data?.email?.length === 0)
      return toast.error("Cannot save with empty email or name");
    let obj = {
      SessionId: 0,
      CallMode: "LOV",
      QuerySettingId: 2487,
      QuerySettingValues: `PROFILE:${allStateData?.partyId}:${name}:0:0:${
        data?.email
      }:${data?.number}:${data?.["Address1"] ? data?.["Address1"] : ""}:${
        data?.["addresss2"] ? data?.["addresss2"] : ""
      }::${data?.["cityId"] ? data?.["cityId"] : ""}:${
        data?.["Pincode"] ? data?.["Pincode"] : ""
      }:${data?.birth_date ? dateConvert(data?.birth_date) : ""}:${
        data?.anniversary_date ? dateConvert(data?.anniversary_date) : ""
      }:${data?.["copyBillingAdd"] ? data?.["copyBillingAdd"] : ""}:${
        data?.["ShipAddress1"] ? data?.["ShipAddress1"] : ""
      }:${data?.["Shipaddresss2"] ? data?.["Shipaddresss2"] : ""}:${
        data?.["ShipPincode"] ? data?.["ShipPincode"] : ""
      }:${data?.["ShipcityId"] ? data?.["ShipcityId"] : ""}:${
        data?.["Shiptaluka"] ? data?.["Shiptaluka"] : ""
      }:${data?.["panNo"] ? data?.["panNo"] : ""}:${
        data?.["gstNo"] ? data?.["gstNo"] : ""
      }`,
      fetchType: "DATA",
      requiredSocket: 0,
      pageNo: 1,
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      iGuid: "5388b279-91f2-4cc9-a352-ed3f738e598b",
    };
    const res = await HttpPost("searchLov", obj);
    if (res.success) {
      // console.log(res?.data);
      setEditClose(false);
      setData({});
      return toast.success("Data updated successfully saved.");
    }
  };

  const fetchData = async () => {
    let obj = {
      SessionId: 0,
      CallMode: "LOV",
      QuerySettingId: 1000286,
      QuerySettingValues: `::5001:::::::::${loginData?.Id}`,
      fetchType: "DATA",
      requiredSocket: 0,
      pageNo: 1,
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      iGuid: "5388b279-91f2-4cc9-a352-ed3f738e598b",
    };
    const res = await HttpPost("searchLov", obj);
    if (res.success) {
      setData({
        ...data,
        name: res?.data?.Response[0]?.PARTY_NAME,
        firstName: res?.data?.Response[0]?.FIRST_NAME,
        lastName: res?.data?.Response[0]?.LAST_NAME,
        email: res?.data?.Response[0]?.EMAIL_ID,
        number: res?.data?.Response[0]?.MOBILE_NO,
        birth_date: res?.data?.Response[0]?.BIRTH_DATE
          ? res?.data?.Response[0]?.BIRTH_DATE
          : "",
        anniversary_date: res?.data?.Response[0]?.ANNIVERSARY_DATE
          ? res?.data?.Response[0]?.ANNIVERSARY_DATE
          : "",
        Address1: res?.data?.Response[0]?.BILLING_ADD_1
          ? res?.data?.Response[0]?.BILLING_ADD_1
          : "",
        addresss2: res?.data?.Response[0]?.BILLING_ADD_2
          ? res?.data?.Response[0]?.BILLING_ADD_2
          : "",
        Pincode: res?.data?.Response[0]?.BILLING_PINCODE
          ? res?.data?.Response[0]?.BILLING_PINCODE
          : "",
        billingAddId: res?.data?.Response[0]?.BILLING_ADDRESS_ID
          ? res?.data?.Response[0]?.BILLING_ADDRESS_ID
          : "",
        gstNo: res?.data?.Response[0]?.GST_NO
          ? res?.data?.Response[0]?.GST_NO
          : "",
        panNo: res?.data?.Response[0]?.PAN_NO
          ? res?.data?.Response[0]?.PAN_NO
          : "",
        taluka: res?.data?.Response[0]?.BILLING_TALUKA
          ? res?.data?.Response[0]?.BILLING_TALUKA
          : "",
        state: res?.data?.Response[0]?.BILLING_STATE
          ? res?.data?.Response[0]?.BILLING_STATE
          : "",
        stateId: res?.data?.Response[0]?.BILLING_STATE_ID
          ? res?.data?.Response[0]?.BILLING_STATE_ID
          : "",
        cityId: res?.data?.Response[0]?.BILLING_CITY_ID
          ? res?.data?.Response[0]?.BILLING_CITY_ID
          : "",
        city: res?.data?.Response[0]?.BILLING_CITY
          ? res?.data?.Response[0]?.BILLING_CITY
          : "",

        ShipAddress1: res?.data?.Response[0]?.SHIPPING_ADD_1
          ? res?.data?.Response[0]?.SHIPPING_ADD_1
          : "",
        Shipaddresss2: res?.data?.Response[0]?.SHIPPING_ADD_2
          ? res?.data?.Response[0]?.SHIPPING_ADD_2
          : "",
        ShipPincode: res?.data?.Response[0]?.SHIPPING_PINCODE
          ? res?.data?.Response[0]?.SHIPPING_PINCODE
          : "",
        ShippingAddId: res?.data?.Response[0]?.SHIPPING_ADDRESS_ID
          ? res?.data?.Response[0]?.SHIPPING_ADDRESS_ID
          : "",
        ShipgstNo: res?.data?.Response[0]?.SHIP_GST_NO
          ? res?.data?.Response[0]?.SHIP_GST_NO
          : "",
        ShippanNo: res?.data?.Response[0]?.SHIP_PAN_NO
          ? res?.data?.Response[0]?.SHIP_PAN_NO
          : "",
        Shiptaluka: res?.data?.Response[0]?.SHIPPING_TALUKA
          ? res?.data?.Response[0]?.SHIPPING_TALUKA
          : "",
        Shipstate: res?.data?.Response[0]?.SHIPPING_STATE
          ? res?.data?.Response[0]?.SHIPPING_STATE
          : "",
        ShipstateId: res?.data?.Response[0]?.SHIPPING_STATE_ID
          ? res?.data?.Response[0]?.SHIPPING_STATE_ID
          : "",
        ShipcityId: res?.data?.Response[0]?.SHIPPING_CITY_ID
          ? res?.data?.Response[0]?.SHIPPING_CITY_ID
          : "",
        Shipcity: res?.data?.Response[0]?.SHIPPING_CITY
          ? res?.data?.Response[0]?.SHIPPING_CITY
          : "",
      });
      return true;
    }
    return false;
  };

  const handleClickEditOpen = async () => {
    let checkData = await fetchData();
    if (checkData) {
      setEditClose(true);
    }
  };

  const getCartItem = async (itemData, cartType = "CART") => {
    // setSearchParams({ q: encrypt({ itemData }) });
    let cartObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      TransItemId: 0,
      vMode: `${cartType}`,
    };
    let res = await HttpPost("GetCartData", cartObj);
    if (res.success) {
      dispatch({
        type: SET_CART_DATA,
        payload: res.ITEM,
      });
      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: res.ITEM.length,
      });
    }
  };

  const getWishListItem = async () => {
    let cartObj = {
      MODE: "GET",
      PARTY_ID: allStateData?.partyId,
    };
    let res = await HttpPost("ManageWishlist", cartObj);
    if (res.success) {
      dispatch({
        type: SET_WISH_LIST,
        payload: res.data ? [...res.data] : [],
      });
    }
  };

  useEffect(() => {
    if (allStateData?.partyId !== 0) {
      getWishListItem();
      getCartItem();
    }
  }, [allStateData?.partyId]);

  const openCartPage = (item) => {
    navigate(`/Additem/`);
  };

  const handleLogout = () => {
    dispatch({
      type: SET_USER_INFO,
      userInfo: "",
    });
    dispatch({
      type: SET_LOGIN,
      apiKey: "",
    });
    dispatch({
      type: SET_MO_NO,
      payload: "",
    });
    dispatch({
      type: SET_EMAIL_ID,
      payload: "",
    });
    localStorage.removeItem("userName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token__key");
    sessionStorage.removeItem("token__key");
    setUserInfoBox(false);
    navigate(`/`);
    // navigate(`/?q=${encrypt(decrypt())}`);
  };

  const navigateToAnotherPage = (navName) => {
    // navbarRef.current.className = "navbar-collapse collapse";
    navigate(navName + "/" + '');
  };

  const handleChangeOnkey = (e) => {
    if (e.key === "Enter") {
      dispatch({ type: SET_IS_SEARCH_DATA, payload: true });

      dispatch({ type: SET_SEARCH_DATA, payload: e.target.value });

      const data = filterData.map((nFilter) => {
        return { ...nFilter, IS_CHECKED: "0" };
      });
      dispatch({
        type: SET_FILTER_DATA,
        payload: data,
      });
      navigate("shop" + "/" + '');
    }
  };

  const handleResetClose = () => {
    setResetClose((prev) => !prev);
  };

  return (
    <>
      <Login
        open={open}
        handleClose={handleClose}
        setLoginData={setLoginData}
      />
      <div
        className="header-info"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          backgroundImage: `url(${Ellipsehead})`,
          backgroundSize: "100% 100%",
          padding: "34px !important",
        }}
      >
        {windowSize > 550 && (
          <>
            <div className="navbar__search">
              <SearchBar />
              <input
                type="text"
                name="search"
                placeholder="Search..."
                autoComplete="off"
                onKeyDown={handleChangeOnkey}
                // ref={refSearch}
              />
            </div>
            <img
              // onClick={() => navigateToAnotherPage("")}
              src={brandName}
              className="d-block  nav-logo"
              alt="..."
              style={{
                maxHeight: "30px ",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/`);
                window.scroll({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
          </>
        )}
        <div
          className="header-info__right"
          style={{ ...divStyle, gap: "8px", alignItems: "center" }}
        >
          <MetalRatePopup lstMetalRateLists={metalRateLists} />
          <Typography
            sx={{
              fontSize: 14,
              margin: "0 8px",
              cursor: "pointer",
              textDecoration: "underline",
              fontStyle: "normal",
            }}
            onClick={() => {
              navigateToAnotherPage("about");
            }}
          >
            About us
          </Typography>

          {allStateData?.userInfo !== "" ? (
            <Typography
              sx={{
                fontSize: 12,
                margin: "0 8px",
                position: "relative",
                "&:before": {
                  content: `"${
                    allStateData?.wishListData?.length
                      ? allStateData?.wishListData?.length
                      : 0
                  }"`,
                  display: "block",
                  position: "absolute",
                  top: -10,
                  right: "-40%",
                  width: 20,
                  height: 20,
                  bgcolor: "#FFFFFF",
                  color: "#111111",
                  borderRadius: "50%",
                  fontSize: "10px",
                  // transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                  p: 0.5,
                },
              }}
              // onClick={openCartPage}
              className="header-info__right__wishlst"
            >
              <FavoriteBorderOutlined />
              {/* {allStateData?.wishListData?.length} */}
            </Typography>
          ) : null}
          <div className="header-info__right__wishListData">
            <HeaderCartData mode={"wishlst"} />
          </div>
          {windowSize > 550 && (
            <>
              <Typography
                sx={{
                  fontSize: 12,
                  margin: "0 8px",
                  position: "relative",
                  "&:before": {
                    content: `"${
                      allStateData?.cartData?.length
                        ? allStateData?.cartData?.length
                        : 0
                    }"`,
                    display: "block",
                    position: "absolute",
                    top: -10,
                    right: "-40%",
                    width: 20,
                    height: 20,
                    bgcolor: "#FFFFFF",
                    color: "#111111",
                    borderRadius: "50%",
                    fontSize: "10px",
                    // transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    p: 0.5,
                  },
                }}
                onClick={openCartPage}
                className="header-info__right__shpCart"
              >
                <ShoppingBagOutlinedIcon sx={{ color: "#FFFFFF" }} />
                {/* {allStateData?.cartCount} */}
              </Typography>
              <div className="header-info__right__cartData">
                <HeaderCartData mode={"cart"} />
              </div>
            </>
          )}
          <div className="user-login">
            <Typography
              style={{ fontSize: 12, margin: "0 8px" }}
              onClick={handleClickOpen}
            >
              <PersonOutlineOutlinedIcon sx={{ color: "#FFFFFF" }} />
              {allStateData.userInfo ? allStateData.userInfo : "Log In"}
            </Typography>
          </div>
          {userInfoBox && (
            <>
              <div
                ref={myRef}
                className="user-login__info"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  style={{ fontSize: 14, margin: "8px", color: "#000000" }}
                  onClick={handleClickAccount}
                >
                  <AccountCircleIcon
                    sx={{
                      fontSize: 18,
                      marginLeft: "5px",
                      textAlign: "left",
                      color: "#000000",
                    }}
                  />{" "}
                  Account
                </Typography>
                <Typography
                  style={{ fontSize: 14, margin: "8px", color: "#000000" }}
                  onClick={handleResetClose}
                >
                  <PasswordIcon
                    sx={{
                      fontSize: 16,
                      marginLeft: "5px",
                      textAlign: "left",
                      color: "#000000",
                    }}
                  />{" "}
                  Reset password
                </Typography>
                <Typography
                  style={{ fontSize: 14, margin: "8px", color: "#000000" }}
                  onClick={handleClickEditOpen}
                >
                  <EditOutlined
                    sx={{
                      fontSize: 16,
                      marginLeft: "5px",
                      textAlign: "left",
                      color: "#000000",
                    }}
                  />{" "}
                  Edit Profile
                </Typography>
                <Typography
                  style={{ fontSize: 14, margin: "8px 8px", color: "#000000" }}
                  onClick={() => handleLogout()}
                >
                  <LogoutRoundedIcon
                    sx={{
                      fontSize: 16,
                      marginLeft: "5px",
                      textAlign: "left",
                      color: "#000000",
                    }}
                  />{" "}
                  Logout
                </Typography>
              </div>
            </>
          )}
        </div>
      </div>

      {editClose ? (
        <EditProfile
          editClose={editClose}
          handleEditClose={handleEditClose}
          data={data}
          setData={setData}
          handleEditButtonClick={handleEditButtonClick}
        />
      ) : null}
      {resetClose ? (
        <ResetPassword
          resetClose={resetClose}
          handleResetClose={handleResetClose}
          handleLogout={handleLogout}
        />
      ) : null}

      {accountClose ? (
        <Account
          accountClose={accountClose}
          setAccountClose={setAccountClose}
          handleLogout={handleLogout}
        />
      ) : null}
    </>
  );
};

export default Header;
