import React from 'react';
import aboutImg from '../image/about_img.jpg';
import ringLotus from '../image/ring-lotus.png';
import ringUnity from '../image/ring-unity.png';

function About() {
  return (
    <div>
         <div>
           <div className="abt-img-container">
            <img src={aboutImg} className="img-fluid" alt="..."/>
           </div> 
        </div>
            <div className="container" style={{marginTop:"80px", width: "100%"}}>
                <div className="col-lg">
                    <h2 className="inclusive-text" style={{fontSize:"32px",textAlign:"center" }}>An Inclusive Shopping Experience</h2>
                    <p className="inclusive-para" style={{fontSize:"19x",textAlign:"center"}}>As a fifth generation family business in jewellery, 
                    we value relationships. We are committed to giving you 
                    the best experience in jewellery retail with our decades of expertise 
                    but also evolving with the trends & knowledge. 
                    We believe in putting your choice first & adding 
                    a personal touch to every purchase. making it satisfying & memorable.</p>
                </div>
            </div>
            <hr className="first-hr-line" style={{marginTop: '80px'}}/>
            <div className="container">
            <div className="row" style={{marginTop:"30px"}}>
              <div className="col-lg">
                <img src={ringLotus} className="img-fluid" alt="..."/>
              </div>
              <div className="col-lg guiding-div">
                  <h2 className="heading-guide text-start mt-5">Purity - Our Guiding Principle</h2>
                  <p className="guiding-para">As with gold we believe in value lies in purity & transparency and building a consumer centric brand. 
                      We meet all standards & certifications for our precious metals & gems to create transparent systems for each purchase. 
                      Our efforts lies in keeping your case, convenience $ trust at the forefront.</p>
              </div>
            </div>
            </div>

            <hr className="first-hr-line"/>

            <div className="container" style={{paddingBottom:"70px"}}>
                <div className="row" style={{marginTop:"30px"}}>
                  <div className="col-lg">
                    <img src={ringUnity} className="img-fluid" alt="..."/>
                  </div>
                  <div className="col-lg guiding-div">
                      <h2 className="heading-guide-2 text-start mt-5">Building A Community, Responsibly</h2>
                      <p className="guiding-para-2">We believe in growing everyday, & we measure this in our capacity to give back. 
                          By ensuring responsible sourcing of material, following ethical & equitable practices,
                           educating our craftsmen & store personnel in industry knowledge & customer needs we work closely & consciously.
                           We do our best to transform profit into progress & meaning towards our community.</p>
                  </div>
                </div>
                </div>

        </div>
    
  )
}

export default About