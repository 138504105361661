import { useRoutes } from "react-router-dom";
import Cart from "../Component/Cart";
// import Login from "../Component/Login";
import Filter from "../Component/Filter";
import Home from "../Component/Home";
import React from "react";
import MainPage from "../Component/MainPage";
import About from "../Component/About";
import AddItem from "../Component/AddItem";
import Collections from "../Component/Collections";
import ShopAaina from "../Component/ShopAaina";
import Register from "../Component/Register";
import Ezee from "../Component/Ezee";
import Proceedcheckout from "../Component/Proceedcheckout";
import Paynow from "../Component/Paynow";
import Coins from "../Component/Coins";
import ShopProduct from "../Component/ShopProduct";
import ContactUs from "../Component/ContactUs/ContactUs";
import FAQs from "../Component/FAQs/FAQs";
import PrivacyPolicy from "../Component/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../Component/TermsCondition/TermsConditions";
import DiamondGuide from "../Component/DiamondGuide/DiamondGuide";
import ShippingRefundPolicy from "../Component/ShippingRefundPolicy/ShippingRefundPolicy";
import GemsStoneGuide from "../Component/GemsStoneGuide/GemsStoneGuide";
import JewelleryGuide from "../Component/JewelleryGuide/JewelleryGuide";
import ProtectedRoutes from "../Component/Protected/ProtectedRoutes";
import OtherJwellery from "../pages/otherjwellery";

function RoutesLists() {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
      children: [
        {
          path: "/",
          element: <MainPage />,
        },
        { path: "shop", element: <Filter /> },
        { path: "shop/product", element: <ShopProduct /> },
        { path: "about", element: <About /> },
        { path: "collections", element: <Collections /> },
        // { path: "/coins", element: <Collections /> },
        { path: "shop_aaina", element: <ShopAaina /> },
        { path: "ezee", element: <Ezee /> },
        { path: "cart", element: <Cart /> },
        { path: "register", element: <Register /> },
        { path: "proceedtocheckout", element: <Proceedcheckout /> },
        { path: "Additem", element: <AddItem /> },
        { path: "Add-To-Cart", element: <AddItem /> },
        // protected Route =>  user cannot access this route directly
        {
          path: "paynow",
          element: (
            <ProtectedRoutes>
              <Paynow />
            </ProtectedRoutes>
          ),
        },
        { path: "coins", element: <Coins /> },
        { path: "contact-us", element: <ContactUs /> },
        { path: "diamond-guide", element: <DiamondGuide /> },
        { path: "gemstone-guide", element: <GemsStoneGuide /> },
        { path: "jewellery-guide", element: <JewelleryGuide /> },
        { path: "faq-page", element: <FAQs /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        { path: "terms-conditions", element: <TermsConditions /> },
        { path: "shipping-refund-policy", element: <ShippingRefundPolicy /> },
        { path: "digi-gold", element: <OtherJwellery /> },
      ],
    },
  ]);
}

export default RoutesLists;
