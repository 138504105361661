import {
  SET_DATEFORMAT,
  SET_LOGIN,
  SET_TOTAL_CART_COUNT,
  SET_GET_CART_DATA,
  SET_USER_INFO,
  SET_CART_DATA,
} from "./ActionTypes";
export const SET_OPEN_LOGIN = "SET_OPEN_LOGIN";
export const SET_PARTY_ID = "SET_PARTY_ID";
export const SET_WISH_LIST = "SET_WISH_LIST";
export const SET_SELECTED_PRODUCT_SAVED = "SET_SELECTED_PRODUCT_SAVED";
export const SET_IS_REGISTER = "SET_IS_REGISTER";
export const SET_EMAIL_ID = "SET_EMAIL_ID";
export const SET_MO_NO = "SET_MO_NO";

const initialState = {
  apiKey: "",
  userInfo: "",
  EmailId: "",
  MobileNo: "",
  cartCount: 0,
  cartData: [],
  wishListData: [],
  dateFormat:
    localStorage && localStorage?.getItem("format")
      ? localStorage.getItem("format")
      : "DD/MM/YYYY",
  openLogin: false,
  partyId: 0,
  externalProduct: {},
  isRegister: false,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        apiKey: action.apiKey,
      };
    case SET_DATEFORMAT:
      localStorage.setItem("format", action.data);
      return {
        ...state,
        dateFormat: action.data,
      };
    case SET_CART_DATA:
      return {
        ...state,
        cartData: action.payload,
      };
    case SET_TOTAL_CART_COUNT:
      return {
        ...state,
        cartCount: action.payload,
      };
    case SET_GET_CART_DATA:
      return {
        ...state,
        getCart: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case SET_PARTY_ID:
      return {
        ...state,
        partyId: action.payload,
      };
    case SET_OPEN_LOGIN:
      return {
        ...state,
        openLogin: action.payload,
      };
    case SET_WISH_LIST:
      return {
        ...state,
        wishListData: action.payload,
      };
    case SET_SELECTED_PRODUCT_SAVED:
      return {
        ...state,
        externalProduct: action.payload,
      };
    case SET_IS_REGISTER:
      return {
        ...state,
        isRegister: action.payload,
      };
    case SET_EMAIL_ID:
      return {
        ...state,
        EmailId: action.payload,
      };
    case SET_MO_NO:
      return {
        ...state,
        MobileNo: action.payload,
      };
    default:
      return state;
  }
};
export default Reducer;
