import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { HttpPost } from "../helper/Api";
import { encrypt } from "../helper/utils";

const CustomerOrderDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const allStateData = useSelector((store) => store.mainReducer);
  const [CartItem, setCartItem] = useState([]);

  const location = useLocation();
  // console.log(location.cartData);

  const getCartItem = async (itemData, cartType = "CART") => {
    // setSearchParams({ q: encrypt({ itemData }) });
    let cartObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      TransItemId: 0,
      vMode: `${cartType}`,
    };
    let res = await HttpPost("GetCartData", cartObj);
    if (res.success) {
      setCartItem(res.ITEM);

      //console.log(res.ITEM);
    } else {
      console.log(res.error);
    }
  };

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      getCartItem();
    }

    return () => {
      isActive = false;
    };
  }, []);
  const [taxTotalInformation, setTaxTotalInformation] = useState([]);
  const getCartTaxInformation = async () => {
    let cartObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      TransItemId: 0,
      vMode: `TF-TAX`,
    };
    let res = await HttpPost("GetCartData", cartObj);
    if (res.success) {
      setTaxTotalInformation(res?.ITEM ? res.ITEM : []);
    }
  };
  useEffect(() => {
    let isActive = true;
    if (isActive) {
      getCartTaxInformation();
    }
    return () => {
      isActive = false;
    };
  }, [allStateData?.cartData?.length]);

  return (
    <div className="container mt-4 mb-5">
      <div>
        <table className="table-width">
          <thead>
            <tr>
              <td className="order-table-data">PRODUCT</td>
              <td className="order-table-data" style={{ textAlign: "right" }}>
                SUBTOTAL
              </td>
            </tr>
          </thead>
          <tbody>
            {CartItem.map((cart) => (
              <tr key={cart.TransItemId}>
                <td className="order-table-data">{cart.VariantName}</td>
                <td className="order-table-data" style={{ textAlign: "right" }}>
                  ₹ {Number(cart.Rate)?.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            {taxTotalInformation?.map((item, i) => (
              <tr key={i}>
                <td className="order-table-data">{item?.RowDescription}</td>
                <td className="order-table-data" style={{ textAlign: "right" }}>
                  ₹{Number(item?.RowValue)?.toFixed(2)}
                </td>
              </tr>
            ))}
            {/* <tr key="SUBTOTAL">
              <td className="order-table-data">SUBTOTAL</td>
              <td className="order-table-data" style={{ textAlign: "center" }}>
                ₹{" "}
                {CartItem?.reduce(
                  (initalValue, cartArray) =>
                    Number(cartArray?.Rate) + initalValue,
                  0
                )?.toFixed(3)}
              </td>
            </tr>
            <tr key="TOTAL">
              <td className="order-table-data">TOTAL</td>
              <td className="order-table-data" style={{ textAlign: "center" }}>
                ₹{" "}
                {CartItem?.reduce(
                  (initalValue, cartArray) =>
                    Number(cartArray?.Rate) + initalValue,
                  0
                )?.toFixed(3)}
              </td>
            </tr> */}
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default CustomerOrderDetail;
