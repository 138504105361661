import axios from './Interceptor';


export function HttpGet(url, data = {}, key) {
  return axios.get(url)
    .then(response => {
      return response.data
    }).catch(error => { return error });
}

export function HttpPost(url, data = {}) {
  return axios.post(url, data).then(response => {
    return response.data
  }).catch(error => { return error });
}