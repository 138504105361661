import React from "react";
import "./JewelleryGuide.css";
import jewelleryGuide1 from "../../image/jewelleyguide1.jpg";
import jewelleryGuide2 from "../../image/jewelleyguide2.jpg";
import jewelleryGuide3 from "../../image/jewelleyguide3.png";
import jewelleryGuide4 from "../../image/jewelleyguide4.png";
import jewelleryGuide5 from "../../image/jewelleyguide5.jpg";

const JewelleryGuide = () => {
  return (
    <div className="jewelleryGuide">
      <div className="jewelleryGuide__header">
        <h1>Jewellery Guide</h1>
      </div>
      <div className="jewelleryGuide__header2">
        <h1>Finding The Right Metal For Yourself</h1>
        <img src={jewelleryGuide1} alt="" />
      </div>
      <div className="jewelleryGuide__cards">
        <div className="jewelleryGuide__card">
          <h2>YELLOW GOLD</h2>
          <p>
            Yellow gold jewelry is still the most popular choice. The popular
            and soft metal is improved when combined with other metals,
            generally with copper, zinc or silver.Basically, most of the jewelry
            is made of 22, 18 or 14 Kt gold.
          </p>
        </div>
        <div className="jewelleryGuide__card">
          <h2>WHITE GOLD</h2>
          <p>
            White gold is authentic, but it is not made entirely of gold. It is
            a lovely, precious metal. Having the same design as platinum and
            silver, but it is much tougher to wear than silver.
          </p>
        </div>
        <div className="jewelleryGuide__card">
          <h2>ROSE GOLD</h2>
          <p>
            Rose gold is a mix and an alloy of pure 24-carat yellow gold, copper
            and silver. With a touch of sophistication, it is essentially gold
            and has been trending a lot lately. It is indeed a mixture of metals
            that produce a soft pink shade that goes well with almost
            everything.
          </p>
        </div>
        <div className="jewelleryGuide__card">
          <h2>PLATINUM</h2>
          <p>
            Platinum is a white coloured metal. It is a very rare and sturdy
            metal.
          </p>
        </div>
      </div>
      <div className="jewelleryGuide__certi">
        <h1>Hallmarking And Certification</h1>
        <div className="jewelleryGuide__hrline"></div>
        <div className="jewelleryGuide__certi__content">
          <div className="jewelleryGuide__certi__content__card">
            <div className="jewelleryGuide__certi__content__card__img">
              <img src={jewelleryGuide2} alt="" />
            </div>
            <div className="jewelleryGuide__certi__content__card__content">
              <h2>HALLMARKING</h2>
              <p>
                Hallmarking is a system to certify the purity of the metal.
                Jewellery sold in India can be hallmarked if it conforms to a
                set of standards laid by the Bureau of Indian Standards (BIS).
                Hallmarking is done at BIS registered hallmarking centre.
              </p>
            </div>
          </div>
          <div className="jewelleryGuide__certi__content__card">
            <div className="jewelleryGuide__certi__content__card__img">
              <img src={jewelleryGuide3} alt="" />
            </div>
            <div className="jewelleryGuide__certi__content__card__content">
              <h2>PLATINUM CERTIFICATION</h2>
              <p>
                Each piece of platinum jewellery comes with a certificate issued
                by the PGI. The product also has a laser inscribed unique code,
                which is mentioned on the certificate. The purity of platinum in
                the product is 950.
              </p>
            </div>
          </div>
          <div className="jewelleryGuide__certi__content__card">
            <div className="jewelleryGuide__certi__content__card__img">
              <img src={jewelleryGuide4} alt="" />
            </div>
            <div className="jewelleryGuide__certi__content__card__content">
              <h2>DIAMOND CERTIFICATION</h2>
              <p>
                Each purchase from Baijnath comes with a bonafide certification
                from us, which is an assurance of cut, color & clarity. The most
                trusted diamond grading laboratories are Gemological Institute
                of America (GIA), HRD, IGI.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="jewelleryGuide__care">
        <h1>Jewellery Care</h1>
        <div className="jewelleryGuide__hrline"></div>
        <p>
          There is always a sentimental reason behind every piece of jewelry you
          purchase, and you do want to wear it forever. Jewelry is one of our
          most intimate and cherished accessories. An exquisite piece of jewelry
          makes an individual statement, knowing how to take care of your
          jewelry, preserving its elegance, and keeping its precious quality
          shining for centuries to come.
        </p>
        <div className="jewelleryGuide__care__card">
          <div className="jewelleryGuide__care__card__img">
            <img src={jewelleryGuide5} alt="" />
          </div>
          <div className="jewelleryGuide__care__card__content">
            <ul>
              <li>
                Put on your jewellery after you’ve applied your cosmetics like
                creams and perfumes.
              </li>
              <li>
                Remove it when you go for swimming or while doing household
                chores.
              </li>
              <li>
                Put your jewellery in warm water with some soap. Clean it with a
                soft brush after soaking for half an hour.
              </li>
              <li>Get your jewellery cleaned or polished from our showroom.</li>
              <li>
                See your jeweler at least once a year, for inspection of your
                ring and other precious items for loose pins and mountings.
              </li>
              <li>
                Store it in the right way in separate boxes as it reduces the
                chances of scratches on the jewellery.
              </li>
              <li>
                Keep it away from moisture by storing it in a fabric-lined box
                or wrap it in soft tissues.
              </li>
              <li>
                Wipe your pearl jewellery with a soft cloth after wearing, as
                even sweat can be damaging to pearls.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JewelleryGuide;
