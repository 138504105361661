import React from 'react'

const FAQs = () => {
    const data = [
        {
            q: "1. WHO ARE WE? WHAT MAKES BAIJNATH DIFFERENT?",
            a: "Ans. The Baijnath beginnings trace back to 1924 and ever since then, Baijnath Jewellers have established a steadily growing and cherished place in the hearts of their clientele by accommodating changing trends and needs. Ranging from elegant and easy to carry work-wear to stunning traditional jewellery, Baijnath covers every facet of life. As of today, a legacy of five generations continues with the influx of new skills, inspirations and outlooks. What remains constant is the endeavour to strike the fine balance between tradition and modernity."
        },
        {
            q: "2. WHICH JEWELLER SHOULD I CHOOSE?",
            a: "Ans. You should choose a trustworthy jeweller, who is selling jewellery in a very transparent manner and has time proven track record of credibility.The one who believes in educating the customer before selling."
        },
        {
            q: "3. SHOULD I GO FOR HALLMARKED OR NON HALLMARKED JEWELLERY?",
            a: "Ans. You should always buy Hallmarked jewellery. Do not go for low costing non-hallmarked jewellery where you save money but end-up buying impure gold jewelley. Make sure of the 5 stamps of hallmarking on the product."
        },
        {
            q: "4. WHAT ARE THE BENEFITS OF BUYING HALLMARKED JEWELLERY?",
            a: `Ans. The benefits of buying Hallmarked jewellery are:
            -Assured metal purity
            -No deduction during return or exchange
            -Other jewellers willingly accept hallmarked jewellery in case of return.`
        },
        {
            q: "5. SHOULD I PAY WASTAGE ON HALLMARKED JEWELLERY?",
            a: `Ans. Always pay wastage on hallmarked jewellery.
            -the wastage is percentage of gold weight added on the net weight of the jewellery.
            -Often jewellers lures customers by offering jewellery without wastage/making charges but compromise on the purity of gold in the jewellery.
            -The process of jewellery making incurs wastage and as an ideal customer you  should pay the applicable wastage.
            -The percentage of wastage varies from piece to piece of jewellery.`
        },
        {
            q: "6.WHAT GOLD RATE WILL I BE CHARGED WHEN I AM BUYING JEWELLERY?",
            a: "Ans. The gold rate will be according to the gold purity/karatage. The jeweller will rightly inform you the rate of 22 kt/ 18kt /14kt gold jewellery on the date of purchase."
        },
        {
            q: "7.WHAT SHOULD I KEEP IN MIND WHILE BUYING DIAMOND JEWELLERY?",
            a: "Ans. While buying diamond jewellery always ask for the diamond jewellery “Certificate of Authenticity”. It should mention the diamond weight, color and clarity."
        },
        {
            q: "8.DOES THE DIAMOND JEWELLERY ALSO HAVE EXCHANGE OR RETURN VALUE LIKE GOLD JEWELLERY?",
            a: "Ans. Yes, Diamonds also have exchange or return value. Baijnath Jewellers offer full value of gold and diamond at appreciated rates on date of exchange/return.We only deduct the labor value of the product. But that is usually offset by the increase in gold and diamond prices. The customer does not lose anything even while buying diamond jewellery."
        },
        {
            q: "9.SHOULD I TAKE PROPER INVOICE WHEN I BUY JEWELLERY?",
            a: `Ans. Always take an invoice with the product. An invoice ensures that the transaction and the product is genuine. It further helps for insurance of family jewellery or valuation etc.
            The GST applicable is at a very nominal rate of 3%`
        },
        {
            q: "10.WHAT IS THE BENEFIT OF JOINING MONTHLY SAVING JEWELLERY BUYING SCHEMES?",
            a: "Ans. It is the most convenient way of planning your jewellery buying. You deposit money on monthly basis and on maturity you buy a piece of jewelley. Further they offer a very good return on your savings. Although there are few schemes in the market, we offer the “EZEE BUY” scheme which has proven to be one of the most flexible schemes."
        },
        {
            q: "11.WHAT IMPORTANT POINTS SHOULD I LOOK FORWARD BEFORE CHOOSING THE JEWELLERY-BUYING SCHEME?",
            a: "Ans. While choosing a scheme look for flexibility of payment & option of money back.These options are really helpful in case of contingency or when you plan some other purchase.”EZEE BUY” scheme offers money back option to customers."
        },
        {
            q: "12.I CAN'T FIND THE PIECE I WANTED ON THE SITE ANYMORE. CAN I STILL ORDER IT?",
            a: "Ans. It might so happen that we discontinue certain pieces or relocate it to some other page. However, we will try our best and make it available for you. Contact Baijnath Jewellers Customer Care team."
        },
        {
            q: "13.WHAT IS YOUR MONEY BACK POLICY?",
            a: "Ans. Our 7-day No Questions asked Money Back Policy gives you time to make sure your purchase is perfect. If you need to return it for any reason, we'll happily provide you with a full refund or exchange, within 7 days of the date of delivery. Yes, it’s that simple. We can only accept the product without custom engraving, in its original condition and with the certification."
        },
        {
            q: "14.WILL YOU WITHHOLD SOME SHIPPING CHARGES WHEN I RETURN THE PRODUCT WITHIN 30 DAYS?",
            a: "Ans. No. When we said, we’ll return the money you paid in full, we meant it."
        },
        {
            q: "15.WHAT IS YOUR EXCHANGE & BUY-BACK POLICY?",
            a: "Ans. We offer a Lifetime Exchange & Buy-back on all purchases made from Baijnath, within India. The product along with the original product certificate can be returned for buy-back or exchanged basis. Refer our T&C for more details."
        },
        {
            q: "16.CAN I COMMISSION A CUSTOM DESIGN?",
            a: "Ans. Please call our Customer Care  team and they will be able to help you. You can customize the gold Caratage and the diamond colour-clarity combination online as per your choice."
        },
        {
            q: "17.CAN I GET THE JEWELLERY IN A DIFFERENT GEMSTONE OR METAL NOT OFFERED ON WEBSITE?",
            a: "Ans. Please call our Customer Care team and they will help you with possible choices."
        },
        {
            q:"18.WHAT IF I DON’T KNOW THE RING SIZE?",
            a:"Ans. We have a complete section on ‘How to measure your ring size’ on our website"
        },
        {
            q:"19.IS MY JEWELLERY AUTHENTIC AND CERTIFIED?",
            a:"Ans. All diamond and gemstone jewellery bought from Baijnath Jewellers is certified and all gold jewellery bought from Baijnath Jewellers is BIS hallmarked."
        },
        {
            q:"20.I LOST MY JEWELLERY CERTIFICATE, CAN I GET A DUPLICATE?",
            a:"Ans. Yes, we can arrange a duplicate for you for Rs 500. It will come with copy/duplicate written across the certificate as a watermark."
        },
        {
            q:"21. IS THERE AN OPTION OF GETTING JEWELLERY WITH DIFFERENT METAL OR GEMSTONE THAT IS NOT OFFERED ON WEBSITE?",
            a:"Ans. Please call our Customer Care team and they will help you with possible choices."
        },
        {
            q:"22.WHAT ARE THE DEBIT / CREDIT CARDS THAT YOU ACCEPT AND WHAT BANKS ARE SUPPORTED UNDER THE INTERNET BANKING OPTION?",
            a:"Ans. We accept all major international credit & debit cards. We also accept net banking facility that is provided by the major banks in India. To get a detailed list on the different options of payment, you can refer the payment options."
        },
    ]
  return (
    <div className='FAQs'>
        <h1>FAQs</h1>
        {data.map((item,i )=>(
            <div key={i}>
                <h3>{item.q}</h3>
                <p>{item.a}</p>
            </div>
        ))}
    </div>
  )
}

export default FAQs