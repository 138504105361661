import React from "react";
import { Box, ImageList, ImageListItem } from "@mui/material";

const SimilarItems = (props) => {
  return (
    <main
      style={{
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        border: "1px solid #E0E4E8",
        padding: "4px 8px",
        borderRadius: "12px",
      }}
    >
      <h6
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          textDecorationLine: "underline",
        }}
      >
        Similar Items
      </h6>
      <ImageList
        sx={{
          width: "100%",
          height: 450,
          gridTemplateColumns: "repeat(1, 1fr) !important",
        }}
      >
        {props?.similarItemList.map((itemInfo) => (
          <ImageListItem key={itemInfo?.VARIANT_ID} cols={1}>
            <Box
              sx={{
                minHeight: "200px",
                maxHeight: "220px",
                minWidth: "200px",
                maxWidth: "220px",
                alignSelf: "center",
              }}
            >
              <img
                alt={itemInfo?.VARIANT_NAME}
                src={itemInfo?.IMAGE_NAME}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => props?.handleNavigateToItemDetailPage(itemInfo)}
              />
            </Box>
            <p
              className="productContainer__product__details__info"
              style={{ marginLeft: "12px", cursor: "none" }}
            >
              <span style={{ fontWeight: "700" }}>SKU: </span>
              {itemInfo?.VARIANT_NAME}
            </p>
            <p
              className="productContainer__product__details__info"
              style={{ marginLeft: "12px", cursor: "none" }}
            >
              <span style={{ fontWeight: "700" }}>CATEGORIES: </span>
              {itemInfo?.CATEGORY}
            </p>
            <p
              className="productContainer__product__details__info"
              style={{ marginLeft: "12px", cursor: "none" }}
            >
              <span style={{ fontWeight: "700" }}>TAG: </span>
              {itemInfo?.STOCK_CODE}
            </p>
            <h5 className="item__price__tag" style={{ cursor: "none" }}>
              ₹{itemInfo.TRANS_AMOUNT}
            </h5>
            {itemInfo?.cartStatus ? (
              <button
                className="item__add__to__cart__btn"
                style={{ alignSelf: "center" }}
                onClick={() => props?.RemoveItemFromCart(itemInfo)}
              >
                REMOVE FROM CART
              </button>
            ) : (
              <button
                className="item__add__to__cart__btn"
                style={{ alignSelf: "center" }}
                onClick={() => props?.AddItemToCartData(itemInfo)}
              >
                ADD TO CART
              </button>
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </main>
  );
};

export default SimilarItems;
