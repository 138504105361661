import React from 'react';
import Additionalinfo from './Additionalinfo';
import Viewcart from './Viewcart';
// import RelatedProducts from './RelatedProducts';
import { useLocation } from 'react-router-dom';

function Cart() {
  const location = useLocation();

  return (
    <div className='container'>

      <Viewcart />
      <div className='row' style={{ marginTop: "70px" }}>
        <div className='col'
          style={
            {
              height: "600px",

            }
          }>
          <img src={location.state.IMAGE_NAME} className="card-img-top" alt="..." />
        </div>
        <div className='col' style={
          {
            fontFamily: "'Tenor Sans', sans-serif",
            textAlign: "left",
            marginTop: "60px",
            marginLeft: "0px"
          }
        }>
          <h1 style={
            {
              fontSize: "55px"
            }
          }>{location.state.CATEGORY}</h1>
          <p style={
            {
              fontSize: "25px"
            }
          }>₹  {location.state.TRANS_AMOUNT}</p>
          <p>{location.state.TOT_CNT}</p>
          <button style={{
            fontSize: "18px",
            border: "2px solid black",
            backgroundColor: "rgb(184,125,139)",
            height: "60px",
            width: "260px",
            marginTop: "30px",
            color: "white"
          }}>ADD TO CART</button>

        </div>
      </div>


      <Additionalinfo data={location.state} />

      {/* <RelatedProducts add={location.state}/> */}
    </div>
  )
}

export default Cart