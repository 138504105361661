import {
  CLEAR_ALL_FILTER,
  SET_FILTER_DATA,
  SET_FILTER_LIST,
} from "./ActionTypes";

const initialState = {
  filterLists: "",
  filterData: [],
  GlobalfilterData: [],
};

const NavBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_LIST:
      return {
        ...state,
        filterLists: action.payload,
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };
    case CLEAR_ALL_FILTER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
export default NavBarReducer;
