import React from 'react'

const Coins = () => {
    return (
        <div>
            <div className='container'>
                <div className='row text-start' style={{height: '253px', display: 'flex', alignItems: 'center'}}>
                    <div className='col-4' style={{width: 'auto', marginRight: '20px'}}>
                        <h4 style={{fontFamily: ['Tenor Sans'], fontSize: 17}}>TAGS</h4>
                        <ul className="list-group list-group-horizontal">
                            <li className="list-group-item coins-list-item" style={{fontFamily: 'Montserrat', border:"none" ,color:"gray",fontSize:"17px", fontWeight: '450', paddingLeft: 0}}>Diamond</li>
                            <li className="list-group-item coins-list-item" style={{fontFamily: 'Montserrat', border:"none" ,color:"gray",fontSize:"17px", fontWeight: '450',  paddingLeft: 0}}>Gold</li>
                            <li className="list-group-item coins-list-item" style={{fontFamily: 'Montserrat', border:"none" ,color:"gray",fontSize:"17px", fontWeight: '450',  paddingLeft: 0}}>Modena</li>
                        </ul>
                    </div>
                    <div className='col-8'>
                        <div className='selected-item-box'>No products were found matching your selection.</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Coins