import React, { useState } from "react";

// icon - import
import { ReactComponent as SeaGoldIcon } from "../../assets/icons/sr_gold_4x.svg";
import { Box, Popover, Stack, SvgIcon, Typography } from "@mui/material";

const MetalRatePopup = (props) => {
  const [metalRateAnchorEl, setMetalRateAnchorEl] = useState(null);

  const handleSetMetalRateAnchorEl = (event) => {
    if (props?.lstMetalRateLists?.length) {
      setMetalRateAnchorEl(event?.currentTarget);
    }
  };

  const handleCloseMetalRatePopup = () => {
    setMetalRateAnchorEl(null);
  };

  return (
    <Box sx={{ m: 0, p: 0, display: "flex", alignItems: "center", gap: "4px" }}>
      <Stack
        direction="row"
        sx={{
          width: "228px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#670f3b",
          color: "#111111",
          borderRadius: "8px",
          padding: "4.8px 8px",
          mt: "1px",
          cursor: "pointer",
        }}
        onClick={(event) => handleSetMetalRateAnchorEl(event)}
      >
        <Box sx={{ display: "flex", gap: "4px" }}>
          <SvgIcon
            component={SeaGoldIcon}
            inheritViewBox
            sx={{
              "& > g > path": {
                fill: "#E8A239",
              },
              width: "16px",
              height: "16px",
            }}
          />
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", fontSize: "12px", color: "#FFFFFF" }}
          >
            {props?.lstMetalRateLists?.length
              ? props?.lstMetalRateLists?.[0]?.["KARAT"]
              : "Gold / -- K"}
            :
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "900", fontSize: "13px", color: "#FFFFFF" }}
          >
            ₹{" "}
            {props?.lstMetalRateLists?.length
              ? props?.lstMetalRateLists?.[0]?.["RATE"]?.toFixed(2)
              : "0.00"}
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", fontSize: "12px", color: "#FFFFFF" }}
          >
            /gm
          </Typography>
        </Box>
      </Stack>
      <Popover
        open={Boolean(metalRateAnchorEl)}
        anchorEl={metalRateAnchorEl}
        onClose={() => handleCloseMetalRatePopup()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            minHeight: "168px",
            maxHeight: "248px",
            boxShadow: "2px 4px 20px rgba(71, 108, 129, 0.4)",
            borderRadius: "12px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <Box
          sx={{
            minWidth: "228px",
            maxWidth: "240px",
            minHeight: "160px",
            maxHeight: "240px",
            mt: "10px",
            position: "relative",
            borderRadius: "8px",
            display: "flex",
            "&::before": {
              backgroundColor: "#FFFFFF",
              content: '""',
              display: "block",
              position: "absolute",
              width: 12,
              height: 12,
              top: -16,
              transform: "rotate(45deg)",
              left: "calc(100% - 50px)",
            },
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {props?.lstMetalRateLists?.map((metalRateList) => (
            <Stack
              key={metalRateList?.KARAT}
              direction="row"
              sx={{
                width: "228px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#FFFFFF",
                color: "#111111",
                borderRadius: "8px",
                padding: "4.8px 8px",
                mt: "1px",
              }}
            >
              <Box sx={{ display: "flex", gap: "4px" }}>
                <SvgIcon
                  component={SeaGoldIcon}
                  inheritViewBox
                  sx={{
                    "& > g > path": {
                      fill:
                        metalRateList?.ITEM_GROUP_ID === 1501
                          ? "#E8A239"
                          : metalRateList?.ITEM_GROUP_ID === 1502
                          ? "#888888"
                          : metalRateList?.ITEM_GROUP_ID === 1503
                          ? "#1890FF"
                          : "#28713E",
                    },
                    width: "16px",
                    height: "16px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", fontSize: "12px", color: "#111111" }}
                >
                  {metalRateList?.["KARAT"]}:
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "900", fontSize: "12px", color: "#111111" }}
                >
                  ₹ {metalRateList?.["RATE"]?.toFixed(2)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", fontSize: "12px", color: "#111111" }}
                >
                  /gm
                </Typography>
              </Box>
            </Stack>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default MetalRatePopup;
