import React, { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpPost } from "../helper/Api";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { encrypt, extractUserDefineError } from "../helper/utils";
import { SET_CART_DATA, SET_TOTAL_CART_COUNT } from "./Store/ActionTypes";
import { SET_SELECTED_PRODUCT_SAVED } from "./Store/Reducer";

function AddItem() {
  const [searchParams, setSearchParams] = useSearchParams();
  const allStateData = useSelector((store) => store.mainReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const getCartItem = async (itemData, cartType = "CART") => {
    // setSearchParams({ q: encrypt({ itemData }) });
    let cartObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      TransItemId: 0,
      vMode: `${cartType}`,
    };
    let res = await HttpPost("GetCartData", cartObj);
    if (res.success) {
      dispatch({
        type: SET_CART_DATA,
        payload: res.ITEM,
      });
    }
  };

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      getCartItem();
    }
    return () => {
      isActive = false;
    };
  }, []);
  const [taxTotalInformation, setTaxTotalInformation] = useState([]);
  const getCartTaxInformation = async () => {
    let cartObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iTransType: "EST",
      TransItemId: 0,
      vMode: `TF-TAX`,
    };
    let res = await HttpPost("GetCartData", cartObj);
    if (res.success) {
      setTaxTotalInformation(res?.ITEM ? res.ITEM : []);
    }
  };
  useEffect(() => {
    let isActive = true;
    if (isActive) {
      getCartTaxInformation();
    }
    return () => {
      isActive = false;
    };
  }, [allStateData?.cartData?.length]);

  const deleteCartItem = async (transItemId) => {
    let delCartObj = {
      iGuid: localStorage.getItem("token__key")?.length
        ? localStorage.getItem("token__key")
        : sessionStorage.getItem("token__key"),
      vMode: "DELETE",
      iMode: "ITEM",
      iScanText: "",
      TransItemId: transItemId,
      iTransItemBomId: 0,
      iTransItemOperationId: 0,
    };

    let res = await HttpPost("pkg_cell_edit_ending", delCartObj);
    if (res.success) {
      // const cartIndex = CartItem.findIndex((cart) => cart.TransItemId === transItemId);
      // setCartItem((prevLists) => ([
      //   ...prevLists?.slice(0, cartIndex),
      //   ...prevLists?.slice(cartIndex + 1)
      // ]))
      const tempCartData = JSON.parse(JSON.stringify(allStateData.cartData));
      const cartIndex = tempCartData.findIndex(
        (cart) => cart.TransItemId === transItemId
      );
      tempCartData.splice(cartIndex, 1);
      dispatch({
        type: SET_CART_DATA,
        payload: tempCartData,
      });

      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: allStateData?.cartCount - 1,
      });
    } else {
      const errorMessage = extractUserDefineError(res.error);
      alert(errorMessage);
    }
  };

  const emptyCartItem = (CartItemInfo) => {
    deleteCartItem(CartItemInfo.TransItemId);
  };

  const checkoutPage = async (itemDetails, cartDetails) => {
    // let saveCartObj = {
    //   "iConfigId": "5053",
    //   "iTransCategoryLinkId": "5053",
    //   "iMenuID": "1000148",
    //   "iWcId": 0,
    //   "iWcGroupId": 0,
    //   "iRowData": "",
    //   "iGuid": localStorage.getItem('token__key')?.length ? localStorage.getItem('token__key') : sessionStorage.getItem('token__key')
    // }
    // let res = await HttpPost("SaveCartData", saveCartObj)
    // if (res.success) {
    // console.log(res.success);
    navigate(`/proceedtocheckout/`, {
      state: itemDetails,
      cartData: cartDetails,
    });
    // }
    // else {
    //   console.log(res.error);
    // }
  };
  const handleOnClickImage = (itemData) => {
    // localStorage.setItem(
    //   "itemObj",
    //   JSON.stringify({ ...itemData, STOCK_CODE: itemData?.StockCode })
    // );
    // sessionStorage.setItem(
    //   "itemObj",
    //   JSON.stringify({ ...itemData, STOCK_CODE: itemData?.StockCode })
    // );

    dispatch({
      type: SET_SELECTED_PRODUCT_SAVED,
      payload: { ...itemData, STOCK_CODE: itemData?.StockCode },
    });
    // navigate(
    //   `/shop/product/?q=${encrypt({
    //     apiKey: allStateData?.apiKey,
    //     externalProduct: { ...itemData, STOCK_CODE: itemData?.StockCode },
    //   })}`
    // );
    navigate(
      `/shop/product/`,
      {
        state: {
          externalProduct: { ...itemData, STOCK_CODE: itemData?.StockCode }
        }
      }
    );
  };
  return (
    <div className="">
      <div className="container-fluid add-to-cart-bg-img">
        <h1
          style={{
            textAlign: "left",
            paddingTop: "95px",
            paddingLeft: "120px",
            fontSize: "40px",
            fontFamily: "'Tenor Sans', sans-serif",
          }}
        >
          CART
        </h1>
      </div>
      <div className="tableContainer">
        <table className="table" style={{ borderWidth: "none" }}>
          <thead
            style={{
              borderWidth: "none",
            }}
          >
            <tr>
              <th scope="col"></th>
              <th className="table__img" scope="col"></th>
              <th scope="col">PRODUCT</th>
              <th scope="col">PRICE</th>
              <th scope="col">QNT</th>
              <th scope="col">SUBTOTAL</th>
            </tr>
          </thead>
          <tbody>
            {allStateData.cartData.map((cart) => (
              <tr key={cart.TransItemId} className="tableRow">
                <td>
                  <button
                    onClick={() => emptyCartItem(cart)}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      color: "gray",
                      fontWeight: "lighter",
                    }}
                  >
                    <ClearIcon sx={{ fontSize: 18 }} />
                  </button>
                </td>
                <td className="table__img">
                  <img
                    src={cart?.ImageUrl ? cart?.ImageUrl : ""}
                    alt=""
                    width="86px"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOnClickImage(cart)}
                  />
                </td>
                <td>{cart?.VariantName}</td>
                <td>₹ {cart?.Rate?.toFixed(2)}</td>
                <td>
                  1
                  {/* <button className="btn btn-sm"
                  style={{borderRadius:"0px",border:"1px solid rgb(216,216,216)",width:"50px",height:"50px",fontSize:"20px",backgroundColor:"transparent"}}>-</button>
                    <input type="text" placeholder="1" style={{borderRadius:"0px",border:"1px solid rgb(216,216,216)",width:"50px",height:"50px",backgroundColor:"tranparen",fontSize:"18px",borderLeft:"none",borderRight:"none"}}/>
                    <button className="btn btn-sm" style={{borderRadius:"0px",border:"1px solid rgb(216,216,216)",width:"50px",height:"50px",backgroundColor:"white"}}>+</button> */}
                </td>
                <td>₹ {cart?.Rate?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* <div className="container-fluid">
        <div className="row card__coupon" style={{ marginTop: "40px" }}>
          <div className="col-6 coupon__div">
            <input
              type="text"
              placeholder="Coupon code"
              className="btn-cart-coupon"
            />
            <button className="btn-cart-appcoupon">APPLY COUPON</button>
          </div>
          <div className="col-6 update-cart__div">
            <button className="update-cart">UPDATE CART</button>
          </div>
        </div>
      </div> */}

      <div className="container mt-5">
        <div className="cart-total-price-box d-flex justify-content-start">
          <h1>Cart Totals</h1>
        </div>
      </div>

      <div className="container mt-4 mb-5">
        <div>
          <table className="table-width">
            <thead>
              {/* <h4 style={{ textAlign: "start", fontFamily: "'Tenor Sans', sans-serif" }}>Cart Totals</h4> */}
              {taxTotalInformation?.map((item, i) => (
                <tr key={i}>
                  <td className="order-table-data">{item?.RowDescription}</td>
                  <td
                    className="order-table-data"
                    style={{ textAlign: "right" }}
                  >
                    ₹{Number(item?.RowValue)?.toFixed(2)}
                  </td>
                </tr>
              ))}
              {/* <tr>
                <td className="order-table-data">SUBTOTAL</td>
                <td
                  className="order-table-data"
                  style={{ textAlign: "center" }}
                >
                  ₹{" "}
                  {allStateData.cartData?.reduce(
                    (initalValue, cartArray) =>
                      Number(cartArray?.Rate) + initalValue,
                    0
                  )}
                </td>
              </tr>
              <tr>
                <td className="order-table-data">TOTAL</td>
                <td
                  className="order-table-data"
                  style={{ textAlign: "center" }}
                >
                  ₹{" "}
                  {allStateData.cartData?.reduce(
                    (initalValue, cartArray) =>
                      Number(cartArray?.Rate) + initalValue,
                    0
                  )}
                </td>
              </tr> */}
            </thead>
          </table>
        </div>

        <div className="container d-flex justify-content-start mt-4 mb-5">
          <button
            className="proceed-btn"
            onClick={() => checkoutPage(location.state, allStateData.cartData)}
            disabled={
              allStateData?.cartData?.reduce(
                (initalValue, cartArray) =>
                  Number(cartArray?.Rate) + initalValue,
                0
              ) === 0
            }
          >
            PROCEED TO CHECKOUT
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddItem;
