import React from "react";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import { Grid } from "@mui/material";
const PaymentSuccess = () => {
  return (
    <div className="main-container">
      <div className="check-container">
        <Grid
          className="check-background"
          sx={{
            svg: {
              path: {
                fill: "#FFFFFF",
              },
              height: "100px !important",
              width: "100%",
            },
          }}
        >
          <Check />
        </Grid>
        <div className="check-shadow"></div>
        {/* <div>Order Number : </div>
        <div>Total : </div> */}
      </div>
    </div>
  );
};

export default PaymentSuccess;
