import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HttpPost } from "../helper/Api";
import { SET_FILTER_DATA } from "./Store/ActionTypes";
import { splicingArrayInOneRow } from "../helper/utils";
import { SET_IS_SEARCH_DATA } from "./Store/ShopProductDetailReducer";

function Navbar() {
  const allStateData = useSelector((store) => store.mainReducer);
  const { filterData } = useSelector((store) => store.NavBarReducer);
  const navbarRef = useRef(null);
  const [openSearch, setOpenSearch] = useState(false);
  const refSearch = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [cateAndLobBuckets, setCateAndLobBuckets] = useState([]);
  const [cateAndCollbBuckets, setCateAndCollBuckets] = useState([]);
  const typoStyleSub = {
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "19.5px",
    py: 0.5,
    cursor: "pointer",
    fontSize: "14px",
    color: "#000000",
    ":hover": {
      color: "#670f3b",
      fontWeight: 600,
    },
  };
  const typoStyleMain = {
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "19.5px",
    fontSize: "14px",
    color: "#000000",
  };
  const [openObj, setOpenObj] = useState({
    shopJewellery: false,
    coins: false,
    shopAaina: false,
    category: false,
    material: false,
    collections: false,
    priceband: false,
    productType: false,
    look: false,
  });
  useEffect(() => {
    if (openSearch) refSearch.current.focus();
  }, [openSearch]);

  const navigateToAnotherPage = (navName) => {
    navbarRef.current.className = "navbar-collapse collapse";
    navigate(navName + "/" + '');
  };
  const windowSize = window.screen.width;

  const openCartPage = (item) => {
    navigate(`/Additem/`);
  };
  const mobNavBarStyle = {
    position: "absolute",
    left: 0,
    top: 50,
    backgroundColor: "#fff",
    zIndex: "999",
    borderBottom: "1px solid #e3dcdc",
    width: "100vw",
  };
  let allRefs = useRef(null);

  const handleMobNavClick = (type) => {
    setOpenObj({ ...openObj, [type]: !openObj[type] });
  };
  const [maxWidth, setMaxWidth] = useState(300);
  useEffect(() => {
    setMaxWidth(window.innerWidth);
    if (
      allStateData.apiKey?.length !== 0 &&
      sessionStorage.getItem("token__key") !== null
    ) {
      getAllCatlogues();
      getFilter();
    }
  }, [allStateData?.apiKey.length]);

  const handleCloseAlRef = (index) => {
    if ([10, 9, 8, 7].includes(index)) {
      allRefs.current.children[index].children[0].style.color = "#222222";
      allRefs.current.children[index].children[0].style.fontWeight = 400;
      return;
    }
    allRefs.current.children[index].children[1].style.display = "none";
    allRefs.current.children[index].children[0].style.color = "#222222";
    allRefs.current.children[index].children[0].style.fontWeight = 400;
    allRefs.current.children[index].children[1].style.position = "relative";
  };
  const handleOpenAlRef = (index) => {
    if ([10, 9, 8, 7].includes(index)) {
      allRefs.current.children[index].children[0].style.color = "#670f3b";
      allRefs.current.children[index].children[0].style.fontWeight = 700;
      return;
    }
    allRefs.current.children[index].children[1].style.display = "block";
    allRefs.current.children[index].children[0].style.fontWeight = 700;
    allRefs.current.children[index].children[0].style.color = "#670f3b";
    allRefs.current.children[index].children[1].style.position = "absolute";
  };
  const bucketsFilterList = [
    "All Jewellery",
    "Rings",
    "Earrings",
    "Necklace",
    "Bracelet",
    // "Other Jewellery",
    "Coins",
    "Silver",
    "Digi Gold",
    "Collections",
    // "Shop Aaina",
    "EZEE Buy",
    // "About us",
  ];
  const CategoryChildFilterList = [
    "All Jewellery",
    "Collections",

    "EZEE Buy",
    "About us",
    "Other Jewellery",
    "Coins",
    "Shop Aaina",
    "Digi Gold",
    "Silver",
  ];
  const [lobAndParentCategory, setLobAndParentCategory] = useState([]);
  const getAllCatlogues = async () => {
    // setCateAndCollBuckets([]);
    // setCateAndLobBuckets([]);
    let body = {
      CATALOGUE_ID: "966924",
    };
    let res = await HttpPost("MainPageListing", body);
    if (res.success) {
      setCateAndCollBuckets(res.data?.CATEGORY_AND_COLLECTION?.buckets);

      setCateAndLobBuckets([
        {
          dynamicId: 0,
          key: "All Jewellery",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        ...res.data?.CATEGORY_AND_LOB?.buckets?.map((bucket) =>
          bucketsFilterList.includes(bucket.key)
            ? {
              ...bucket,
              dynamicId: bucketsFilterList.indexOf(bucket.key),
            }
            : { ...bucket, dynamicId: bucketsFilterList.length + 1 }
        ),
        // {
        //   key: "Other Jewellery",
        //   doc_count: 0,
        //   LOB: {
        //     buckets: [],
        //   },
        // },
        {
          id: 7,
          key: "Digi Gold",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          id: 8,
          key: "Collections",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          dynamicId: bucketsFilterList.length + 1,
          key: "Shop Aaina",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          dynamicId: 10,
          key: "EZEE Buy",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
        {
          dynamicId: 10,
          key: "About us",
          doc_count: 0,
          LOB: {
            buckets: [],
          },
        },
      ]);
      let arrFlat = [];
      const lobAndPaCate = res.data?.LOB_AND_PARENT_CATEGORY?.buckets
        ?.filter(
          (bucket) => bucket?.key === "AAINA" || bucket?.key === "SILVER"
        )
        ?.map((pCate) => pCate?.["PARENT CATEGORY"]?.buckets);
      lobAndPaCate?.forEach((element) => {
        arrFlat = [...arrFlat, ...element];
      });
      setLobAndParentCategory(arrFlat);
    }
  };
  const handleClickOnSub = (
    seleCtedValues = [],
    filterName,
    isAllJewelllery = false
  ) => {
    let selectedValueFilter = [];
    if (filterName) {
      selectedValueFilter = [
        {
          filterType: "PARENT CATEGORY",
          key: filterName,
        },
        ...cateAndLobBuckets
          ?.filter(
            (categoryFilterKey) => categoryFilterKey?.key == filterName
          )?.[0]
          ?.LOB?.buckets?.filter((lob) => lob?.key !== "AAINA")
          ?.map((lob) => ({
            filterType: "LINE OF BUSINESS",
            key: lob?.key,
          })),
        ...(filterName === "Silver"
          ? [
            {
              filterType: "LINE OF BUSINESS",
              key: "AAINA",
            },
          ]
          : []),
      ];
    } else {
      selectedValueFilter = seleCtedValues;
    }
    dispatch({ type: SET_IS_SEARCH_DATA, payload: false });
    let data = filterData.map((nFilter) => {
      if (nFilter?.FILTER_TYPE === "RANGE") {
        const filteringData = selectedValueFilter?.filter(
          (obj2) =>
            nFilter.FILTER_HEADING === obj2.filterType &&
            nFilter?.FILTER_TYPE === obj2?.key
        );

        if (filteringData?.length) {
          return {
            ...nFilter,
            INPUT_MIN_VALUE: `${filteringData[0]?.value[0]}`,
            INPUT_MAX_VALUE: `${filteringData[0]?.value[1]}`,
          };
        } else {
          return { ...nFilter, INPUT_MIN_VALUE: `0`, INPUT_MAX_VALUE: `0` };
        }
      } else {
        const filteringData = selectedValueFilter?.filter(
          (obj2) =>
            nFilter.FILTER_HEADING === obj2.filterType &&
            nFilter?.FILTER_ID === obj2?.key
        );
        if (filteringData?.length) {
          return { ...nFilter, IS_CHECKED: "1" };
        }
      }
      return { ...nFilter, IS_CHECKED: "0" };
    });
    if (isAllJewelllery) {
      data = data?.filter(
        (itemFilterInfo) => itemFilterInfo?.FILTER_ID != "SILVER"
      );
    }
    dispatch({
      type: SET_FILTER_DATA,
      payload: data,
    });
    navigateToAnotherPage("shop");
  };
  // const handleClickOnRange = (seleCtedValues = []) => {
  //   if (filterData?.length) {
  //     let existInd = filterData?.findIndex(
  //       (nFilter) =>
  //         nFilter.FILTER_HEADING === seleCtedValues[0].filterType &&
  //         nFilter.FILTER_DESC === seleCtedValues[0].key
  //     );
  //     if (existInd !== -1) {
  //       let tempArr = [...filterData];
  //       let ind = tempArr.findIndex(
  //         (sellFilter) =>
  //           sellFilter.FILTER_HEADING === "SELLING PRICE" &&
  //           sellFilter.FILTER_TYPE === "RANGE"
  //       );
  //       tempArr[ind].INPUT_MIN_VALUE = seleCtedValues[1]?.value[0];
  //       tempArr[ind].INPUT_MAX_VALUE = seleCtedValues[1]?.value[1];

  //       dispatch({
  //         type: SET_FILTER_DATA,
  //         payload: tempArr,
  //       });
  //       return;
  //     } else {
  //       handleClickOnSub(seleCtedValues);
  //     }
  //   } else {
  //     handleClickOnSub(seleCtedValues);
  //   }
  // };
  const getFilter = async () => {
    let body = {
      SESSION_ID: "352",
      PARTY_ID: "12",
      CATALOGUE_ID: "966924",
      CATALOGUE_NAME: "STOCK",
      WCGROUP_ID: "",
      MODE: "FETCH",
    };
    let res = await HttpPost("getFilterData", body);
    if (res.success) {
      dispatch({ type: SET_FILTER_DATA, payload: [...res.FilterData] });
    }
  };
  return (
    <div
      style={{
        position: "sticky",
        top: "75px",
        zIndex: 15,
        minHeight: "30px",
      }}
    >
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        {!openSearch && (
          <div
            className="container-fluid scr"
            style={{
              background: "#FFFFFF",
              overflowX: "scroll",
              scrollbarWidth: "thin",
              justifyContent: "center",
              // padding: "7px",
            }}
          >
            <div style={{ display: "flex" }}>
              {windowSize < 550 && (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <Typography style={{ fontSize: 12 }} onClick={openCartPage}>
                    <ShoppingBagOutlinedIcon />
                    {allStateData?.cartCount}
                  </Typography>
                  <SearchIcon onClick={() => setOpenSearch(!openSearch)} />
                </div>
              )}
              <button
                className="navbar-toggler header-info__mobNavBtn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ border: "none" }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                ref={navbarRef}
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
                style={
                  windowSize < 550
                    ? mobNavBarStyle
                    : {
                      overflowX: "auto",
                    }
                }
              >
                {cateAndLobBuckets.length ? (
                  <ul
                    ref={allRefs}
                    className="navbar-nav me-auto mb-2 mb-lg-0 mr-auto nav-font px-4"
                    style={{ width: "100%", height: "42px" }}
                  >
                    {cateAndLobBuckets
                      ?.filter((bucket) =>
                        bucketsFilterList.includes(bucket.key)
                      )
                      ?.sort((a, b) => a.dynamicId - b.dynamicId)
                      ?.map((val, i) => (
                        <li
                          key={i + val?.key}
                          className="nav-item"
                          onMouseOver={() => handleOpenAlRef(i)}
                          onMouseOut={() => handleCloseAlRef(i)}
                        >
                          <p
                            className="nav-anchor-list"
                            style={{
                              minWidth:
                                val.key === "EZEE Buy"
                                  ? "69px"
                                  : `${val?.key.length * 8}px`,
                              margin: "0px",
                            }}
                            onClick={() => {
                              if (val.key === "Collections")
                                return navigateToAnotherPage("collections");
                              if (val.key === "EZEE Buy")
                                return navigateToAnotherPage("ezee");
                              if (val.key === "Digi Gold")
                                return navigateToAnotherPage("digi-gold");

                              if (val.key !== "All Jewellery")
                                handleClickOnSub([
                                  {
                                    filterType: "PARENT CATEGORY",
                                    key: val?.key,
                                  },
                                  ...val.LOB.buckets
                                    ?.filter((lob) => lob?.key !== "AAINA")
                                    ?.map((lob) => ({
                                      filterType: "LINE OF BUSINESS",
                                      key: lob?.key,
                                    })),
                                  ...(val?.key === "Silver"
                                    ? [
                                      {
                                        filterType: "LINE OF BUSINESS",
                                        key: "AAINA",
                                      },
                                    ]
                                    : []),
                                ]);
                            }}
                          >
                            {val?.key}
                          </p>

                          {val.key === "All Jewellery" ? (
                            <div
                              className="mega-menu "
                              id="collapseExample"
                              style={{
                                width: `${maxWidth}px !important`,
                                marginLeft: "0px",
                                borderRadius: "0px !important",
                                backgroundColor: "#f6f6f6",
                                paddingBottom: "15px",
                              }}
                            >
                              <Grid
                                container
                                direction={"row"}
                                // gap={1}
                                justifyContent={"flex-start"}
                                className="container"
                                alignItems={"flex-start"}
                                sx={{
                                  mt: 2,
                                  // marginLeft: "50px",
                                  width: `${maxWidth}px !important`,
                                  // minHeight: "240px",
                                }}
                              >
                                {/* ['Bands', 'Solitaires', 'Traditional' ,'Casual'] */}
                                {Object.entries(
                                  splicingArrayInOneRow(
                                    [
                                      ...cateAndLobBuckets?.filter(
                                        (catlogAndLob) =>
                                          !/(Silver|Bichiya|Payal|Peti|Rakhi|Baby Kara|Strings)/i.test(catlogAndLob?.key)
                                      ),
                                    ],
                                    5
                                  )
                                )?.map(([ind, arrVal]) => (
                                  <Grid
                                    key={ind + Math.random()}
                                    item
                                    xs={12}
                                    lg={2.9}
                                    sm={2.9}
                                    md={2.9}
                                    container
                                    direction={"row"}
                                    gap={1.5}
                                    justifyContent="flex-start"
                                    alignItems={"flex-start"}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      sx={{ maxHeight: "19.5px !important" }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          ...typoStyleMain,
                                          minHeight: "19.5px",
                                        }}
                                      >
                                        {ind === "0" ? "By Categories" : ""}
                                      </Typography>
                                    </Grid>
                                    {arrVal
                                      ?.filter(
                                        (filter) =>
                                          !CategoryChildFilterList.includes(
                                            filter.key
                                          )
                                      )
                                      ?.map((collection, i) => (
                                        <Grid
                                          key={i + collection?.key}
                                          item
                                          xs={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          sx={{
                                            maxHeight: "19.5px !important",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={typoStyleSub}
                                            onClick={() =>
                                              handleClickOnSub(
                                                [
                                                  {
                                                    filterType:
                                                      "PARENT CATEGORY",
                                                    key: collection?.key,
                                                  },
                                                ],
                                                collection?.key,
                                                true
                                              )
                                            }
                                          >
                                            {collection?.key}
                                          </Typography>
                                        </Grid>
                                      ))}
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                          ) : (
                            <>
                              <div
                                className="mega-menu "
                                id="collapseExample"
                                style={{
                                  width: `${maxWidth}px !important`,
                                  marginLeft: "0px",
                                  borderRadius: "0px !important",
                                  backgroundColor: "#f6f6f6",
                                  paddingBottom: "15px",
                                }}
                              >
                                <Grid
                                  container
                                  direction={"row"}
                                  gap={1}
                                  justifyContent={"space-around"}
                                  className="container"
                                  alignItems={"flex-start"}
                                  sx={{
                                    mt: 2,
                                    // marginLeft: "50px",
                                    width: `${maxWidth}px !important`,
                                    // minHeight: "240px",
                                  }}
                                >
                                  {/* ['Bands', 'Solitaires', 'Traditional' ,'Casual'] */}
                                  {val.key === "Silver" ? (
                                    <Grid
                                      item
                                      xs={12}
                                      lg={2.9}
                                      sm={2.9}
                                      md={2.9}
                                      container
                                      direction={"row"}
                                      gap={1.5}
                                      justifyContent="flex-start"
                                      alignItems={"flex-start"}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        sx={{ maxHeight: "19.5px !important" }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          sx={{
                                            ...typoStyleMain,
                                            minHeight: "19.5px",
                                          }}
                                        >
                                          {"By Categories"}
                                        </Typography>
                                      </Grid>
                                      {lobAndParentCategory?.map(
                                        (collection, i) => (
                                          <Grid
                                            key={i + collection?.key}
                                            item
                                            xs={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            sx={{
                                              maxHeight: "19.5px !important",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              sx={typoStyleSub}
                                              onClick={() =>
                                                handleClickOnSub([
                                                  {
                                                    filterType:
                                                      "PARENT CATEGORY",
                                                    key: collection?.key,
                                                  },
                                                  ...val.LOB.buckets
                                                    ?.filter(
                                                      (lob) =>
                                                        lob?.key !== "AAINA"
                                                    )
                                                    ?.map((lob) => ({
                                                      filterType:
                                                        "LINE OF BUSINESS",
                                                      key: lob?.key,
                                                    })),
                                                  ...(val?.key === "Silver"
                                                    ? [
                                                      {
                                                        filterType:
                                                          "LINE OF BUSINESS",
                                                        key: "AAINA",
                                                      },
                                                    ]
                                                    : []),
                                                ])
                                              }
                                            >
                                              {collection?.key}
                                            </Typography>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={12}
                                      lg={2.9}
                                      sm={2.9}
                                      md={2.9}
                                      container
                                      gap={1.5}
                                      direction={"row"}
                                    // justifyContent="start"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        sx={{ maxHeight: "19.5px !important" }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          sx={typoStyleMain}
                                        >
                                          By Style
                                        </Typography>
                                      </Grid>
                                      {cateAndCollbBuckets?.filter(
                                        (Coll) => Coll?.key === val?.key
                                      )?.length
                                        ? cateAndCollbBuckets
                                          ?.filter(
                                            (Coll) => Coll?.key === val?.key
                                          )?.[0]
                                          ?.STYLE_COLLECTION?.buckets?.map(
                                            (collection, i) => (
                                              <Grid
                                                key={i + collection?.key}
                                                item
                                                xs={5.9}
                                                lg={5.9}
                                                md={5.9}
                                                sm={5.9}
                                                sx={{
                                                  maxHeight:
                                                    "19.5px !important",
                                                }}
                                              >
                                                <Typography
                                                  variant="subtitle2"
                                                  sx={typoStyleSub}
                                                  onClick={() =>
                                                    handleClickOnSub([
                                                      {
                                                        filterType:
                                                          "PARENT CATEGORY",
                                                        key: val?.key,
                                                      },
                                                      {
                                                        filterType:
                                                          "STYLE COLLECTION",
                                                        key: collection?.key,
                                                      },
                                                      ...val.LOB.buckets
                                                        ?.filter(
                                                          (lob) =>
                                                            lob?.key !==
                                                            "AAINA"
                                                        )
                                                        ?.map((lob) => ({
                                                          filterType:
                                                            "LINE OF BUSINESS",
                                                          key: lob?.key,
                                                        })),
                                                      ...(val?.key ===
                                                        "Silver"
                                                        ? [
                                                          {
                                                            filterType:
                                                              "LINE OF BUSINESS",
                                                            key: "AAINA",
                                                          },
                                                        ]
                                                        : []),
                                                    ])
                                                  }
                                                >
                                                  {collection?.key}
                                                </Typography>
                                              </Grid>
                                            )
                                          )
                                        : null}
                                    </Grid>
                                  )}

                                  <Grid
                                    item
                                    xs={12}
                                    lg={2.9}
                                    sm={2.9}
                                    md={2.9}
                                    container
                                    gap={1.5}
                                    direction={"column"}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      sx={{ maxHeight: "19.5px !important" }}
                                    >
                                      <Typography
                                        variant="h5"
                                        sx={{ ...typoStyleMain }}
                                      >
                                        By Metal & Stone
                                      </Typography>
                                    </Grid>

                                    {val?.LOB?.buckets?.length
                                      ? val.LOB.buckets
                                        ?.filter(
                                          (lineItem) =>
                                            lineItem?.key !== "AAINA"
                                        )
                                        ?.map((lob, i) => (
                                          <Grid
                                            key={i + lob?.key}
                                            item
                                            xs={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            sx={{
                                              maxHeight: "19.5px !important",
                                            }}
                                          >
                                            <Typography
                                              variant="h5"
                                              sx={typoStyleSub}
                                              onClick={() => {
                                                if (lob?.key === "AAINA") {
                                                  handleClickOnSub([
                                                    {
                                                      filterType:
                                                        "LINE OF BUSINESS",
                                                      key: lob?.key,
                                                    },
                                                  ]);
                                                  return;
                                                }
                                                handleClickOnSub([
                                                  {
                                                    filterType:
                                                      "PARENT CATEGORY",
                                                    key: val?.key,
                                                  },
                                                  {
                                                    filterType:
                                                      "LINE OF BUSINESS",
                                                    key: lob?.key,
                                                  },
                                                ]);
                                              }}
                                            >
                                              {lob?.key}
                                            </Typography>
                                          </Grid>
                                        ))
                                      : null}
                                    {val.key === "Silver" ? (
                                      <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        sx={{
                                          maxHeight: "19.5px !important",
                                        }}
                                      >
                                        <Typography
                                          variant="h5"
                                          sx={typoStyleSub}
                                          onClick={() => {
                                            handleClickOnSub([
                                              {
                                                filterType: "LINE OF BUSINESS",
                                                key: "AAINA",
                                              },
                                            ]);
                                          }}
                                        >
                                          AAINA
                                        </Typography>
                                      </Grid>
                                    ) : null}
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    lg={2.9}
                                    sm={2.9}
                                    md={2.9}
                                    container
                                    gap={1.5}
                                    direction={"row"}
                                  >
                                    <Grid
                                      item
                                      xs={6}
                                      lg={6}
                                      md={6}
                                      container
                                      gap={1.5}
                                      direction={"column"}
                                    >
                                      <Typography
                                        variant="h5"
                                        sx={typoStyleMain}
                                      >
                                        By Price
                                      </Typography>
                                      <Typography
                                        variant="h5"
                                        sx={typoStyleSub}
                                        onClick={() =>
                                          handleClickOnSub([
                                            {
                                              filterType: "PARENT CATEGORY",
                                              key: val?.key,
                                              ...val.LOB.buckets
                                                ?.filter(
                                                  (lob) => lob?.key !== "AAINA"
                                                )
                                                ?.map((lob) => ({
                                                  filterType:
                                                    "LINE OF BUSINESS",
                                                  key: lob?.key,
                                                })),
                                              ...(val?.key === "Silver"
                                                ? [
                                                  {
                                                    filterType:
                                                      "LINE OF BUSINESS",
                                                    key: "AAINA",
                                                  },
                                                ]
                                                : []),
                                            },
                                            {
                                              filterType: "SELLING PRICE",
                                              key: "RANGE",
                                              value: [10000, 20000],
                                            },
                                          ])
                                        }
                                      >
                                        10k to 20k
                                      </Typography>
                                      <Typography
                                        variant="h5"
                                        sx={typoStyleSub}
                                        onClick={() =>
                                          handleClickOnSub([
                                            {
                                              filterType: "PARENT CATEGORY",
                                              key: val?.key,
                                            },
                                            {
                                              filterType: "SELLING PRICE",
                                              key: "RANGE",
                                              value: [20000, 30000],
                                            },
                                            ...val.LOB.buckets
                                              ?.filter(
                                                (lob) => lob?.key !== "AAINA"
                                              )
                                              ?.map((lob) => ({
                                                filterType: "LINE OF BUSINESS",
                                                key: lob?.key,
                                              })),
                                            ...(val?.key === "Silver"
                                              ? [
                                                {
                                                  filterType:
                                                    "LINE OF BUSINESS",
                                                  key: "AAINA",
                                                },
                                              ]
                                              : []),
                                          ])
                                        }
                                      >
                                        20k to 30k
                                      </Typography>
                                      <Typography
                                        variant="h5"
                                        sx={typoStyleSub}
                                        onClick={() =>
                                          handleClickOnSub([
                                            {
                                              filterType: "PARENT CATEGORY",
                                              key: val?.key,
                                            },
                                            {
                                              filterType: "SELLING PRICE",
                                              key: "RANGE",
                                              value: [30000, 100000],
                                            },
                                            ...val.LOB.buckets
                                              ?.filter(
                                                (lob) => lob?.key !== "AAINA"
                                              )
                                              ?.map((lob) => ({
                                                filterType: "LINE OF BUSINESS",
                                                key: lob?.key,
                                              })),
                                            ...(val?.key === "Silver"
                                              ? [
                                                {
                                                  filterType:
                                                    "LINE OF BUSINESS",
                                                  key: "AAINA",
                                                },
                                              ]
                                              : []),
                                          ])
                                        }
                                      >
                                        30k to above
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={2.9}
                                    sm={2.9}
                                    md={2.9}
                                    container
                                    gap={1.5}
                                    direction={"row"}
                                  >
                                    <Grid
                                      item
                                      xs={6}
                                      lg={6}
                                      md={6}
                                      container
                                      direction={"column"}
                                    >
                                      {val.key !== "Rings" ? null : (
                                        <>
                                          <Typography
                                            variant="h5"
                                            sx={{
                                              ...typoStyleMain,
                                              mb: 1,
                                              cursor: "pointer",
                                              ":hover": {
                                                color: "#670f3b",
                                              },
                                            }}
                                            onClick={() =>
                                              handleClickOnSub([
                                                {
                                                  filterType: "CATEGORY",
                                                  key: "G Ring",
                                                },
                                                ...val.LOB.buckets
                                                  ?.filter(
                                                    (lob) =>
                                                      lob?.key !== "AAINA"
                                                  )
                                                  ?.map((lob) => ({
                                                    filterType:
                                                      "LINE OF BUSINESS",
                                                    key: lob?.key,
                                                  })),
                                                ...(val?.key === "Silver"
                                                  ? [
                                                    {
                                                      filterType:
                                                        "LINE OF BUSINESS",
                                                      key: "AAINA",
                                                    },
                                                  ]
                                                  : []),
                                              ])
                                            }
                                          >
                                            For Men
                                          </Typography>
                                          <Typography
                                            variant="h5"
                                            sx={{
                                              ...typoStyleMain,
                                              my: 1,
                                              cursor: "pointer",
                                              ":hover": {
                                                color: "#670f3b",
                                              },
                                            }}
                                            onClick={() =>
                                              handleClickOnSub([
                                                {
                                                  filterType: "CATEGORY",
                                                  key: "L Ring",
                                                  ...val.LOB.buckets
                                                    ?.filter(
                                                      (lob) =>
                                                        lob?.key !== "AAINA"
                                                    )
                                                    ?.map((lob) => ({
                                                      filterType:
                                                        "LINE OF BUSINESS",
                                                      key: lob?.key,
                                                    })),
                                                  ...(val?.key === "Silver"
                                                    ? [
                                                      {
                                                        filterType:
                                                          "LINE OF BUSINESS",
                                                        key: "AAINA",
                                                      },
                                                    ]
                                                    : []),
                                                },
                                              ])
                                            }
                                          >
                                            For Women
                                          </Typography>
                                        </>
                                      )}

                                      <Typography
                                        variant="h5"
                                        sx={{
                                          ...typoStyleMain,
                                          my: 1,
                                          cursor: "pointer",
                                          ":hover": {
                                            color: "#670f3b",
                                          },
                                        }}
                                        onClick={() =>
                                          handleClickOnSub([
                                            {
                                              filterType: "PARENT CATEGORY",
                                              key: val?.key,
                                            },
                                            ...val.LOB.buckets
                                              ?.filter(
                                                (lob) => lob?.key !== "AAINA"
                                              )
                                              ?.map((lob) => ({
                                                filterType: "LINE OF BUSINESS",
                                                key: lob?.key,
                                              })),
                                            ...(val?.key === "Silver"
                                              ? [
                                                {
                                                  filterType:
                                                    "LINE OF BUSINESS",
                                                  key: "AAINA",
                                                },
                                              ]
                                              : []),
                                          ])
                                        }
                                      >
                                        Show All Designs
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          )}
                        </li>
                      ))}
                  </ul>
                ) : null}

                {/* Navbar for mobile */}
                <div className="mobileNavbarLinks">
                  <a href="#" className="nav-anchor-list">
                    <ListItemButton
                      onClick={() => navigateToAnotherPage("about")}
                    >
                      <ListItemText>About</ListItemText>
                    </ListItemButton>
                  </a>
                  <ListItemButton
                    onClick={() => handleMobNavClick("shopJewellery")}
                  >
                    <ListItemText primary="SHOP JEWELLERY" />
                    {openObj.shopJewellery ? (
                      <ExpandMoreIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openObj.shopJewellery}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <a href="#" className="nav-anchor-list">
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => navigateToAnotherPage("shop")}
                        >
                          <ListItemText primary="ALL JEWELLERY" />
                        </ListItemButton>
                      </a>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleMobNavClick("category")}
                      >
                        <ListItemText primary="CATEGORY" />
                        {openObj.category ? (
                          <ExpandMoreIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openObj.category}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Earrings" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Pendants" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Ladies Rings" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Gents Rings" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Nosepins" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Chains" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Bracelets" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Bangles" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Necklace" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Mangalsutra" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleMobNavClick("material")}
                      >
                        <ListItemText primary="MATERIAL" />
                        {openObj.material ? (
                          <ExpandMoreIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openObj.material}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Gold" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Diamond" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Platinum" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Coloured Stone" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleMobNavClick("collections")}
                      >
                        <ListItemText primary="COLLECTIONS" />
                        {openObj.collections ? (
                          <ExpandMoreIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openObj.collections}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Modena" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleMobNavClick("priceband")}
                      >
                        <ListItemText primary="PRICE BAND" />
                        {openObj.priceband ? (
                          <ExpandMoreIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openObj.priceband}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Under 10 K" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="10 K - 30 K" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="30 K-50 K" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Above 50 K" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Above 1 Lac" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Above 2 Lac" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </Collapse>
                  <a href="#" className="nav-anchor-list">
                    <ListItemButton
                      onClick={() => navigateToAnotherPage("collections")}
                    >
                      <ListItemText>COLLECTIONS</ListItemText>
                    </ListItemButton>
                  </a>
                  <ListItemButton onClick={() => handleMobNavClick("coins")}>
                    <ListItemText>COINS</ListItemText>
                    {openObj.coins ? (
                      <ExpandMoreIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemButton>
                  <Collapse in={openObj.coins} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <a href="#" className="nav-anchor-list">
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText
                            primary="Gold Coin"
                            onClick={() => navigateToAnotherPage("coins")}
                          />
                        </ListItemButton>
                      </a>
                      <a href="#" className="nav-anchor-list">
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText
                            primary="Silver Coin"
                            onClick={() => navigateToAnotherPage("coins")}
                          />
                        </ListItemButton>
                      </a>
                    </List>
                  </Collapse>
                  <ListItemButton
                    onClick={() => handleMobNavClick("shopAaina")}
                  >
                    <ListItemText>SHOP AAINA</ListItemText>
                    {openObj.shopAaina ? (
                      <ExpandMoreIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemButton>
                  <Collapse in={openObj.shopAaina} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleMobNavClick("productType")}
                      >
                        <ListItemText primary="Product Type" />
                        {openObj.productType ? (
                          <ExpandMoreIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openObj.productType}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <a href="#" className="nav-anchor-list">
                            <ListItemButton
                              sx={{ pl: 8 }}
                              onClick={() => navigateToAnotherPage("shop")}
                            >
                              <ListItemText primary="Earrings" />
                            </ListItemButton>
                          </a>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Necklace" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Rings" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Bangles/Bracelets" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Pendants" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Nose pins" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Toe Rings" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Anklets" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Chain/Neck Strings" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Men Accessories" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleMobNavClick("look")}
                      >
                        <ListItemText primary="Look" />
                        {openObj.look ? (
                          <ExpandMoreIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </ListItemButton>
                      <Collapse in={openObj.look} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <a href="#" className="nav-anchor-list">
                            <ListItemButton
                              sx={{ pl: 8 }}
                              onClick={() => navigateToAnotherPage("shop")}
                            >
                              <ListItemText primary="Traditional" />
                            </ListItemButton>
                          </a>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Tribal" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }}>
                            <ListItemText primary="Coloured Stone" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </Collapse>
                  <a href="#" className="nav-anchor-list">
                    <ListItemButton
                      onClick={() => navigateToAnotherPage("ezee")}
                    >
                      <ListItemText>EZEE BUY</ListItemText>
                    </ListItemButton>
                  </a>
                  <ListItemButton>
                    <ListItemText>+91 9984500051/52</ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>info@baijnathjewellers.com</ListItemText>
                  </ListItemButton>
                </div>
              </div>
            </div>
          </div>
        )}
        {openSearch && (
          <div className="navbar__search">
            <input
              type="text"
              name="search"
              placeholder="Search..."
              ref={refSearch}
            />
            <ClearIcon
              style={{ fontSize: 24, cursor: "pointer" }}
              onClick={() => setOpenSearch(!openSearch)}
            />
          </div>
        )}
      </nav>
    </div>
  );
}

export default Navbar;
