import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { HttpPost } from "../helper/Api";
import { useState } from "react";
import { useEffect } from "react";
function EditProfile(props) {
  const [countryList, setContryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [countryValue, setCountryValue] = useState({});
  const [stateValue, setStateValue] = useState({});
  const [cityValue, setCityValue] = useState({});
  const [talukaValue, setTalukaValue] = useState({});
  const handleChange = (fieldName, value) => {
    props?.setData({ ...props?.data, [fieldName]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleGetCourtyList = async () => {
    // if (countryList?.length) return;
    let body = {
      QuerySettingId: 2953,
      QuerySettingValues: "0",
    };
    let res = await HttpPost("searchLov", body);
    if (res.success) {
      // console.log("res", res.data.Response);
      setContryList(res.data.Response);
    }
  };
  const handleStateList = async () => {
    // if (countryList?.length) return;
    let body = {
      QuerySettingId: 4099,
      QuerySettingValues: "",
      fetchType: "DATA",
      CallMode: "LOV",
    };
    let res = await HttpPost("searchLov", body);
    if (res.success) {
      // console.log("res", res.data.Response);
      setStateList(res.data.Response);
    }
  };
  const handlePinCodeCheck = async (e) => {
    if (!e.target.value?.length) return;
    if (e.key === "Tab" || e.key === "Enter") {
      let body = {
        QuerySettingId: 2899,
        QuerySettingValues: e.target.value,
      };
      let res = await HttpPost("searchLov", body);
      if (res.success) {
        const details = res.data?.Response?.[0];
        props?.setData((prev) => ({
          ...prev,
          ...(e.target.name === "Pincode"
            ? {
                cityId: details?.CITY_ID,
                city: details?.CITY_NAME,
                countryId: details?.COUNTRY_ID,
                country: details?.COUNTRY_NAME,
                stateId: details?.STATE_ID,
                state: details?.STATE_NAME,
              }
            : {
                ShipcityId: details?.CITY_ID,
                Shipcity: details?.CITY_NAME,
                ShipcountryId: details?.COUNTRY_ID,
                Shipcountry: details?.COUNTRY_NAME,
                ShipstateId: details?.STATE_ID,
                Shipstate: details?.STATE_NAME,
              }),
        }));
      }
    }
  };
  useEffect(() => {
    if (!countryList?.length) {
      handleGetCourtyList();
    }
    if (!StateList?.length) {
      handleStateList();
    }
    if (!cityList?.length) {
      handleCityList();
    }
    if (!talukaList?.length) {
      handleTalukaList();
    }
  }, []);
  const handleCityList = async () => {
    let body = {
      QuerySettingId: 2552,
      QuerySettingValues: "",
      fetchType: "FILTER",
      CallMode: "LOV",
    };
    const res = await HttpPost("searchLov", body);
    if (res?.success) {
      setCityList(res.data.Response);
    }
  };
  const handleTalukaList = async () => {
    let body = {
      QuerySettingId: 3995,
      QuerySettingValues: "",
      fetchType: "DATA",
      CallMode: "LOV",
    };
    const res = await HttpPost("searchLov", body);
    if (res?.success) {
      setTalukaList(res.data.Response);
    }
  };
  const handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      props?.setData((prev) => ({
        ...prev,
        Shiptaluka: prev?.taluka ? prev?.taluka : "",
        Shipcity: prev?.city ? prev?.city : "",
        ShipcityId: prev?.cityId ? prev?.cityId : "",
        Shipstate: prev?.state ? prev?.state : "",
        Shipcountry: prev?.country ? prev?.country : "",
        ShipPincode: prev?.Pincode ? prev?.Pincode : "",
        Shipaddresss2: prev?.addresss2 ? prev?.addresss2 : "",
        ShipAddress1: prev?.Address1 ? prev?.Address1 : "",
        ShipgstNo: prev?.gstNo ? prev?.gstNo : "",
        ShippanNo: prev?.panNo ? prev?.panNo : "",
        copyBillingAdd: 1,
      }));
    } else {
      props?.setData((prev) => ({
        ...prev,
        Shiptaluka: "",
        Shipcity: "",
        ShipcityId: "",
        Shipstate: "",
        Shipcountry: "",
        ShipPincode: "",
        Shipaddresss2: "",
        ShipAddress1: "",
        ShipgstNo: "",
        ShippanNo: "",
        copyBillingAdd: 0,
      }));
    }
  };
  const handleChangeSelection = (target, value) => {
    switch (target) {
      case "city":
        const city = cityList?.filter((li) => li?.REGION_NAME === value)?.[0];
        if (city) {
          setCityValue(city);

          props?.setData((prev) => ({
            ...prev,
            cityId: city?.REGION_ID,
          }));
        }
        return;
      case "Shipcity":
        const Shipcity = cityList?.filter(
          (li) => li?.REGION_NAME === value
        )?.[0];
        if (Shipcity) {
          props?.setData((prev) => ({
            ...prev,
            ShipcityId: Shipcity?.REGION_ID,
          }));
        }
        return;
      case "country":
        const country = countryList?.filter(
          (li) => li?.COUNTRY_NAME === value
        )?.[0];

        if (country) {
          props?.setData((prev) => ({
            ...prev,
            countryId: country?.REGION_ID,
          }));
        }
        return;
      case "Shipcountry":
        const Shipcountry = countryList?.filter(
          (li) => li?.COUNTRY_NAME === value
        )?.[0];

        if (Shipcountry) {
          props?.setData((prev) => ({
            ...prev,
            ShipcountryId: Shipcountry?.REGION_ID,
          }));
        }
        return;
      case "state":
        const state = StateList?.filter((li) => li?.REGION_NAME === value)?.[0];
        if (state) {
          props?.setData((prev) => ({
            ...prev,
            stateId: state?.REGION_ID,
          }));
        }
        return;
      case "Shipstate":
        const Shipstate = StateList?.filter(
          (li) => li?.REGION_NAME === value
        )?.[0];
        if (Shipstate) {
          props?.setData((prev) => ({
            ...prev,
            ShipstateId: Shipstate?.REGION_ID,
          }));
        }
        return;

      default:
        return;
    }
  };
  return (
    <Dialog
      open={props.editClose}
      onClose={() => props.handleEditClose()}
      style={{ backgroundColor: "rgba(255,255,255,.75)", overflowY: "hidden" }}
      fullWidth
      maxWidth={"xl"}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ display: "flex", alignItems: "center", flex: "1" }}>
            <Typography variant="h5" component="h2">
              Edit Profile
            </Typography>
          </Box>
          <Tooltip title="Esc to close">
            <IconButton
              tabIndex={-1}
              sx={{ borderRadius: "50px" }}
              onClick={() => props.handleEditClose()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <div className="register">
          <form onSubmit={handleSubmit}>
            <div className="register__inputs">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  First Name*
                </label>
                <input
                  type="text"
                  autoFocus={true}
                  value={props.data?.firstName ? props.data?.firstName : ""}
                  autoComplete="off"
                  name="firstName"
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Last Name*
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  name="lastName"
                  value={props.data?.lastName ? props.data?.lastName : ""}
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  disabled={true}
                  value={props.data?.email ? props.data?.email : ""}
                  autoComplete="off"
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Mobile No*
                </label>
                <span style={{fontSize: "12px",
                background: "#FFFFFF",
                position: "absolute",
                top: 12,
                left: "5px",
                padding: "0px 5px",}}>+91</span>
                <input
             type="number"
             inputMode="numeric"
                  name="number"
                  disabled={true}
                  value={props.data?.number ? props.data?.number : ""}
                  maxLength={'10'}
                  minLength={'10'}
                  style={{paddingLeft:'35px'}}
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -5,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Birth Date
                </label>

                <input
                  type="date"
                  placeholder="Birth Date*"
                  name="birth_date"
                  // value={props?.data?.birth_date}
                  defaultValue={new Date(
                    props?.data?.birth_date
                  )?.toLocaleDateString("en-CA")}
                  required
                  onChange={(e) => handleChange("birth_date", e.target.value)}
                />
              </div>
              {/* <label>Anniversary Date</label> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -5,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Anniversary Date
                </label>

                <input
                  type="date"
                  placeholder="Anniversary Date*"
                  name="anniversary_date"
                  defaultValue={new Date(
                    props?.data?.anniversary_date
                  )?.toLocaleDateString("en-CA")}
                  required
                  onChange={(e) =>
                    handleChange("anniversary_date", e.target.value)
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Pan Number
                </label>

                <input
                  type="text"
                  autoComplete="off"
                  value={props.data?.panNo ? props.data?.panNo : ""}
                  name="panNo"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Gst Number.
                </label>

                <input
                  type="text"
                  autoComplete="off"
                  value={props.data?.gstNo ? props.data?.gstNo : ""}
                  name="gstNo"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div style={{ width: "100%" }}>Billing Address*</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Address 1*
                </label>
                <input
                  type="text"
                  name="Address1"
                  value={props.data?.Address1 ? props.data?.Address1 : ""}
                  autoComplete="off"
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Addresss 2
                </label>
                <input
                  type="text"
                  name="addresss2"
                  value={props.data?.addresss2 ? props.data?.addresss2 : ""}
                  autoComplete="off"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Pin code*
                </label>

                <input
                  type="number"
                  name="Pincode"
                  autoComplete="off"
                  value={props.data?.Pincode ? props.data?.Pincode : ""}
                  required
                  min={0}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  onKeyDown={(e) => handlePinCodeCheck(e)}
                />
              </div>
              {/* country */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Country
                </label>

                <input
                  list="country"
                  name="country"
                  value={props.data?.country ? props.data?.country : ""}
                  role="combobox"
                  size="5"
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChangeSelection(e.target.name, e.target.value);
                    handleChange(e.target.name, e.target.value);
                  }}
                />
              </div>
              <datalist
                id="country"
                role="listbox"
                style={{
                  position: "absolute !important",
                  backgroundColor: "white",
                  border: "1px solid blue",
                  borderRadius: "0 0 5px 5px",
                  borderTop: "none",
                  width: "350px",
                  padding: "5px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {countryList?.map((country, index) => (
                  <option key={index} value={country?.COUNTRY_NAME}>
                    {country?.COUNTRY_NAME} ( {country?.COUNTRY_CODE} )
                  </option>
                ))}
              </datalist> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  State
                </label>

                <input
                  list="state"
                  name="state"
                  value={props.data?.state ? props.data?.state : ""}
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                    handleChangeSelection(e.target.name, e.target.value);
                  }}
                />
              </div>
              <datalist
                id="state"
                role="listbox"
                style={{ maxHeight: "100px !important", minHeight: "100px" }}
              >
                {StateList?.map((state, index) => (
                  <option key={index} value={state?.REGION_NAME}>
                    {state?.REGION_NAME} ( {state?.REGION_CODE} )
                  </option>
                ))}
              </datalist>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  City*
                </label>

                <input
                  list="city"
                  name="city"
                  required
                  value={props.data?.city ? props.data?.city : ""}
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                    handleChangeSelection(e.target.name, e.target.value);
                  }}
                />
              </div>
              <datalist
                id="city"
                role="listbox"
                style={{ maxHeight: "100px !important", minHeight: "100px" }}
              >
                {cityList?.map((state, index) => (
                  <option key={index} value={state?.REGION_NAME}>
                    {state?.REGION_NAME} ( {state?.REGION_CODE} )
                  </option>
                ))}
              </datalist>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Taluka
                </label>

                <input
                  list="taluka"
                  name="taluka"
                  autoComplete="off"
                  value={props.data?.taluka ? props.data?.taluka : ""}
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                />
              </div>

              {/* Shiiping Address */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <div>Shipping Address*</div>
                <div>
                  <Tooltip
                    title="Same as billing address"
                    sx={{ mt: "0px !important" }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        handleCheckBoxChange(e);
                      }}
                      sx={{
                        minWidth: "unset",
                      }}
                      inputProps={{
                        style: {
                          minWidth: "unset",
                        },
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Address 1*
                </label>
                <input
                  type="text"
                  name="ShipAddress1"
                  autoComplete="off"
                  value={
                    props.data?.ShipAddress1 ? props.data?.ShipAddress1 : ""
                  }
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Addresss 2
                </label>
                <input
                  type="text"
                  value={
                    props.data?.Shipaddresss2 ? props.data?.Shipaddresss2 : ""
                  }
                  name="Shipaddresss2"
                  autoComplete="off"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Pin code*
                </label>

                <input
                  type="number"
                  name="ShipPincode"
                  value={props.data?.ShipPincode ? props.data?.ShipPincode : ""}
                  // autoComplete="off"
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  onKeyDown={(e) => handlePinCodeCheck(e)}
                />
              </div>
              {/* country */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Country
                </label>

                <input
                  list="country"
                  name="Shipcountry"
                  role="combobox"
                  size="5"
                  value={props.data?.Shipcountry ? props.data?.Shipcountry : ""}
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChangeSelection(e.target.name, e.target.value);
                    handleChange(e.target.name, e.target.value);
                  }}
                />
              </div>
              <datalist
                id="country"
                role="listbox"
                style={{
                  position: "absolute !important",
                  backgroundColor: "white",
                  border: "1px solid blue",
                  borderRadius: "0 0 5px 5px",
                  borderTop: "none",
                  width: "350px",
                  padding: "5px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {countryList?.map((country, index) => (
                  <option key={index} value={country?.COUNTRY_NAME}>
                    {country?.COUNTRY_NAME} ( {country?.COUNTRY_CODE} )
                  </option>
                ))}
              </datalist>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  State
                </label>

                <input
                  list="state"
                  name="Shipstate"
                  value={props.data?.Shipstate ? props.data?.Shipstate : ""}
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChangeSelection(e.target.name, e.target.value);
                    handleChange(e.target.name, e.target.value);
                  }}
                />
              </div>
              <datalist
                id="state"
                role="listbox"
                style={{ maxHeight: "100px !important", minHeight: "100px" }}
              >
                {StateList?.map((state, index) => (
                  <option key={index} value={state?.REGION_NAME}>
                    {state?.REGION_NAME} ( {state?.REGION_CODE} )
                  </option>
                ))}
              </datalist>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  City*
                </label>

                <input
                  list="city"
                  name="Shipcity"
                  required
                  value={props.data?.Shipcity ? props.data?.Shipcity : ""}
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                    handleChangeSelection(e.target.name, e.target.value);
                  }}
                />
              </div>
              <datalist
                id="city"
                role="listbox"
                style={{ maxHeight: "100px !important", minHeight: "100px" }}
              >
                {cityList?.map((state, index) => (
                  <option key={index} value={state?.REGION_NAME}>
                    {state?.REGION_NAME} ( {state?.REGION_CODE} )
                  </option>
                ))}
              </datalist>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    fontSize: "10px",
                    background: "#FFFFFF",
                    position: "absolute",
                    top: -6,
                    left: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Taluka
                </label>

                <input
                  list="taluka"
                  name="Shiptaluka"
                  autoComplete="off"
                  value={props.data?.Shiptaluka ? props.data?.Shiptaluka : ""}
                  // onFocus={() => handleGetCourtyList()}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <button
          className="register__btn"
          type="submit"
          onClick={(e) => props?.handleEditButtonClick(e)}
        >
          EDIT
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProfile;
