import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { HttpPost } from "../helper/Api";
import { Autocomplete, Menu, MenuItem, TextField } from "@mui/material";
import { SEACustomInput } from "./formControls/CustomInput";
import { useDispatch } from "react-redux";
import { SET_ERROR_MODAL_OPEN } from "./Store/errorModalReducer";

const Register = ({ closeRegister }) => {
  const [myIp, setIp] = useState("");
  const isPinError = useRef(true);
  const [countryList, setContryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [countryValue, setCountryValue] = useState({});
  const [stateValue, setStateValue] = useState({});
  const [cityValue, setCityValue] = useState({});
  const [talukaValue, setTalukaValue] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    // username: '',
    email: "",
    number: "",
    password: "",
    repeatpassword: "",
  });
  useEffect(() => {
    const getIP = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      if (res) setIp(res.data.IPv4);
    };
    getIP();
    return () => {
      closeRegister();
    };
  }, []);
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.repeatpassword) {
      dispatch({
        type: SET_ERROR_MODAL_OPEN,
        payload: {
          isOpen: true,
          message: "Password does not match.",
          details: "",
        },
      });
      return;
    }
    if (isPinError.current) {
      dispatch({
        type: SET_ERROR_MODAL_OPEN,
        payload: {
          isOpen: true,
          message: "Please Enter Valid Pincode.",
          details: "",
        },
      });
      return;
    }
    let dataObj = {
      // "SessionId": 212069,
      CallMode: "LOV",
      QuerySettingId: 2487,
      QuerySettingValues: `API:${formData?.firstName}:${
        formData?.lastName ? formData?.lastName : ""
      }:${formData.email}:${formData.name}:${
        formData.number
      }:ip-${myIp}.ap-south-1.compute.internal:${myIp}:dd/mm/yyyy:0:${
        formData.password
      }:${formData?.Address1}:${
        formData?.addresss2 ? formData?.addresss2 : ""
      }:${formData?.BillingPincode}:${
        cityValue?.REGION_ID ? cityValue?.REGION_ID : ""
      }::${formData?.panNo ? formData?.panNo : ""}:${
        formData?.gstNo ? formData?.gstNo : ""
      }`,
      fetchType: "FILTER",
      requiredSocket: 0,
      isLogin: 0,
      pageNo: 1,
    };
    let res = await HttpPost("searchLov", dataObj);
    if (res.success) {
      alert("User Registered Successfully");
      closeRegister();
    }
  };

  const handleChange = (type, v) => {
    if (type === "number" && v?.length > 10) return;
    if (type === "BillingPincode" && v?.length > 6) return;
    setFormData({
      ...formData,
      [type]: v,
    });
  };

  const handleGetCourtyList = async () => {
    // if (countryList?.length) return;
    let body = {
      QuerySettingId: 2953,
      QuerySettingValues: "0",
    };
    let res = await HttpPost("searchLov", body);
    if (res.success) {
      // console.log("res", res.data.Response);
      setContryList(res.data.Response);
    }
  };
  const handleStateList = async () => {
    // if (countryList?.length) return;
    let body = {
      QuerySettingId: 4099,
      QuerySettingValues: "",
      fetchType: "DATA",
      CallMode: "LOV",
    };
    let res = await HttpPost("searchLov", body);
    if (res.success) {
      // console.log("res", res.data.Response);
      setStateList(res.data.Response);
    }
  };
  const handlePinCodeCheck = async (e) => {
    if (!e.target.value?.length) return;
    if (e.key === "Tab" || e.key === "Enter") {
      let body = {
        QuerySettingId: 2899,
        QuerySettingValues: e.target.value,
      };
      let res = await HttpPost("searchLov", body);
      if (res.success) {
        isPinError.current = false;
      } else {
        isPinError.current = true;
      }
    }
  };
  useEffect(() => {
    if (!countryList?.length) {
      handleGetCourtyList();
    }
    if (!StateList?.length) {
      handleStateList();
    }
    if (!cityList?.length) {
      handleCityList();
    }
    if (!talukaList?.length) {
      handleTalukaList();
    }
  }, []);
  const handleCityList = async () => {
    let body = {
      QuerySettingId: 2552,
      QuerySettingValues: "",
      fetchType: "FILTER",
      CallMode: "LOV",
    };
    const res = await HttpPost("searchLov", body);
    if (res?.success) {
      setCityList(res.data.Response);
    }
  };
  const handleTalukaList = async () => {
    let body = {
      QuerySettingId: 3995,
      QuerySettingValues: "",
      fetchType: "DATA",
      CallMode: "LOV",
    };
    const res = await HttpPost("searchLov", body);
    if (res?.success) {
      setTalukaList(res.data.Response);
    }
  };

  return (
    <div className="register">
      <h2>Create An Account</h2>
      <form onSubmit={handleSubmit}>
        <div className="register__inputs">
          <div style={{ width: "100%" }}>Personal Info*</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              First Name*
            </label>
            <input
              type="text"
              autoFocus={true}
              autoComplete="off"
              name="firstName"
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Last Name*
            </label>
            <input
              type="text"
              autoComplete="off"
              name="lastName"
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Email*
            </label>
            <input
              type="email"
              name="email"
              autoComplete="off"
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Mobile No*
            </label>
            <span style={{fontSize: "12px",
                background: "#FFFFFF",
                position: "absolute",
                top: 12,
                left: "5px",
                padding: "0px 5px",}}>+91</span>
           
            <input
              type="number"
              inputMode="numeric"
              name="number"
              maxLength={10}
              value={formData.number}
              style={{paddingLeft:'35px'}}
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Password*
            </label>
            <input
              type="password"
              name="password"
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Re-password*
            </label>
            <input
              type="password"
              name="repeatpassword"
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Pan Number*
            </label>

            <input
              type="text"
              autoComplete="off"
              name="panNo"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Gst Number.*
            </label>

            <input
              type="text"
              autoComplete="off"
              name="gstNo"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div style={{ width: "100%" }}>Billing Address*</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Address 1*
            </label>
            <input
              type="text"
              name="Address1"
              autoComplete="off"
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Addresss 2*
            </label>
            <input
              type="text"
              name="addresss2"
              autoComplete="off"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Pin code*
            </label>

            <input
              type="number"
              inputMode="numeric"
              maxLength={6}
              name="BillingPincode"
              // autoComplete="off"
              required
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              onKeyDown={(e) => handlePinCodeCheck(e)}
            />
          </div>
          {/* country */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Country*
            </label>

            <input
              list="country"
              name="country"
              role="combobox"
              size="5"
              // value={countryValue?.COUNTRY_NAME ? countryValue?.COUNTRY_NAME : ""}
              // onFocus={() => handleGetCourtyList()}
              onChange={(e) => {
                setCountryValue(
                  countryList?.filter(
                    (li) => li?.COUNTRY_NAME === e.target.value
                  )?.[0]
                );
              }}
            />
          </div>
          <datalist
            id="country"
            role="listbox"
            style={{
              position: "absolute !important",
              backgroundColor: "white",
              border: "1px solid blue",
              borderRadius: "0 0 5px 5px",
              borderTop: "none",
              width: "350px",
              padding: "5px",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {countryList?.map((country, index) => (
              <option key={index} value={country?.COUNTRY_NAME}>
                {country?.COUNTRY_NAME} ( {country?.COUNTRY_CODE} )
              </option>
            ))}
          </datalist>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              State*
            </label>

            <input
              list="state"
              name="state"
              // value={stateValue?.REGION_NAME ? stateValue?.REGION_NAME : ""}
              // onFocus={() => handleGetCourtyList()}
              onChange={(e) => {
                setStateValue(
                  StateList?.filter(
                    (li) => li?.REGION_NAME === e.target.value
                  )?.[0]
                );
              }}
            />
          </div>
          <datalist
            id="state"
            role="listbox"
            style={{ maxHeight: "100px !important", minHeight: "100px" }}
          >
            {StateList?.map((state, index) => (
              <option key={index} value={state?.REGION_NAME}>
                {state?.REGION_NAME} ( {state?.REGION_CODE} )
              </option>
            ))}
          </datalist>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              City*
            </label>

            <input
              list="city"
              name="city"
              required
              // value={stateValue?.REGION_NAME ? stateValue?.REGION_NAME : ""}
              // onFocus={() => handleGetCourtyList()}
              onChange={(e) => {
                setCityValue(
                  cityList?.filter(
                    (li) => li?.REGION_NAME === e.target.value
                  )?.[0]
                );
              }}
            />
          </div>
          <datalist
            id="city"
            role="listbox"
            style={{ maxHeight: "100px !important", minHeight: "100px" }}
          >
            {cityList?.map((state, index) => (
              <option key={index} value={state?.REGION_NAME}>
                {state?.REGION_NAME} ( {state?.REGION_CODE} )
              </option>
            ))}
          </datalist>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                background: "#FFFFFF",
                position: "absolute",
                top: -6,
                left: "10px",
                padding: "0px 5px",
              }}
            >
              Taluka*
            </label>

            <input
              list="taluka"
              name="taluka"
              autoComplete="off"
              // value={stateValue?.REGION_NAME ? stateValue?.REGION_NAME : ""}
              // onFocus={() => handleGetCourtyList()}
              onChange={(e) => {
                setTalukaValue(
                  talukaList?.filter(
                    (li) => li?.TALUKA_NAME === e.target.value
                  )?.[0]
                );
              }}
            />
          </div>
          <datalist
            id="taluka"
            role="listbox"
            style={{ maxHeight: "100px !important", minHeight: "100px" }}
          >
            {talukaList?.map((state, index) => (
              <option key={index} value={state?.TALUKA_NAME}>
                {state?.TALUKA_NAME} ( {state?.TALUKA_CODE} )
              </option>
            ))}
          </datalist>

          <button
            className="register__btn"
            type="submit"
            // onSubmit={() => handleSubmit()}
          >
            REGISTER
          </button>
          <p onClick={closeRegister}>Back To Log In</p>
        </div>
      </form>
    </div>
  );
};

export default Register;
