import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import { getTotalCartAmount } from "../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpPost } from "../helper/Api";
import { SET_CART_DATA, SET_TOTAL_CART_COUNT } from "./Store/ActionTypes";
import { encrypt } from "../helper/utils";
import { SET_SELECTED_PRODUCT_SAVED, SET_WISH_LIST } from "./Store/Reducer";
import { toast } from "react-hot-toast";
import { Typography } from "@mui/material";

const HeaderCartData = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const allStateData = useSelector((store) => store.mainReducer);
  const openCartPage = (item) => {
    navigate(`/Additem/`);
  };
  const checkoutPage = async (itemDetails, cartDetails) => {
    navigate(`/proceedtocheckout/`, {
      state: itemDetails,
      cartData: cartDetails,
    });
  };

  const deleteCartItem = async (transItemId) => {
    let delCartObj = {
      iGuid: localStorage.getItem("token__key")?.length
        ? localStorage.getItem("token__key")
        : sessionStorage.getItem("token__key"),
      vMode: "DELETE",
      iMode: "ITEM",
      iScanText: "",
      TransItemId: transItemId,
      iTransItemBomId: 0,
      iTransItemOperationId: 0,
    };

    let res = await HttpPost("pkg_cell_edit_ending", delCartObj);
    if (res.success) {
      //   const cartIndex = CartItem.findIndex((cart) => cart.TransItemId === transItemId);
      //   setCartItem((prevLists) => ([
      //     ...prevLists?.slice(0, cartIndex),
      //     ...prevLists?.slice(cartIndex + 1)
      //   ]))

      const tempCartData = JSON.parse(JSON.stringify(allStateData.cartData));
      const cartIndex = tempCartData.findIndex(
        (cart) => cart.TransItemId === transItemId
      );
      tempCartData.splice(cartIndex, 1);
      dispatch({
        type: SET_CART_DATA,
        payload: tempCartData,
      });
      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: allStateData?.cartCount - 1,
      });
    }
  };

  const deleteWishListItem = async (elem) => {
    let body = {
      MODE: "D",
      PK_ID: elem?.PK_ID,
      PARTY_ID: allStateData?.partyId,
    };
    let res = await HttpPost("ManageWishlist", body);
    if (res.success) {
      dispatch({
        type: SET_WISH_LIST,
        payload: res.data ? [...res.data] : [],
      });
      toast.success("Removed from wishlist.");
    }
  };

  const handleRouteToDetailPage = (elem) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_SAVED,
      payload: elem,
    });

    // navigate(
    //   `/shop/product/?q=${encrypt({
    //     apiKey: allStateData?.apiKey,
    //     externalProduct: { STOCK_CODE: elem?.STOCK_CODE },
    //   })}`
    // );
    navigate(
      `/shop/product/`,
      {
        state: {
          externalProduct: { STOCK_CODE: elem?.STOCK_CODE }
        }
      }
    );
  };
  return (
    <div className="header__cartdata">
      {props?.mode === "cart" ? (
        <>
          {allStateData?.cartData?.length === 0 ? (
            <Typography variant="subtitle1" sx={{ width: "100%" }}>
              Empty cart
            </Typography>
          ) : (
            <>
              {allStateData.cartData.map((item) => (
                <div
                  className="header__cartdata__cart"
                  style={{ justifyContent: "space-between" }}
                  key={item.TransItemId}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item?.ImageUrl ? item?.ImageUrl : ""}
                      width="50"
                      alt=""
                    />
                    <p>
                      {item.VariantName}
                      <br />
                      <span>
                        1 x {item.Rate ? Number(item.Rate)?.toFixed(3) : 0}
                      </span>
                    </p>
                  </div>
                  <button
                    onClick={() => deleteCartItem(item.TransItemId)}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      color: "gray",
                      fontWeight: "lighter",
                    }}
                  >
                    <ClearIcon sx={{ fontSize: 18 }} />
                  </button>
                </div>
              ))}
              <div className="header__cartdata__total">
                <p style={{ fontStyle: "normal" }}>Total</p>
                <p>₹ {getTotalCartAmount(allStateData.cartData).toFixed(2)}</p>
              </div>
              <div className="header__cartdata__btn">
                <button onClick={openCartPage}>VIEW CART</button>
                <button
                  onClick={() =>
                    checkoutPage(location.state, allStateData.cartData)
                  }
                >
                  CHECKOUT
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {allStateData?.wishListData?.length === 0 ? (
            <Typography variant="subtitle1" sx={{ width: "100%" }}>
              Empty wishlist
            </Typography>
          ) : (
            <>
              {allStateData?.wishListData?.map((item) => (
                <div
                  className="header__cartdata__cart"
                  style={{ justifyContent: "space-between", cursor: "pointer" }}
                  key={item.PK_ID}
                  onClick={() => handleRouteToDetailPage(item)}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item?.IMAGE_URL ? item?.IMAGE_URL : ""}
                      width="50"
                      alt=""
                      style={{ height: "70px", width: "70px" }}
                    />
                    <p>
                      {item.VARIANT_NAME}

                      {/* <span>1 x {item.Rate}</span> */}
                    </p>
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      deleteWishListItem(item);
                    }}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      color: "gray",
                      fontWeight: "lighter",
                    }}
                  >
                    <ClearIcon sx={{ fontSize: 18 }} />
                  </button>
                </div>
              ))}
              {/* <div className="header__cartdata__total">
            <p style={{ fontStyle: "normal" }}>Total</p>
            <p>₹ {getTotalCartAmount(allStateData.cartData).toFixed(2)}</p>
          </div> */}
              {/* <div className="header__cartdata__btn">
            <button onClick={openCartPage}>VIEW CART</button>
            <button
              onClick={() =>
                checkoutPage(location.state, allStateData.cartData)
              }
            >
              CHECKOUT
            </button>
          </div> */}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderCartData;
