import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { encrypt, decrypt } from "../../helper/utils";
import { useSelector } from "react-redux";

const ProtectedRoutes = ({ children }) => {
  const location = useLocation();
  const allStateData = useSelector((store) => store.mainReducer);
  if (!allStateData?.userInfo?.length || location.key === "default") {
    // ?=${encrypt(decrypt()?.apiKey)}
    return <Navigate to={`/`} />;
  }
  return children;
};

export default ProtectedRoutes;
