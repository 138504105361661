import React, { useState, useEffect } from "react";
import { HttpPost } from "../helper/Api";
import { initializeRazorpay } from "./initializeRazorpay";
import COMPANY_LOGO from "../image/cropped-baijnath-logo-02.png";
import { useDispatch } from "react-redux";
import { SET_CART_DATA, SET_TOTAL_CART_COUNT } from "./Store/ActionTypes";
import { extractUserDefineError } from "../helper/utils";
import { v4 as UIDV4 } from "uuid";
import { SET_ERROR_MODAL_OPEN } from "./Store/errorModalReducer";
import PaymentSuccess from "./PaymentSuccess";
import { CircularProgress, Typography } from "@mui/material";
// import Ellipsehead from "../image/icons/Ellipsehead.png";
const Paynow = () => {
  const [pkIdValue, setPkId] = useState("");
  const [orderDetail, setOrderDetail] = useState({});
  const [orderNumber, setOrderNumber] = useState("");
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  // console.log("orderNumber", orderNumber)
  const saveCart = async () => {
    setProgress(true);
    let saveCartObj = {
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iRowData: "MODE~BUT_ORD",
      iGuid: UIDV4(),
    };
    let res = await HttpPost("SaveCartData", saveCartObj);
    if (res.success) {
      dispatch({
        type: SET_TOTAL_CART_COUNT,
        payload: 0,
      });
      dispatch({
        type: SET_CART_DATA,
        payload: [],
      });
      localStorage.setItem("orderDone", "1");
      setOrderNumber(res.data);
      setProgress(false);
    }
    setProgress(false);
  };

  const razorpayFailedCapture = async (
    pkId,
    razorpay_order_id,
    razorpay_payment_id,
    razorpay_signature,
    responseError = null
  ) => {
    let bodyObject = JSON.stringify({
      iConfigId: "5053",
      iTransCategoryLinkId: "5053",
      iMenuID: "1000148",
      iWcId: 0,
      iWcGroupId: 0,
      iMode: "PAYMENT-DETAIL-RAZORPAY",
      ERROR_LOG: JSON.stringify(responseError),
      PK_ID: localStorage.getItem("pkID"),
      ORDER_ID: razorpay_order_id,
      PAYMENT_ID: razorpay_payment_id,
      SIGNATURE_ID: razorpay_signature ? razorpay_signature : undefined,
    });
    const result = await HttpPost("paymentRecords", bodyObject);
    if (result.success) {
      localStorage.removeItem("pkID");
      saveCart();
    }
  };
  const makePayment = async () => {
    const res = await initializeRazorpay();

    if (!res) {
      dispatch({
        type: SET_ERROR_MODAL_OPEN,
        payload: {
          isOpen: true,
          message: "Razorpay SDK Failed to load",
          details: "",
        },
      });
      return;
    }

    try {
      const response = await HttpPost("createOrderRazorpay", {});
      if (response.success) {
        if (response?.checkStatusData === "PAYMENT_DONE") {
          dispatch({
            type: SET_ERROR_MODAL_OPEN,
            payload: {
              isOpen: true,
              message: "Payment is already done.",
              details: "",
            },
          });
          localStorage.setItem("orderDone", "1");
          return;
        }
        setPkId(response.PK_ID);
        setOrderDetail(response);
        localStorage.setItem("pkID", response.PK_ID);
        let options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          name: "BAIJNATH",
          currency: response.currency,
          amount: response.amount,
          order_id: response.orderId,
          description: "Thank you.",
          image: COMPANY_LOGO,
          handler: async function (response) {
            const result = await HttpPost(
              "verifyRazorpay",
              JSON.stringify({
                response,
              })
            );
            if (result.signatureIsValid) {
              razorpayFailedCapture(
                response.PK_ID,
                response.razorpay_order_id,
                response.razorpay_payment_id,
                response.razorpay_signature,
                null
              );
            }
          },
          theme: {
            color: "#670f3b",
          },
        };

        const razorpayObject = new window.Razorpay(options);
        razorpayObject.open();
        razorpayObject.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
          razorpayFailedCapture(
            pkIdValue,
            response.error.metadata.order_id,
            response.error.metadata.payment_id,
            null,
            response.error
          );
        });
      }
    } catch (error) {
      const errorMessage = extractUserDefineError(error.message);
      dispatch({
        type: SET_ERROR_MODAL_OPEN,
        payload: {
          isOpen: true,
          message: errorMessage,
          details: "",
        },
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("orderDone", "0");
    makePayment();
  }, []);
  return (
    <div>
      <div className="add-to-cart-bg-img">
        <h1
          style={{
            textAlign: "left",
            paddingTop: "120px",
            paddingLeft: "120px",
            fontSize: "55px",
            fontFamily: "'Tenor Sans', sans-serif",
          }}
        >
          Order Payment
        </h1>
      </div>
      {progress ? (
        <div
          style={{
            height: "300px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="container d-flex justify-content-start mt-3 mb-3"
          style={{ width: "100%", height: "350px" }}
        >
          <ul className="text-start" style={{ width: "100%" }}>
            {/* <li>Order number : {orderNumber?.split("|").at(-1)}</li> */}
            {/* <li>Date :</li> */}

            {orderNumber?.length !== 0 &&
            localStorage.getItem("orderDone") === "1" ? (
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  alignItems: "center",
                  flexDirection: "column",
                  // width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <Typography>Payment Successful.</Typography>
                <PaymentSuccess />
                <Typography>
                  Order Number : {orderNumber?.split("|").at(-1)}
                </Typography>
                <Typography>
                  {" "}
                  Total:{" "}
                  {orderDetail.amount
                    ? `${orderDetail.amount}`.slice(0, -2) +
                      "." +
                      `${orderDetail.amount}`.slice(-2)
                    : ""}
                </Typography>
              </div>
            ) : (
              <>
                <li>
                  Total:{" "}
                  {orderDetail.amount
                    ? `${orderDetail.amount}`.slice(0, -2) +
                      "." +
                      `${orderDetail.amount}`.slice(-2)
                    : ""}
                </li>
                <li>Payment method: Credit Card/Debit Card/NetBanking</li>
                <p>
                  Thank you for your order, please click the button below to pay
                  with Razorpay.
                </p>
                <button className="pay-now" onClick={() => makePayment()}>
                  Pay now
                </button>
                <button
                  className="cancel"
                  // onClick={() => navigate(`/proceedtocheckout/`)}
                >
                  Cancel
                </button>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Paynow;
