import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    width: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const LoginWithOtpDialog = ({
  handleValidateOtp,
  open,
  handleClose,
  handleInputChange,
  handleGetOTP,
  messageText,
  isSuccessMobile,
}) => {
  return (
    <>
      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose()}
        >
          Login with mobile number
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            <div className="user">
              <p style={{ marginBottom: 5 }}>
                <label className="user__id">Mobile Number</label>
              </p>
              <input
                type={"number"}
                name="mobileNumber"
                placeholder="Enter Your Number"
                className="form__control"
                onChange={handleInputChange}
              />

              {isSuccessMobile ? (
                <div
                  className="user__id"
                  style={{ marginTop: 10, color: "yellowgreen" }}
                >
                  OTP has been sent to your registered mobile number
                </div>
              ) : isSuccessMobile === false ? (
                <div className="invalid-feedback inlineValidation mfont">
                  {messageText}.
                </div>
              ) : null}
            </div>
          </Typography>
          <Typography gutterBottom>
            {isSuccessMobile ? (
              <>
                <div className="user">
                  <p style={{ marginBottom: 5 }}>
                    <label className="user__id">Enter OTP *</label>
                  </p>
                  <input
                    type={"number"}
                    name="otp"
                    placeholder="Enter Otp"
                    className="form__control"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="forget">
                  <div>
                    <Button
                      className="forget__password"
                      onClick={() => handleGetOTP()}
                      variant="text"
                    >
                      {" "}
                      Re-Send Otp{" "}
                    </Button>
                  </div>
                  <Button
                    variant="outlined"
                    onClick={() => handleValidateOtp()}
                  >
                    Sign In
                  </Button>
                </div>
              </>
            ) : (
              <DialogActions style={{ marginRight: "460px" }}>
                <Button
                  variant="outlined"
                  onClick={() => handleGetOTP()}
                  style={{ width: "300px" }}
                >
                  Get OTP
                </Button>
              </DialogActions>
            )}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default LoginWithOtpDialog;
