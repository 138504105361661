import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { HttpPost } from "../../helper/Api";
import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const Account = ({ accountClose, setAccountClose, handleLogout }) => {
  const allStateData = useSelector((store) => store.mainReducer);
  const [orderval, setOrderVal] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [orderDetail, setOrderDetail] = useState(false);
  const [orderdata, setorderdata] = useState([]);
  const orderId = useRef();
  const orderDate = useRef();
  const getOrderInfoHandler = async () => {
    const data = {
      PARTY_ID: allStateData.partyId,
    };
    let res = await HttpPost("getOrderInfo", data);
    setOrderVal(true);
    setOrderDetail(false);
    setTabledata(res.data.rows);
  };
  const getOrderDetailInfoHandler = async (Trans_id, DOC_NO, date) => {
    orderId.current = DOC_NO;
    orderDate.current = date;
    const data = {
      PARTY_ID: allStateData.partyId,
      Trans_id: Trans_id,
    };
    let res = await HttpPost("getOrderDetailInfo", data);
    setOrderVal(false);
    setOrderDetail(true);
    setorderdata(res.data);
  };
  const backbtnHandler = () => {
    if (orderDetail) {
      setOrderDetail(false);
      setOrderVal(true);
    } else if (orderval) {
      setOrderVal(false);
    } else {
      setAccountClose(false);
    }
  };
  return (
    <Box>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={accountClose}
        onClose={() => setAccountClose(false)}
        PaperProps={{
          sx: {
            width: "100%",
            marginLeft: "0%",
            height: "70%",
            marginRight: "0%",
            marginTop: "320px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
            >
              <Box
                sx={{
                  width: "10%",
                  backgroundColor: "#FFF9F8",
                  height: "400px",
                  alignItems: "center",
                  paddingTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "13px",
                  color: "black",
                }}
              >
                <p>
                  <span>
                    <ArrowBackIosNewIcon
                      sx={{ color: "black" }}
                      onClick={backbtnHandler}
                    />
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={getOrderInfoHandler}
                  >
                    ORDERS
                  </span>
                </p>
              </Box>
              {orderval ? (
                <Box
                  sx={{
                    width: "40%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    fontSize: "17px",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Order</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tabledata?.map((row) => (
                        <TableRow
                          key={row.TRANS_ID}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">{row.DOC_NO}</TableCell>
                          <TableCell align="right">{row.TRANS_DATE}</TableCell>
                          <TableCell align="right">
                            {row.TOTAL_AMOUNT}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant="dark"
                              onClick={() =>
                                getOrderDetailInfoHandler(
                                  row.TRANS_ID,
                                  row.DOC_NO,
                                  row.TRANS_DATE
                                )
                              }
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : orderDetail ? (
                <Box
                  sx={{
                    width: "40%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    fontSize: "17px",
                  }}
                >
                  <Typography sx={{ color: "black" }}>
                    Order{" "}
                    <span
                      style={{ backgroundColor: "#FFFF00" }}
                    >{`#${orderId.current}`}</span>{" "}
                    was placed on{" "}
                    <span style={{ backgroundColor: "#FFFF00" }}>
                      {orderDate.current}
                    </span>
                  </Typography>
                  <h2 style={{ marginTop: "18px", color: "black" }}>
                    Order Details
                  </h2>
                  <Table>
                    <TableHead>
                      <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell>IMAGE_URL</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {`${orderdata[0]?.item.rows[0]?.VARIANT_NAME} × ${orderdata[0]?.item.rows[0]?.QUANTITY}`}
                          </TableCell>
                          <TableCell>
                            <img alt="err" src={`${orderdata[0]?.item.rows[0]?.IMAGE_URL}`} style={{width:'50px',height:"50px"}} />
                        </TableCell>
                        <TableCell align="right">
                          {orderdata[0].item.rows[0].TRANS_AMOUNT}
                        </TableCell>
                      </TableRow>
                      {orderdata[1].Formula.rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.ROW_DESCRIPTION}
                          </TableCell>
                          <TableCell>

                          </TableCell>
                          <TableCell align="right" >
                            {!isNaN(row.ROW_VALUE)
                              ? row.ROW_VALUE.toFixed(2)
                              : row.ROW_VALUE}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <h2 style={{ marginTop: "18px", color: "black" }}>
                    Billing Address
                  </h2>
                  <Box
                    style={{
                      fontSize: "15px",
                      fontStyle: "italic",
                      marginTop: "25px",
                      marginBottom: "30px",
                    }}
                  >
                    <p>{allStateData.userInfo}</p>
                    <p>{orderdata[2].Address.rows[0].ADDRESS}</p>
                    <p>{orderdata[2].Address.rows[0].PIN_CODE}</p>
                    <p>{orderdata[2].Address.rows[0].MOBILE_NO}</p>
                    <p>{orderdata[2].Address.rows[0].EMAIL_ID}</p>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    fontSize: "17px",
                  }}
                >
                  <p>
                    Hello{" "}
                    <span style={{ color: "black" }}>
                      {allStateData.userInfo}
                    </span>{" "}
                    (not{" "}
                    <span style={{ color: "black" }}>
                      {allStateData.userInfo}
                    </span>
                    ?{" "}
                    <span
                      onClick={handleLogout}
                      sx={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      Log out
                    </span>
                    )
                  </p>
                  <p>
                    From your account dashboard you can view your recent orders,
                    manage your shipping and billing addresses, and edit your
                    password and account details.
                  </p>
                </Box>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default Account;
